import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { fetchInfoOrder, updateOrder } from "../../../redux/slices/order";
import "./Order.scss"

const ChangeOrder = ({ handleOpenModal, handleCloseModal, data }) => {
  const dispatch = useDispatch();
  const [newData, setNewData] = useState({
    id: data._id,
    address: data.address,
    intercom: data.intercom,
    comment: data.comment,
  })

  const handleChangeOrder = (el) => {
    setNewData((prevState) => ({
      ...prevState,
      [el.target.name]: el.target.value
    }))
  }

  const handleSendChangeOrder = async () => {
    await dispatch(updateOrder(newData));
    await dispatch(fetchInfoOrder())
    handleOpenModal(false);
  }

  return (
    <>
      <div className="completion-registration__wrapper"></div>
      <div className="completion-registration">
        <div className="completion-registration__title">Изменение заказа
          №{data.orderNumber.toString().padStart(5, '0')}</div>

        <form className="completion-registration__form"
        >
          <div className="completion-registration__form-text">Адрес</div>
          <input
            name='address'
            value={newData.address}
            onChange={handleChangeOrder}
            className="calculator__data-input"
            type="text" placeholder="Адрес" />
          <div className="completion-registration__form-text">Наличие домофона</div>
          <input
            name='intercom'
            value={newData.intercom}
            onChange={handleChangeOrder}
            className="calculator__data-input"
            type="text" placeholder="Наличие домофона" />
          <div className="completion-registration__form-text">Ваши пожелания</div>
          <textarea
            name='comment'
            value={newData.comment}
            onChange={handleChangeOrder}
            className="calculator__data-textarea"
            placeholder="Ваши пожелания"
            cols="10"
            rows="10"
          ></textarea>

          <button
            type="submit"
            onClick={handleSendChangeOrder}
            className="completion-registration__form-button"
          >
            Сохранить
          </button>
          <span
            href="/"
            type="button"
            onClick={handleCloseModal}
            className="completion-registration__form-button"
          >
            закрыть
          </span>
        </form>
      </div>
    </>
  );
};

export default ChangeOrder;