import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
export const fetchCheckPaymentStatus = createAsyncThunk('api/fetchCheckPaymentStatus', async (id) => {
  const { data } = await axios.get(`/api/payment/${id}`);

  return data;
});

const initialState = {
  message: "Ожидание ответа",
  status: 'loading',
};

const paymentStatusSlice = createSlice({
  name: 'paymentStatus',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCheckPaymentStatus.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCheckPaymentStatus.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.message = action.payload.message;
    },
    [fetchCheckPaymentStatus.rejected]: (state) => {
      state.status = 'error';
      state.message = 'Ошибка запроса';
    },
  },
});

export const paymentStatusReducer = paymentStatusSlice.reducer;