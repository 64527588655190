import React, { useState, useEffect } from 'react'
import { memo } from 'react'

const TimeInputs = memo(({ weekNumber, callBack }) => {
  const [hours, setHours] = useState(7)
  const [minutes, setMinutes] = useState(30)

  const currentTime = new Date()
  currentTime.setHours(currentTime.getHours() + 1)

  useEffect(() => {
    setHours(currentTime.getHours())
    setMinutes(currentTime.getMinutes())
    callBack(weekNumber, currentTime.getHours(), currentTime.getMinutes())
  }, [])

  const handleChangeHours = (e) => {
    if (e.target.value < 0 || e.target.value > 24) return

    setHours(e.target.value)
    callBack(weekNumber, e.target.value, minutes)
  }
  const handleChangeMinutes = (e) => {
    if (e.target.value < 0 || e.target.value > 60) return
    setMinutes(e.target.value)
    callBack(weekNumber, hours, e.target.value)
  }
  return (
    <>
      <input
        className="additional-services__input"
        placeholder="7"
        step="1"
        type="number"
        min="0"
        max="24"
        value={hours}
        onChange={handleChangeHours}
      />
      :
      <input
        className="additional-services__input"
        placeholder="30"
        step="1"
        type="number"
        min="0"
        max="60"
        value={minutes}
        onChange={handleChangeMinutes}
      />
    </>
  )
})

export default TimeInputs
