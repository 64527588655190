import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUserInfo, fetchUserInfoChange } from "../../../../redux/slices/user";
import { useForm } from "react-hook-form";

const AddMail = () => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    // dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      "email": ""
    }
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUserInfoChange(values));
    setOpen(!open);
    document.location.reload();
    reset();
    return data;
  }

  return (
    <div className="setting__data-item">

      <div>E-mail</div>
      {user.items && user.items.email ? (
        <div>{user.items.email}</div>
      ) : (
        <div>Почта не установлена</div>
      )}
      <div onClick={handleOpen} className="setting__data-button">Изменить</div>
      {open &&
        <form onSubmit={handleSubmit(onSubmit)} >
          <input
            className="setting__data-change-modal-input"
            {...register('email', {
              required: true
            })}
            placeholder="E-mail"
            type="email"
          />
          <button type="submit" className="setting__address-modal-button">Сохранить</button>
        </form>
      }

    </div>
  )
}

export default AddMail;