import { Link } from 'react-router-dom'
import './Advantages.scss'

const Advantages = () => {
  return (
    <section className="benefits__wrapper feedback__wrapper">
      <div className="landing__container advantages">
        <div className="benefits__title">Преимущество работы с нами</div>
        <ul className="advantages__list">
          <li>
            <div>Документооборот</div>
            <div>Используем ЭДО,выставляем счета в личном кабинете</div>
          </li>
          <li>
            <div>Клинеры</div>
            <div>Обученные профессионалы своего дела</div>
          </li>
          <li>
            <div>Цена</div>
            <div>Стоимость не изменится в процессе уборки</div>
          </li>
          <li>
            <div>Договор</div>
            <div>Сотрудничество осуществляется по составляенному договору</div>
          </li>
          <li>
            <div>Гарантия</div>
            <div>Выполним работу в лучшем виде или вернем деньги</div>
          </li>
          <li>
            <div>Надежность</div>
            <div>Компенсация за испорченные вещи</div>
          </li>
        </ul>
        <Link
          to={'/calculator'}
          className="landing__form-button button"
          style={{
            textDecoration: 'none',
          }}
        >
          Рассчитать стоимость
        </Link>
      </div>
    </section>
  )
}

export default Advantages
