import React, { useState } from "react";
import Advantages from "../../assets/advantages-container-block-1.png";
import phone from "../../assets/phone.svg";
import "./WorkUs.scss"; // Make sure to create this CSS file for styling
import { Link } from "react-router-dom";

export const WorkUs = () => {
  const workSchedules = [
    { id: 1, name: "5 / 2", income: "95 000" },
    { id: 2, name: "2 / 2", income: "60 000" },
    { id: 3, name: "3 / 1", income: "98 000" },
  ];

  const [selectedSchedule, setSelectedSchedule] = useState(
    workSchedules[0].name
  );
  const [income, setIncome] = useState(workSchedules[0].income);

  const handleScheduleClick = schedule => {
    setSelectedSchedule(schedule.name);
    setIncome(schedule.income);
  };

  return (
    <section class="work-page">
      <div class="work-page__title">Калькулятор дохода</div>

      <div class="work-page__wrapper">
        <div class="work-page__income-calculator">
          <div class="income-calculator__title">
            Выберите удобный график работы
          </div>
          <div class="income-calculator__schedule-list">
            {workSchedules.map(schedule => (
              <div
                key={schedule.id}
                class={`income-calculator__schedule-item ${
                  selectedSchedule === schedule.name
                    ? "income-calculator__schedule-item--active"
                    : ""
                }`}
                onClick={() => handleScheduleClick(schedule)}>
                {schedule.name}
              </div>
            ))}
          </div>
          <div class="income-calculator__fixed-income">
            <div class="income-calculator__fixed-income-title">
              Фиксированный доход
            </div>
            <div class="income-calculator__fixed-income-amount">
              {income}руб/месяц
            </div>
            <div class="income-calculator__fixed-income-description">
              Выплаты 2 раза в месяц
            </div>
          </div>
          <div class="income-calculator__percentage-income">
            <div class="income-calculator__percentage-income-title">
              Работая на <span>%</span> выплаты происходят
            </div>
            <div class="income-calculator__percentage-income-description">
              один раз в неделю
            </div>
          </div>
        </div>

        <div class="work-page__benefits">
          <div class="work-page__benefits-title">
            Станьте исполнителем уборки с доходом
          </div>
          <div class="work-page__benefits-income">
            до <span>120 000</span> руб/месяц
          </div>
          <div class="work-page__benefits-description">
            Работаете на фиксированном окладе
          </div>
          <div class="work-page__benefits-choice">
            или на <span>%</span> выбираете сами
          </div>
        </div>
      </div>

      <div className="work-page__wrapper-advantages advantages-2">
        <div class="work-page__advantages work-page__advantages-block">
          <div class="work-page__advantages-title">Наши преимущества</div>

          <div class="work-page__advantage-item">
            1. Высокий оклад и большой <span>%</span> с заказов
          </div>
          <div class="work-page__advantage-item">2. Удобный график работы</div>
          <div class="work-page__advantage-item">3. Надежность</div>
          <div class="work-page__advantage-item">
            4. Личный менеджер, который поможет с заказом
          </div>
          <div class="work-page__advantage-item">5. Стабильные выплаты</div>

          <div class="work-page__conditions-title">Наши условия работы</div>
          <div class="work-page__condition-item">1. Договор - Формате ГПХ</div>
          <div class="work-page__condition-item">
            2. График работы на Выбор 5/2 3/1 2/2
          </div>
          <div class="work-page__condition-item">
            3. Работа на окладе или по проценту
          </div>
        </div>

        <div className="work-page__advantages">
          <div className="work-page__advantages-title-ancate">
            Заполните Анкету
          </div>

          <Link
            to="/clearinghouse-registration"
            className="work-page__advantages-container-blocks">
            <div className="work-page__advantages-container-block-1">
              <img src={Advantages} width={110} alt="" />
            </div>
            <div className="work-page__advantages-container-block-2">
              Стать Клинером
            </div>
          </Link>
          <div>
            <div>Если остались вопросы, звоните нам!</div>
            <div className="work-page__advantages-phone">
              <img src={phone} alt="" />
              +7(985)352-90-39
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
