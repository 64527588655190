import { Calendar } from "react-multi-date-picker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMultipleDate,
  changeTimes,
} from "../../../../redux/slices/calculator";
import TimeInputs from "./TimeInputs"; // Импортируем ваш компонент TimeInputs
import "./Calendar.scss";

const MultipleCalendar = () => {
  const { dates: stateDates } = useSelector(({ calculator }) => calculator);
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();

  // Синхронизация состояния с Redux
  useEffect(() => {
    const newDates = [];
    stateDates.forEach(item => {
      const date = new Date(
        item.year,
        item.month - 1,
        item.day,
        item.hours,
        item.minutes
      );
      newDates.push(date);
    });
    setDates(newDates);
  }, [stateDates]);

  // Обновление выбранных дат
  const handleChangeDates = values => {
    const newDates = values.map(item => ({
      day: item.day,
      month: item.month.number,
      year: item.year,
      hours: item.hour || 8, // По умолчанию 8 часов
      minutes: item.minute || 0,
    }));
    dispatch(changeMultipleDate(newDates));
    setDates(values);
  };

  // Обновление времени для конкретной даты
  const handleTimeChange = (hours, minutes, index) => {
    const date = stateDates[index];

    // Диспатчим новое время для конкретного дня
    dispatch(
      changeTimes({
        year: date.year,
        month: date.month,
        day: date.day,
        hours,
        minutes,
      })
    );
  };

  return (
    <div className="multiple-calendar calendar__wrapper">
      <Calendar
        weekDays={["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]}
        months={[
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ]}
        weekStartDayIndex={1}
        className="calendar__services"
        value={dates}
        minDate={new Date()}
        onChange={handleChangeDates}
        multiple
      />
      <div className="selected-dates">
        {stateDates.map((item, index) => (
          <div key={index}>
            <div>
              Дата : {item.day}.{item.month}.{item.year}
            </div>
            <div>
              <TimeInputs
                date={item}
                callBack={(hours, minutes) =>
                  handleTimeChange(hours, minutes, index)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleCalendar;
