import { useState, memo } from "react";
import "../completed-services/CompletedServices.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoOrder } from "../../../redux/slices/order";
import { selectIsAuth } from "../../../redux/slices/auth";
import arrowImage from "../../../assets/arrow-small.png";
import { months } from "../../../data";
import ChangeOrder from "./ChangeOrder";
import CancelOrder from "./CancelOrder";
import { Calendar } from "react-multi-date-picker";

const MyOrders = memo(() => {
  const [modal, setModal] = useState(false);
  const [dataChange, setDataChange] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelId, setCancelId] = useState(false);

  const handleOpenModal = (value, data = null) => {
    setModal(value);
    setDataChange(data);
  };

  const handleCancelOrder = value => {
    setCancelId(value);
    setCancelModal(true);
  };

  const handleCloseModal = () => {
    handleOpenModal(false);
  };

  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(fetchInfoOrder());
  }, [dispatch]);

  const [expandedItems, setExpandedItems] = useState([]);

  const toggleExpand = index => {
    setExpandedItems(prevExpanded => {
      if (prevExpanded.includes(index)) {
        return prevExpanded.filter(itemIndex => itemIndex !== index);
      } else {
        return [...prevExpanded, index];
      }
    });
  };

  const [markedDates, setMarkedDates] = useState([]);

  // Функция для извлечения будущих дат уборок
  useEffect(() => {
    const futureDates = order.items
      .filter(item => {
        // Фильтрация объектов: оставляем только будущие даты и не отмененные заказы
        const itemDate = new Date(item.date);
        return itemDate > new Date() && !item.isCanceled;
      })
      .map(item => new Date(item.date));

    setMarkedDates(futureDates);
  }, [order.items]);

  // const mockOrder = {
  //   items: [
  //     {
  //       _id: "1",
  //       group: "Поддерживающая уборка",
  //       type: "Ежедневная",
  //       whatPurpose: "Квартира",
  //       room_count: 3,
  //       bathroom_count: 1,
  //       squareMeters: 50,
  //       price: 5000,
  //       bonusAmount: 500,
  //       address: "ул. Ленина, 15",
  //       numberOfFloors: 5,
  //       elevator: true,
  //       intercom: "1234",
  //       comment: "Позвоните за 15 минут до приезда",
  //       payment_type: "Наличные",
  //       isCanceled: false,
  //       isPaid: true,
  //       state: false,
  //       date: "2023-09-10T14:30:00Z",
  //       orderNumber: 12345,
  //       additional_services: [
  //         {
  //           name: "Мойка окон",
  //           count: 2,
  //           price: 1000,
  //         },
  //         {
  //           name: "Дополнительная комната",
  //           count: 1,
  //           price: 1500,
  //         },
  //       ],
  //     },
  //     {
  //       _id: "2",
  //       group: "Генеральная уборка",
  //       type: "Одноразовая",
  //       whatPurpose: "Офис",
  //       room_count: 4,
  //       bathroom_count: 2,
  //       squareMeters: 100,
  //       price: 10000,
  //       bonusAmount: 1000,
  //       address: "ул. Пушкина, 25",
  //       numberOfFloors: 10,
  //       elevator: false,
  //       intercom: "5678",
  //       comment: "Есть собака, не бояться",
  //       payment_type: "Безналичный расчет",
  //       isCanceled: false,
  //       isPaid: true,
  //       state: false,
  //       date: "2023-09-12T10:00:00Z",
  //       orderNumber: 12346,
  //       additional_services: [
  //         {
  //           name: "Химчистка ковров",
  //           count: 3,
  //           price: 3000,
  //         },
  //       ],
  //     },
  //     {
  //       _id: "3",
  //       group: "Поддерживающая уборка",
  //       type: "Ежедневная",
  //       whatPurpose: "Дом",
  //       room_count: 5,
  //       bathroom_count: 3,
  //       squareMeters: 120,
  //       price: 8000,
  //       bonusAmount: 800,
  //       address: "ул. Гоголя, 17",
  //       numberOfFloors: 2,
  //       elevator: false,
  //       intercom: "9999",
  //       comment: "Оставьте ключи под ковриком",
  //       payment_type: "Наличные",
  //       isCanceled: true,
  //       isPaid: false,
  //       state: false,
  //       date: "2023-09-14T08:00:00Z",
  //       orderNumber: 12347,
  //       additional_services: [
  //         {
  //           name: "Мойка фасада",
  //           count: 1,
  //           price: 5000,
  //         },
  //       ],
  //     },
  //   ],
  // };

  // const order = mockOrder;

  return (
    <section>
      {/* <div className="calendar__wrapper-order">
        <Calendar
          weekDays={["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]}
          months={[
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ]}
          value={markedDates}
          format="YYYY/MM/DD"
          multiple
          sort
        />
        {!order.items.length > 0 ? (
          <div className="calendar__wrapper-order-text">Ваш календарь пуст</div>
        ) : null}
      </div> */}

      <ul className="completed-services__items">
        {order.items.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              color: "#b2b2b2",
              padding: "32px 0",
            }}>
            У вас нет заказов
          </div>
        ) : (
          order.items
            .slice()
            .reverse()
            .filter(item => {
              // Фильтрация объектов по условиям
              if (item.isCanceled || item.state) {
                return false;
              }
              if (!item.isPaid && item.payment_type === "Безналичный расчет") {
                return false;
              }
              return true; // Показывать только допустимые объекты
            })
            .map((item, index) => {
              const isExpanded = expandedItems.includes(index);
              const date = new Date(item.date);
              const day = date.getDate();
              const month = months[date.getMonth()];
              const hours = date.getHours();
              const minutes = date.getMinutes().toString().padStart(2, "0");

              return (
                <li
                  className={`completed-services__item completed-services__item-order ${
                    isExpanded ? "expanded" : ""
                  }`}
                  key={index}>
                  <div className="completed-services__item-header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        onClick={() => toggleExpand(index)}
                        className="completed-services__arrow">
                        {!isExpanded ? (
                          <img src={arrowImage} width={18} alt="" />
                        ) : (
                          <img
                            src={arrowImage}
                            width={18}
                            alt=""
                            style={{ transform: "rotate(180deg)" }}
                          />
                        )}
                      </span>
                      <div
                        className="completed-services__text"
                        style={{ display: "flex", marginLeft: "8px" }}>
                        {item.group}
                      </div>
                    </div>
                    <div className="completed-services__text-wrapper block">
                      <div className="completed-services__text order">
                        Заказ № {item.orderNumber}
                      </div>
                      <div className="completed-services__text text">
                        - Дата Уборки {`${day} ${month} ${hours}:${minutes}`}
                      </div>
                    </div>
                    <div className="completed-services__text-wrapper buttons">
                      <div
                        onClick={() => handleOpenModal(true, item)}
                        className="completed-services__cancel-button">
                        Изменить
                      </div>
                      <div
                        onClick={() =>
                          handleCancelOrder({
                            id: item._id,
                            orderNumber: item.orderNumber,
                          })
                        }
                        className="completed-services__cancel-button button-left">
                        Отменить уборку
                      </div>
                    </div>
                  </div>
                  <div className="text-price">{item.price} ₽</div>
                  {isExpanded && (
                    <span className="completed-services__item-span">
                      <div>
                        {item.whatPurpose && (
                          <div className="completed-services__text">
                            Объект - <span>{item.whatPurpose}</span>
                          </div>
                        )}

                        <div className="completed-services__text">
                          Количество комнат - <span>{item.room_count}</span>
                        </div>

                        <div className="completed-services__text">
                          Количество сан/узлов -{" "}
                          <span>{item.bathroom_count}</span>
                        </div>

                        <div>
                          <strong className="order">
                            Дополнительный сервис:
                          </strong>
                          <div className="completed-services__text">
                            {item.additional_services.map((service, idx) => (
                              <div key={idx}>
                                <div className="completed-services__text">
                                  <span>
                                    {service.name} [{service.count}] ={" "}
                                    {service.price} ₽
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="completed-services__text">
                          Адрес: <span>{item.address}</span>
                        </div>
                        {item.numberOfFloors && (
                          <div className="completed-services__text">
                            Количество этажей:{" "}
                            <span>{item.numberOfFloors}</span>
                          </div>
                        )}
                        {"elevator" in item && (
                          <div className="completed-services__text">
                            Лифт: <span>{item.elevator ? "Да" : "Нет"}</span>
                          </div>
                        )}
                        <div className="completed-services__text">
                          Домофон: <span>{item.intercom}</span>
                        </div>
                        <div className="completed-services__text">
                          Комментарий: <span>{item.comment}</span>
                        </div>
                      </div>

                      <div>
                        <div className="completed-services__text">
                          Формат оплаты: <span>{item.payment_type}</span>
                        </div>
                        <div className="completed-services__text">
                          Сумма заказа: <span>{item.price}</span>
                        </div>
                        <div className="completed-services__text">
                          Списано бонусов: <span>{item.bonusAmount}</span>
                        </div>
                        <div className="completed-services__text">
                          Итого сумма заказа:{" "}
                          <span>{item.price - item.bonusAmount}</span>
                        </div>
                      </div>
                    </span>
                  )}
                </li>
              );
            })
        )}
      </ul>
      {modal && (
        <ChangeOrder
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          data={dataChange}
        />
      )}
      {cancelModal && (
        <CancelOrder
          handleCloseModal={() => setCancelModal(false)}
          orderId={cancelId.id}
          orderNumber={cancelId.orderNumber}
        />
      )}
    </section>
  );
});

export default MyOrders;
