import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Политика конфиденциальности нашего клинингового агенства</title>
        <meta
          name="description"
          content="Узнайте о нашей политике конфиденциальности в обработке персональных данных. Мы заботимся о вашей безопасности и конфиденциальности личных данных."
        />
      </Helmet>
      <section className="landing__container">
        <div className="landing__additional-title">
          ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
        </div>
        <br />
        <div>г. Москва </div>
        <div>«11» января 2023 г.</div>
        <br />
        <div className="landing__additional-text-ct">
          Настоящая Политика конфиденциальности персональных данных (далее –
          Политика конфиденциальности) действует в отношении всей информации,
          которую Интернет-магазин <strong>«ТВОЯУБОРКА»</strong>, расположенный
          на доменном имени{" "}
          <a href="https://tvoyauborka.ru"> https://tvoyauborka.ru </a>, может
          получить о Пользователе во время использования сайта{" "}
          <strong>«ТВОЯУБОРКА»</strong>, программ и продуктов Интернет-магазина.
        </div>
        <div className="landing__additional-title">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</div>
        <ul className="landing__additional-list">
          <li>
            1.1. В настоящей Политике конфиденциальности используются следующие
            термины:
          </li>
          <li>
            1.1.1. «Администрация сайта Интернет-магазина (далее –
            «ТВОЯУБОРКА»)» – уполномоченные сотрудники на управления сайтом,
            действующие от имени Название организации, которые организуют и
            (или) осуществляет обработку персональных данных, а также определяет
            цели обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с
            персональными данными.
          </li>
          <li>
            1.1.2. «Персональные данные» - любая информация, относящаяся к прямо
            или косвенно определенному или определяемому физическому лицу
            (субъекту персональных данных).
          </li>
          <li>
            1.1.3. «Обработка персональных данных» - любое действие (операция)
            или совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </li>
          <li>
            1.1.4. «Конфиденциальность персональных данных» - обязательное для
            соблюдения Оператором или иным получившим доступ к персональным
            данным лицом требование не допускать их распространения без согласия
            субъекта персональных данных или наличия иного законного основания.
          </li>
          <li>
            1.1.5. «Пользователь сайта Интернет-магазина (далее - Пользователь)»
            – лицо, имеющее доступ к Сайту, посредством сети Интернет и
            использующее Сайт интернет-магазина.
          </li>
          <li>
            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный
            веб-сервером и хранимый на компьютере пользователя, который
            веб-клиент или веб-браузер каждый раз пересылает веб- серверу в
            HTTP-запросе при попытке открыть страницу соответствующего сайта.
          </li>
          <li>
            1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной
            сети, построенной по протоколу IP.
          </li>
        </ul>
        <div className="landing__additional-title">2. ОБЩИЕ ПОЛОЖЕНИЯ</div>
        <ul className="landing__additional-list">
          <li>
            2.1. Использование Пользователем сайта «ТВОЯУБОРКА» означает
            согласие с настоящей Политикой конфиденциальности и условиями
            обработки персональных данных Пользователя.
          </li>
          <li>
            2.2. В случае несогласия с условиями Политики конфиденциальности
            Пользователь должен прекратить использование сайта «ТВОЯУБОРКА».
          </li>
          <li>
            2.3. Настоящая Политика конфиденциальности применяется только к
            сайту Интернет- магазина «ТВОЯУБОРКА». Интернет-магазин не
            контролирует и не несет ответственность за сайты третьих лиц, на
            которые Пользователь может перейти по ссылкам, доступным на сайте
            «ТВОЯУБОРКА».
          </li>
          <li>
            2.4. Администрация сайта не проверяет достоверность персональных
            данных, предоставляемых Пользователем сайта «ТВОЯУБОРКА».
          </li>
        </ul>

        <div className="landing__additional-title">
          3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
        </div>
        <ul className="landing__additional-list">
          <li>
            3.1. Настоящая Политика конфиденциальности устанавливает
            обязательства Администрации сайта «ТВОЯУБОРКА» по неразглашению и
            обеспечению режима защиты конфиденциальности персональных данных,
            которые Пользователь предоставляет по запросу Администрации сайта
            при регистрации на сайте «ТВОЯУБОРКА» или при оформлении заказа для
            приобретения Услуг.
          </li>
          <li>
            3.2. Персональные данные, разрешённые к обработке в рамках настоящей
            Политики конфиденциальности, предоставляются Пользователем путём
            заполнения регистрационной формы на Сайте «ТВОЯУБОРКА» Название
            раздела и включают в себя следующую информацию:
          </li>
          <li>3.2.1. фамилию, имя, отчество Пользователя;</li>
          <li>3.2.2. контактный телефон Пользователя;</li>
          <li>3.2.3. адрес электронной почты (e-mail);</li>
          <li>3.2.4. адрес оказание услуг;</li>
          <li>3.2.5. место жительство Пользователя.</li>
          <li>
            3.3. Интернет-магазин защищает Данные, которые автоматически
            передаются в процессе просмотра рекламных блоков и при посещении
            страниц, на которых установлен статистический скрипт системы
            ("пиксель"): • IP адрес; • информация из cookies; • информация о
            браузере (или иной программе, которая осуществляет доступ к показу
            рекламы); • время доступа; • адрес страницы, на которой расположен
            рекламный блок; • реферер (адрес предыдущей страницы).
          </li>
          <li>
            3.3.1. Отключение cookies может повлечь невозможность доступа к
            частям сайта Интернет- магазина, требующим авторизации.
          </li>
          <li>
            3.3.2. Интернет-магазин осуществляет сбор статистики об IP-адресах
            своих посетителей. Данная информация используется с целью выявления
            и решения технических проблем, для контроля законности проводимых
            финансовых платежей.
          </li>
          <li>
            3.4. Любая иная персональная информация неоговоренная выше (история
            покупок, используемые браузеры и операционные системы и т.д.)
            подлежит надежному хранению и нераспространению, за исключением
            случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики
            конфиденциальности.
          </li>
        </ul>

        <div className="landing__additional-title">
          4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
        </div>
        <ul className="landing__additional-list">
          <li>
            4.1. Персональные данные Пользователя Администрация сайта
            «ТВОЯУБОРКА» может использовать в целях:
          </li>
          <li>
            4.1.1. Идентификации Пользователя, зарегистрированного на сайте
            «ТВОЯУБОРКА», для оформления заказа и (или) заключения Договора
            купли-продажи товара дистанционным способом с Название
            интернет-магазина.
          </li>
          <li>
            4.1.2. Предоставления Пользователю доступа к персонализированным
            ресурсам Сайта «ТВОЯУБОРКА».
          </li>
          <li>
            4.1.3. Установления с Пользователем обратной связи, включая
            направление уведомлений, запросов, касающихся использования Сайта
            «ТВОЯУБОРКА», оказания услуг, обработка запросов и заявок от
            Пользователя.
          </li>
          <li>
            4.1.4. Определения места нахождения Пользователя для обеспечения
            безопасности, предотвращения мошенничества.
          </li>
          <li>
            4.1.5. Подтверждения достоверности и полноты персональных данных,
            предоставленных Пользователем.
          </li>
          <li>
            4.1.6. Создания учетной записи для совершения покупок, если
            Пользователь дал согласие на создание учетной записи.
          </li>
          <li>
            4.1.7. Уведомления Пользователя Сайта «ТВОЯУБОРКА» о состоянии
            Заказа.
          </li>
          <li>
            4.1.8. Обработки и получения платежей, подтверждения налога или
            налоговых льгот, оспаривания платежа, определения права на получение
            кредитной линии Пользователем.
          </li>
          <li>
            4.1.9. Предоставления Пользователю эффективной клиентской и
            технической поддержки при возникновении проблем связанных с
            использованием Сайта «ТВОЯУБОРКА».
          </li>
          <li>
            4.1.10. Предоставления Пользователю с его согласия, обновлений
            продукции, специальных предложений, информации о ценах, новостной
            рассылки и иных сведений от имени Интернет-магазина или от имени
            партнеров Интернет-магазина.
          </li>
          <li>
            4.1.11. Осуществления рекламной деятельности с согласия
            Пользователя.
          </li>
          <li>
            4.1.12. Предоставления доступа Пользователю на сайты или сервисы
            партнеров Интернет- магазина с целью получения продуктов, обновлений
            и услуг.
          </li>
        </ul>

        <div className="landing__additional-title">
          5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
        </div>
        <ul className="landing__additional-list">
          <li>
            5.1. Обработка персональных данных Пользователя осуществляется без
            ограничения срока, любым законным способом, в том числе в
            информационных системах персональных данных с использованием средств
            автоматизации или без использования таких средств.
          </li>
          <li>
            5.2. Пользователь соглашается с тем, что Администрация сайта вправе
            передавать персональные данные третьим лицам, в частности,
            курьерским службам, организациями почтовой связи, операторам
            электросвязи, исключительно в целях выполнения заказа Пользователя,
            оформленного на Сайте интернет-магазина «ТВОЯУБОРКА», включая
            доставку Товара.
          </li>
          <li>
            5.3. Персональные данные Пользователя могут быть переданы
            уполномоченным органам государственной власти Российской Федерации
            только по основаниям и в порядке, установленным законодательством
            Российской Федерации.
          </li>
          <li>
            5.4. При утрате или разглашении персональных данных Администрация
            сайта информирует Пользователя об утрате или разглашении
            персональных данных.
          </li>
          <li>
            5.5. Администрация сайта принимает необходимые организационные и
            технические меры для защиты персональной информации Пользователя от
            неправомерного или случайного доступа, уничтожения, изменения,
            блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц.
          </li>
          <li>
            5.6. Администрация сайта совместно с Пользователем принимает все
            необходимые меры по предотвращению убытков или иных отрицательных
            последствий, вызванных утратой или разглашением персональных данных
            Пользователя.
          </li>
        </ul>

        <div className="landing__additional-title">6. ОБЯЗАТЕЛЬСТВА СТОРОН</div>
        <ul className="landing__additional-list">
          <li>
            6.1.1. Предоставить информацию о персональных данных, необходимую
            для пользования Сайтом «ТВОЯУБОРКА».
          </li>
          <li>
            6.1.2. Обновить, дополнить предоставленную информацию о персональных
            данных в случае изменения данной информации.
          </li>
          <li>
            <strong>6.2. Администрация сайта обязана:</strong>
          </li>
          <li>
            6.2.1. Использовать полученную информацию исключительно для целей,
            указанных в п. 4 настоящей Политики конфиденциальности.
          </li>
          <li>
            6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не
            разглашать без предварительного письменного разрешения Пользователя,
            а также не осуществлять продажу, обмен, опубликование, либо
            разглашение иными возможными способами переданных персональных
            данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей
            Политики Конфиденциальности.
          </li>
          <li>
            6.2.3. Принимать меры предосторожности для защиты конфиденциальности
            персональных данных Пользователя согласно порядку, обычно
            используемого для защиты такого рода информации в существующем
            деловом обороте.
          </li>
          <li>
            6.2.4. Осуществить блокирование персональных данных, относящихся к
            соответствующему Пользователю, с момента обращения или запроса
            Пользователя или его законного представителя либо уполномоченного
            органа по защите прав субъектов персональных данных на период
            проверки, в случае выявления недостоверных персональных данных или
            неправомерных действий.
          </li>
        </ul>

        <div className="landing__additional-title">
          7. ОТВЕТСТВЕННОСТЬ СТОРОН
        </div>
        <ul className="landing__additional-list">
          <li>
            7.1. Администрация сайта, не исполнившая свои обязательства, несёт
            ответственность за убытки, понесённые Пользователем в связи с
            неправомерным использованием персональных данных, в соответствии с
            законодательством Российской Федерации, за исключением случаев,
            предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
            Конфиденциальности.
          </li>
          <li>
            7.2. В случае утраты или разглашения Конфиденциальной информации
            Администрация сайта не несёт ответственность, если данная
            конфиденциальная информация:
          </li>
          <li>
            7.2.1. Стала публичным достоянием до её утраты или разглашения.
          </li>
          <li>
            7.2.2. Была получена от третьей стороны до момента её получения
            Администрацией сайта.
          </li>
          <li>7.2.3. Была разглашена с согласия Пользователя.</li>
        </ul>

        <div className="landing__additional-title">8. РАЗРЕШЕНИЕ СПОРОВ</div>
        <ul className="landing__additional-list">
          <li>
            8.1. До обращения в суд с иском по спорам, возникающим из отношений
            между Пользователем сайта Интернет-магазина и Администрацией сайта,
            обязательным является предъявление претензии (письменного
            предложения о добровольном урегулировании спора).
          </li>
          <li>
            8.2 .Получатель претензии в течение 30 календарных дней со дня
            получения претензии, письменно уведомляет заявителя претензии о
            результатах рассмотрения претензии.
          </li>
          <li>
            8.3. При не достижении соглашения спор будет передан на рассмотрение
            в судебный орган в соответствии с действующим законодательством
            Российской Федерации.
          </li>
          <li>
            8.4. К настоящей Политике конфиденциальности и отношениям между
            Пользователем и Администрацией сайта применяется действующее
            законодательство Российской Федерации.
          </li>
        </ul>

        <div className="landing__additional-title">
          9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
        </div>
        <ul className="landing__additional-list">
          <li>
            9.1. Администрация сайта вправе вносить изменения в настоящую
            Политику конфиденциальности без согласия Пользователя.
          </li>
          <li>
            9.2. Новая Политика конфиденциальности вступает в силу с момента ее
            размещения на Сайте «ТВОЯУБОРКА», если иное не предусмотрено новой
            редакцией Политики конфиденциальности.
          </li>
          <li>
            9.3. Все предложения или вопросы по настоящей Политике
            конфиденциальности следует сообщать указать раздел сайта
            «ТВОЯУБОРКА»
          </li>
          <li>
            9.4. Действующая Политика конфиденциальности размещена на странице
            по адресу https:// tvoyauborka.ru/.
          </li>
        </ul>
        <br />
        <div>Обновлено «11» января 2023 г.</div>
        <br />
      </section>
    </>
  );
};

export default Policy;
