// import BellClient from '../../../assets/bell-client.svg'
// import BellClientWhite from '../../../assets/BellClientWhite.svg'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../redux/slices/auth'
import { fetchUserInfo } from '../../../redux/slices/user'
import { FaWhatsapp } from 'react-icons/fa'
import { FaTelegram } from 'react-icons/fa'
import { BsFillTelephoneFill } from 'react-icons/bs'

const ModalCabinet = () => {
  // Меняет цвет шапки при скролле
  const [navScroll, setNavScroll] = useState(false)
  const showScroll = () => {
    if (window.scrollY > 80) {
      setNavScroll(true)
    } else {
      setNavScroll(false)
    }
  }
  window.addEventListener('scroll', showScroll)

  const [openUserModal, setOpenUserModal] = useState(false)

  const handleOpenUserModal = () => {
    setOpenUserModal(!openUserModal)
  }

  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)

  const onClickLogout = () => {
    dispatch(logout())
    localStorage.removeItem('token')
    document.location.href = '/'
  }

  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  return (
    <>
      <div className="nav__info-container">
        <div
          className={
            navScroll
              ? 'nav__info-name nav__info-name-scroll'
              : 'nav__info-name'
          }
        >
          {user.items.name ? <>Здравствуйте, {user.items.name} !</> : <></>}
        </div>
        {/* <div>
          <img src={navScroll ? BellClientWhite : BellClient} alt="icon" />
        </div> */}
        <div onClick={handleOpenUserModal} className="nav__cabinet">
          <span className="nav__cabinet-title">Мой кабинет</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            className="bi bi-person-circle"
            viewBox="0 0 16 16"
          >
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path
              fillRule="evenodd"
              d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
            />
          </svg>
          {openUserModal && (
            <>
              <span
                onClick={handleOpenUserModal}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  zIndex: 100,
                }}
              ></span>
              <div className="nav__cabinet-modal">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    width: '100%',
                    padding: '10px 0',
                  }}
                >
                  <a
                    href={`tel:89152640011`}
                    className="nav__container-info-phone"
                  >
                    {' '}
                    <BsFillTelephoneFill color="#5e92ff" />
                  </a>
                  <a
                    href="https://wa.me/+79152640011"
                    target="_blank"
                    rel="noreferrer"
                    className="nav__container-info-phone"
                    style={{
                      fontSize: '20px',
                    }}
                  >
                    {' '}
                    <FaWhatsapp color="#5e92ff" />
                  </a>
                  <a
                    href="https://t.me/tvoyauborka_RU"
                    target="_blank"
                    rel="noreferrer"
                    className="nav__container-info-phone"
                    style={{
                      fontSize: '20px',
                    }}
                  >
                    {' '}
                    <FaTelegram color="#5e92ff" />
                  </a>
                </div>
                <div>
                  <Link className="nav__cabinet-modal-link" to="/main">
                    Профиль
                  </Link>
                  <Link className="nav__cabinet-modal-link" to="/calculator">
                    ТвояУборка
                  </Link>
                  <div
                    onClick={onClickLogout}
                    className="nav__cabinet-modal-link"
                  >
                    Выйти
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalCabinet
