import { Calendar } from "react-multi-date-picker"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMultipleDate } from "../../../../redux/slices/calculator";

const MultipleCalendar = () => {
  const { dates: stateDates } = useSelector(({ calculator }) => calculator);
  let [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const newDates = [];
    stateDates.map(item => {
      const date = new Date(item.year, item.month - 1, item.day, item.hours, item.minutes)
      newDates.push(date)
    })

    setDates(newDates)
  }, [stateDates])

  const handleChangeDates = (values) => {
    const newDates = []
    values.map(item => {
      newDates.push({
        day: item.day,
        month: item.month.number,
        year: item.year,
        hours: item.hour,
        minutes: item.minute
      })
    })
    dispatch(changeMultipleDate(newDates))
    setDates(values)
  }

  return (
    <>
      <Calendar
        weekDays={['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']} // кастомизация недели // начинается с воскресенья
        months={[
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',]} // кастомизация месяцев
        weekStartDayIndex={1}
        className='calendar__services'
        value={dates}
        minDate={new Date()} // минимальная дата сегодня
        onChange={handleChangeDates}
        multiple
      />
    </>
  );
};

export default MultipleCalendar;