// import { motion } from "framer-motion";
// import { UpAnimation, OpacityAnimation } from "../../../../animations";
import "./BenefistSale.scss";

const BenefistSale = () => {
  return (
    <div className="benefits__wrapper feedback__wrapper">
      <div className="landing__container">
        <div className="benefist__sale">
          <div className="percent-block">
            <div className="title">Выгода с нами</div>
            <div className="percent-qty">30%</div>
          </div>
          <div className="offer-block">
            <div className="offer-title">Кэшбэк за каждую уборку!</div>
            <div className="offer-description">
              Все просто: больше уборок, больше выгоды
            </div>
            <div className="offer-explanation">
              <img
                src="https://airo.ru/images/landing/cleaning/clocks.svg"
                alt=""
              />
              <div>Экономь деньги с нами выгодно</div>
            </div>
          </div>
          <div className="benefist__sale-conditions-block">
            <div className="container container-border">
              <div>За каждую уборку начисляем</div>
              <div className="title">15%</div>
            </div>
            <div className="container container-border">
              <div>Первая уборка дешевле на</div>
              <div className="title">8%</div>
            </div>
            <div className="container">
              <div>Уборка каждую неделю выгодна на</div>
              <div className="title">20%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefistSale;
