import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BenefistSale from "../Client/landing/benefistSale/BenefistSale";
import Benefist from "../Client/landing/benefist/Benefist";
import QuestionsOrder from "../Client/landing/questionsOrder/questionsOrder";
import Feedback from "../Client/landing/feedback/Feedback";
import Sales from "../Client/landing/sales/Sales";
import OurEquipment from "../../components/our-equipment/OurEquipment";
import Questions from "../Client/landing/questions/Questions";
import { Helmet } from "react-helmet-async";

export const UborkaDomov = () => {
  const tabs = [
    {
      title: "Поддерживающая",
      content: "Поддерживающая уборка от 2520 ₽",
    },
    {
      title: "Генеральная",
      content: "Генеральная уборка от 5253 ₽",
    },
    {
      title: "После ремонта",
      content: "После ремонта уборка от 7000 ₽",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };

  return (
    <>
      <Helmet>
        <title>Закажите качественную уборку домов в Москве – Твоя Уборка</title>
        <meta
          name="description"
          content="Закажите профессиональную уборку домов в Москве от компании Твоя Уборка. Мы быстро и тщательно сделаем ваш дом чистым и уютным!"
        />
      </Helmet>
      <section style={{ overflow: "hidden" }}>
        <div className="landing__main">
          <div className="landing__container service-list-container">
            <ul className="service-list">
              <li>
                <Link to="/uslugi/uborka-kvartir">
                  <div>Уборка Квартиры</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/office/">
                  <div>Уборка офиса</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                  <div>Склад</div>
                </Link>
              </li>
              <li>
                <Link to="/cleaning">
                  <div>Химчистка</div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="landing__container ">
            <ul className="service-list">
              {tabs.map((tab, index) => (
                <li key={index} onClick={() => handleTabClick(index)}>
                  <div
                    className={`${
                      activeTab === index ? "service-list__active" : ""
                    }`}>
                    {tab.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="landing__wrapper-form">
          <div className="landing__container landing__container-tab">
            <div></div>

            {activeTab !== -1 ? (
              <div className="landing__main-form landing__main-form-tab">
                <div className="landing__form">
                  <div className="landing__form-title">
                    {tabs[activeTab].content}
                  </div>
                  <Link
                    to={"/calculator"}
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}>
                    Заказать уборку квартиры
                  </Link>
                  <div className="landing__form-border">
                    <a
                      href="https://yandex.ru/business/widget/request/company/157082417183"
                      target="_blank"
                      className="landing__form-button"
                      style={{
                        textDecoration: "none",
                      }}
                      rel="noreferrer">
                      Перезвонить мне
                    </a>
                  </div>
                  <div className="landing__promo">
                    <div
                      className="promo"
                      ref={promoRef}
                      onClick={copyToClipboard}>
                      First
                    </div>
                    <div className="title">
                      <strong>Скидка 8%</strong> на первый заказ{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="landing__main">
          <BenefistSale />
          <Benefist />
          <QuestionsOrder />
          <Feedback />

          <OurEquipment />
          <Sales />
        </div>
        <div className="benefits__wrapper">
          <div className="landing__container">
            <Questions />
          </div>
        </div>
        <div className="landing__container" style={{ padding: "22px 0" }}>
          <h1>
            Уборка домов в Москве: профессиональный клининг, который стоит
            вашего внимания
          </h1>
          <p>
            Ваш дом — это место, где вы находите уют, отдыхаете и проводите
            время с семьей. Однако поддержание чистоты в доме требует времени и
            усилий. Если вы ищете способ сэкономить время и наслаждаться
            чистотой без лишних забот, профессиональный клининг на дом от
            компании "Твоя уборка" — идеальное решение.
          </p>
          <h2>Почему стоит выбрать профессиональный клининг?</h2>
          <p>
            Во-первых, профессиональная уборка квартир и домов — это гарантия
            того, что ваш дом будет сиять чистотой. Используя профессиональные
            средства и современное оборудование, мы обеспечиваем качественную
            уборку.
          </p>
          <h2>Уборка домов: какие задачи мы решаем?</h2>
          <p>
            Мы предлагаем полный спектр услуг по уборке квартир и домов в
            Москве. Выполняем все виды работ: от генеральной уборки до
            поддерживающей. В зависимости от ваших нужд, вы можете выбрать
            комплексную услугу или заказать определенные задачи, например, мытье
            окон.
          </p>
          <h3>Уборка квартир и домов включает в себя:</h3>
          <ul>
            <li>тщательное очищение всех поверхностей</li>
            <li>уборку санузлов с дезинфекцией</li>
            <li>устранение пыли и грязи из труднодоступных мест</li>
            <li>мытье полов и чистку ковровых покрытий</li>
            <li>уход за мебелью и текстилем</li>
          </ul>
          <p>
            Каждая уборка проводится с использованием качественных и безопасных
            средств, что особенно важно, если в доме есть дети или домашние
            животные.
          </p>
          <h3>Стоимость услуг: доступная цена за качество</h3>
          <p>
            Цены на услуги клининга от компании "Твоя уборка" зависят от объема
            работы и площади вашего дома. Заказать уборку можно на нашем сайте,
            где также представлены все детали по стоимости услуг.
          </p>
          <p>
            Не нужно тратить время на поиск и покупку чистящих средств — мы
            привезем все необходимое с собой. Это не только удобно, но и
            выгодно, так как наши специалисты используют профессиональную химию,
            которая эффективно и безопасно справляется с загрязнениями.
          </p>
        </div>
      </section>
    </>
  );
};
