import "./PaymentHistory.scss";
import { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoOrder } from "../../../redux/slices/order";
// import { selectIsAuth } from '../../../redux/slices/auth';
import { months } from "../../../data";

const PaymentHistory = memo(() => {
  const dispatch = useDispatch();
  // const isAuth = useSelector(selectIsAuth);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(fetchInfoOrder());
  }, [dispatch]);
  return (
    <section>
      <ul className="completed-services__items">
        {order.items.length === 0 ||
        !order.items
          .slice()
          .reverse()
          .filter(item => {
            // Фильтрация объектов по условиям
            if (item.isCanceled || item.state) {
              return false;
            }
            // isPaid - оплачен ли заказ (true/false)
            if (!item.isPaid && item.payment_type === "Безналичный расчет") {
              return false;
            }
            if (item.payment_type === "Наличные") {
              return true;
            }
            return true; // Показывать остальные объекты
          }).length ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              color: "#b2b2b2",
              padding: "32px 0",
            }}>
            У вас нет заказов
          </div>
        ) : (
          order.items
            .slice()
            .reverse()
            .filter(item => {
              // Фильтрация объектов по условиям
              if (item.isCanceled || item.state) {
                return false;
              }
              if (!item.isPaid && item.payment_type === "Безналичный расчет") {
                return false;
              }
              if (item.payment_type === "Наличные") {
                return true;
              }
              return true;
            })
            .map((item, index) => {
              const date = new Date(item.date);
              const day = date.getDate();
              const month = months[date.getMonth()];
              const hours = date.getHours();
              const minutes = date.getMinutes().toString().padStart(2, "0");
              return (
                <li className="completed-services__item" key={index}>
                  <div>
                    <div className="completed-services__text">{item.group}</div>
                  </div>
                  <div className="completed-services__container">
                    <div>Сумма оплаты: {item.price} ₽</div>
                    <div className="completed-services__button">
                      Чек об оплате
                    </div>
                  </div>
                  <div>
                    <div className="">{`${day} ${month} ${hours}:${minutes}`}</div>
                  </div>
                </li>
              );
            })
        )}
      </ul>
    </section>
  );
});

export default PaymentHistory;
