import Switch from '@mui/material/Switch'
import { useEffect, useState } from 'react'
import MultipleCalendar from './MultipleCalendar'
import SingleCalendar from './SingleCalendar'
import { useDispatch, useSelector } from 'react-redux'
import { changeTimes, clearDates } from '../../../../redux/slices/calculator'
import TimeInputs from './TimeInputs'
import { memo } from 'react'
import { HiOutlineCursorClick } from 'react-icons/hi'

const nameWeekDays = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]

const CalculatorCalendar = memo(() => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } }
  const [checked, setChecked] = useState(false)
  const { dates } = useSelector(({ calculator }) => calculator)
  const [weekDays, setWeekDays] = useState([[], [], [], [], [], [], []])
  const dispatch = useDispatch()

  useEffect(() => {
    const months = [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ]

    const newDays = [[], [], [], [], [], [], []]
    dates.map((item) => {
      const dateObj = new Date(item.year, item.month - 1, item.day)
      const formattedDate = `${dateObj.getDate()} ${months[dateObj.getMonth()]}`

      if (item.weekDay === 0) {
        newDays[6].push(formattedDate)
      } else {
        newDays[item.weekDay - 1].push(formattedDate)
      }
    })
    setWeekDays(newDays)
  }, [dates])

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked)
    dispatch(clearDates())
  }

  const handleChangeTime = (weekDayNumber, hours, minutes) => {
    dispatch(
      changeTimes({
        weekDayNumber,
        hours,
        minutes,
      })
    )
  }

  // const [value, onChange] = useState(new Date());

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="additional-services__calendar-wrapper">
      <div
        className={
          open
            ? `additional-services__title additional-services__title__mobile`
            : `additional-services__title__mobile-open`
        }
        onClick={() => setOpen(!open)}
      >
        Время уборки
        <span className="additional-services__title__mobile-icon">
          <HiOutlineCursorClick />
        </span>
      </div>
      <span className={open ? `` : `additional-services__mobile`}>
        <div className="additional-services__title">
          Выберите день и время уборки
        </div>
        <div className="additional-services__switch">
          <div className="additional-services__title">Цикличное расписание</div>
          <Switch checked={checked} onChange={handleChangeSwitch} {...label} />
        </div>
        <div className="additional-services__time">
          {checked ? <MultipleCalendar /> : <SingleCalendar />}
          <div className="additional-services__inputs">
            {weekDays.map((weekday, index) => {
              if (weekday.length > 0) {
                return (
                  <div key={index}>
                    {nameWeekDays[index]}:
                    {weekday.map((date, innerIndex) => (
                      <span key={innerIndex}> {date}</span>
                    ))}
                    <TimeInputs
                      weekNumber={index}
                      callBack={handleChangeTime}
                    />
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      </span>
    </div>
  )
})

export default CalculatorCalendar
