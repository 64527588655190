import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  city: {
    cities: [
      "Москва",
      "Санкт-Петербург",
    ],
    selected: 0,
    status: "loaded",
  },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    setCity(state, action) {
      state.city.selected = action.payload;
    }
  }
});

export const { setCity } = citySlice.actions;

export const cityReducer = citySlice.reducer;