import { useState } from 'react'
// import IconClose from "../../../../assets/icon-close.svg";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  fetchUserInfo,
  fetchUserInfoChange,
} from '../../../../redux/slices/user'
import { useForm } from 'react-hook-form'

const AddAddress = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    // dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange())
  }, [dispatch])

  const [open, setOpen] = useState(false)
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      address: ['', '', ''],
    },
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUserInfoChange(values))
    setOpen(!open)
    document.location.reload()
    reset()
    return data
  }

  return (
    <div className="setting__data-add-item setting__address-modal">
      <div>Адрес:</div>
      {user.items && user.items.address && user.items.address.length > 0 ? (
        <>
          <div>{user.items.address[0]}</div>
          <div>{user.items.address[1]}</div>
          {user.items.address[2] && <div>{user.items.address[2]}</div>}
        </>
      ) : (
        <div>Адрес не указан</div>
      )}
      <div onClick={handleOpen} className="setting__data-button">
        Изменить
      </div>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="setting__data-change-modal-inputs">
            <input
              className="setting__data-change-modal-input"
              {...register('address[0]', {
                required: true,
              })}
              placeholder="Город"
              type="address"
            />
            <input
              className="setting__data-change-modal-input"
              {...register('address[1]', {
                required: true,
              })}
              placeholder="Улица, дом, квартира"
              type="address"
            />
            <input
              className="setting__data-change-modal-input"
              {...register('address[2]', {})}
              placeholder="Домофон"
              type="address"
            />
          </div>
          <button type="submit" className="setting__address-modal-button">
            Сохранить
          </button>
        </form>
      )}
    </div>
  )
}

export default AddAddress
