import { motion, AnimatePresence } from "framer-motion";
import { useState, memo } from "react";
import { QuestionsText } from "../../../../data";

const Questions = memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisible = () => setIsVisible(!isVisible);

  return (
    <>
      <div onClick={handleVisible} className="questions__main-title">
        Часто задаваемые вопросы
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            style={{ overflow: "hidden" }}
            transition={{ duration: 0.5 }}>
            {QuestionsText.map((item, index) => (
              <div key={index} className="questions__title-wrapper">
                <p className="questions__title">{item.title}</p>
                <p className="questions__text">{item.text}</p>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});

export default Questions;
