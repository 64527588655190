import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', fontSize: '32px', textDecoration: 'none', color: '#000000', padding: '48px', textAlign: 'center' }}>
        Страница не найдена
      </div>
      <Link to="/"
        style={{ display: 'flex', justifyContent: 'center', fontSize: '24px', textDecoration: 'none', color: '#2696d6', padding: '48px', width: '262px', margin: '0 auto' }}>
        Перейти на главную
      </Link>
    </>
  )
}

export default Error;