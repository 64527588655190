import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import { requestReducer } from "./slices/request";
import { userReducer } from "./slices/user";
import { orderReducer } from "./slices/order";
import { calculatorReducer } from "./slices/calculator";
import { cityReducer } from "./slices/city";
import {paymentStatusReducer} from "./slices/paymentStatus";

const store = configureStore({
  reducer: {
    auth: authReducer,
    request: requestReducer,
    order: orderReducer,
    user: userReducer,
    calculator: calculatorReducer,
    city: cityReducer,
    paymentStatus: paymentStatusReducer
  },
});

export default store;