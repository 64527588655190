import React, { useState, useEffect, useRef } from "react";
import { memo } from "react";
import "./TimeInputs.scss";

const TimeInputs = memo(({ date, callBack, weekNumber }) => {
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Генерация интервалов времени
  useEffect(() => {
    if (date && date.year && date.month && date.day) {
      const intervals = generateTimeIntervals();
      setTimeIntervals(intervals);
    }
  }, [date]);

  // Проверка доступности выбранного интервала
  useEffect(() => {
    if (selectedInterval) {
      const isIntervalAvailable = timeIntervals.some(
        interval =>
          interval.from.getHours() === selectedInterval.from.getHours() &&
          interval.from.getMinutes() === selectedInterval.from.getMinutes() &&
          interval.isAvailable
      );
      // Сброс выбранного интервала, если он недоступен
      if (!isIntervalAvailable) {
        setSelectedInterval(null);
      }
    }
  }, [selectedInterval, timeIntervals]);

  // Закрытие выпадающего списка при клике вне его
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Функция для преобразования строкового времени в объект Date
  const parseTime = (timeString, selectedDate) => {
    const [hour, minute] = timeString.split(":").map(Number);
    const date = new Date(selectedDate);
    date.setHours(hour, minute, 0, 0);
    return date;
  };

  const generateTimeIntervals = () => {
    const now = new Date();
    const selectedDate = new Date(date.year, date.month - 1, date.day);
    const isToday = now.toDateString() === selectedDate.toDateString();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    // Интервалы теперь имеют только fromHour
    const allIntervals = [
      { fromHour: "08:00" },
      { fromHour: "08:30" },
      { fromHour: "09:00" },
      { fromHour: "09:30" },
      { fromHour: "10:00" },
      { fromHour: "10:30" },
      { fromHour: "11:00" },
      { fromHour: "11:30" },
      { fromHour: "12:00" },
      { fromHour: "12:30" },
      { fromHour: "13:00" },
      { fromHour: "13:30" },
      { fromHour: "14:00" },
      { fromHour: "14:30" },
      { fromHour: "15:00" },
      { fromHour: "15:30" },
      { fromHour: "16:00" },
      { fromHour: "16:30" },
      { fromHour: "17:00" },
      { fromHour: "17:30" },
      { fromHour: "18:00" },
      { fromHour: "18:30" },
      { fromHour: "19:00" },
      { fromHour: "19:30" },
      { fromHour: "20:00" },
      { fromHour: "20:30" },
      { fromHour: "21:00" },
      { fromHour: "21:30" },
      { fromHour: "22:00" },
      { fromHour: "22:30" },
    ];

    return allIntervals.map(interval => {
      const fromTime = parseTime(interval.fromHour, selectedDate);

      let isAvailable = true;
      if (isToday) {
        // Проверка доступности интервала
        if (
          fromTime.getHours() < currentHour ||
          (fromTime.getHours() === currentHour &&
            fromTime.getMinutes() < currentMinutes)
        ) {
          isAvailable = false;
        }
      }

      return {
        from: fromTime,
        isAvailable: isAvailable,
        isToday, // Добавляем метку, если это сегодняшний день
      };
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectTime = interval => {
    if (!interval.isAvailable) {
      return;
    }
    setSelectedInterval(interval);
    setIsDropdownOpen(false);

    // Вызов callBack для обновления времени в родительском компоненте
    if (typeof weekNumber !== "undefined") {
      callBack(
        interval.from.getHours(),
        interval.from.getMinutes(),
        weekNumber
      );
    } else {
      callBack(interval.from.getHours(), interval.from.getMinutes());
    }
  };

  if (!date || !date.year || !date.month || !date.day) {
    console.warn("Invalid date object passed to TimeInputs.");
    return null;
  }

  return (
    <div className="time-inputs" ref={dropdownRef}>
      <div className="custom-select-container" onClick={toggleDropdown}>
        <div className="selected-value">
          {selectedInterval ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              <span className="custom-select-time">
                {selectedInterval.from.getHours().toString().padStart(2, "0")}
              </span>{" "}
              :{" "}
              <span className="custom-select-time">
                {selectedInterval.from.getMinutes().toString().padStart(2, "0")}
              </span>
              {/* Добавляем эмодзи молнии и текст +15%, если выбран сегодняшний день */}
              {selectedInterval.isToday && (
                <span className="extra-charge">⚡ +15%</span>
              )}
            </span>
          ) : (
            "Выберите время"
          )}
        </div>
      </div>
      {isDropdownOpen && (
        <div className="custom-select-dropdown">
          {timeIntervals.map((interval, index) => (
            <div
              key={index}
              className={`custom-select-option ${
                interval.isAvailable ? "" : "disabled-option"
              }`}
              onClick={() => handleSelectTime(interval)}>
              <span className="custom-select-time">
                {interval.from.getHours().toString().padStart(2, "0")}
              </span>{" "}
              :{" "}
              <span className="custom-select-time">
                {interval.from.getMinutes().toString().padStart(2, "0")}
              </span>
              {/* Добавляем эмодзи молнии и текст +15%, если выбран сегодняшний день */}
              {interval.isToday && (
                <span className="extra-charge">⚡ +15%</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default TimeInputs;
