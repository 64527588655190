import QuestionsOrderTab from "./QuestionsOrderTab";
import { motion } from "framer-motion";
import { UpAnimation } from "../../../../animations";

const QuestionsOrder = () => {
  return (
    <div className="benefits__wrapper">
      <div className="landing__container">
        <div className="benefits__title">Что входит в уборку</div>
        <QuestionsOrderTab />
      </div>
    </div>
  );
};

export default QuestionsOrder;
