import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import BenefitBlock from "../../../components/benefit-block/BenefitBlock";
import { Helmet } from "react-helmet-async";

const WarehousePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = index => {
    setActiveTab(index);
  };

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };
  return (
    <>
      <Helmet>
        <title>Заказать уборку складов в Москве – клининг без забот</title>
        <meta
          name="description"
          content="Качественная уборка складских помещений в Москве. Мы предлагаем услуги по уборке складов для формирования чистого и безопасного рабочего пространства."
        />
      </Helmet>
      <section style={{ overflow: "hidden" }}>
        <div className="landing__main">
          <div className="landing__container service-list-container">
            <ul className="service-list">
              <li>
                <Link to="/uslugi/uborka-kvartir">
                  <div>Уборка Квартиры</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/office/">
                  <div>Уборка офиса</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                  <div>Склад</div>
                </Link>
              </li>
              <li>
                <Link to="/cleaning">
                  <div>Химчистка</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="landing__wrapper-form">
          <div className="landing__container landing__container-tab">
            <div></div>

            {activeTab !== -1 ? (
              <div className="landing__main-form landing__main-form-tab">
                <div className="landing__form">
                  <div className="landing__form-title">
                    <div
                    // style={{ color: "#fac91e" }}
                    >
                      Храним ваше доверие
                    </div>
                    <div>храним ваше имущество.</div>
                  </div>
                  <Link
                    to={"/calculator"}
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}>
                    Заказать онлайн
                  </Link>
                  <div className="landing__form-border">
                    <a
                      href="https://yandex.ru/business/widget/request/company/157082417183"
                      target="_blank"
                      className="landing__form-button"
                      style={{
                        textDecoration: "none",
                      }}
                      rel="noreferrer">
                      Перезвонить мне
                    </a>
                  </div>
                  <div className="landing__promo">
                    <div
                      className="promo"
                      ref={promoRef}
                      onClick={copyToClipboard}>
                      First
                    </div>
                    <div className="title">
                      <strong>Скидка 8%</strong> на первый заказ{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <BenefitBlock
          description1={"За каждую уборку начисляем"}
          description2={"Первая уборка дешевле на"}
          description3={"Уборка каждую неделю выгодна на"}
        />
      </section>
    </>
  );
};

export default WarehousePage;
