// import Registration from "./Registration";
import Sign from "./Sign";

const Login = ({ openModal }) => {
  return (
    <div className="nav__login">
      {/* <Registration openModal={openModal}/> */}
      <Sign />
    </div>
  )
}

export default Login