import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Асинхронный thunk для регистрации
export const fetchRegistration = createAsyncThunk(
  "clearinghouseRegistration/fetchRegistration",
  async data => {
    const response = await axios.post("/api/users", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

const initialState = {
  clearinghouseRegistration: {
    status: "idle",
    error: null,
  },
};

const clearinghouseRegistrationSlice = createSlice({
  name: "clearinghouseRegistration",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRegistration.pending, state => {
        state.clearinghouseRegistration.status = "loading";
      })
      .addCase(fetchRegistration.fulfilled, state => {
        state.clearinghouseRegistration.status = "success";
      })
      .addCase(fetchRegistration.rejected, (state, action) => {
        state.clearinghouseRegistration.status = "failed";
        state.clearinghouseRegistration.error = action.error.message;
      });
  },
});

export default clearinghouseRegistrationSlice.reducer;
