import VacuumCleaner from "../../assets/vacuum-cleaner.jpg";
import Vacuum from "../../assets/vacuum.jpg";
import SteamGenerator from "../../assets/steam-generator.jpg";
import { motion } from "framer-motion";
import { UpAnimation, OpacityAnimation } from "../../animations";
import "./OurEquipment.scss";

const OurEquipment = () => {
  return (
    <div className="benefits__wrapper">
      <div className="landing__container our-equipment">
        <div className="benefits__title">Наше обурудование</div>
        <div className="our-equipment__list">
          <div className="our-equipment__list-item">
            <img src={VacuumCleaner} alt="Пылесос" />
          </div>
          <div className="our-equipment__list-item">
            <img src={Vacuum} alt="Пылесос" />
          </div>
          <div className="our-equipment__list-item">
            <img src={SteamGenerator} alt="Парогенератор" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurEquipment;
