import { motion } from "framer-motion";
import { UpAnimation, OpacityAnimation } from "../../animations";
import "./BenefitBlock.scss";

const BenefistSale = ({
  title,
  percent,
  description1,
  description2,
  description3,
  conditions,
}) => {
  return (
    <div className="benefits__wrapper feedback__wrapper">
      <div className="landing__container">
        <div className="benefist__sale">
          <div className="percent-block">
            <div custom={3} className="title">
              Выгода с нами
            </div>
            <div className="percent-qty">30%</div>
          </div>
          <div className="offer-block">
            <div className="offer-title">Кэшбэк за каждую уборку!</div>
            <div className="offer-description">
              Все просто: больше уборок, больше выгоды
            </div>
            <div className="offer-explanation">
              <motion.img
                custom={1}
                variants={OpacityAnimation}
                transition={{
                  delay: 0.9,
                }}
                src="https://airo.ru/images/landing/cleaning/clocks.svg"
                alt=""
              />
              <div>Экономь деньги с нами выгодно</div>
            </div>
          </div>
          <div className="benefist__sale-conditions-block">
            <div className="container container-border">
              <div>{description1}</div>
              <div className="title">15%</div>
            </div>
            <div className="container container-border">
              <div>{description2}</div>
              <div className="title">8%</div>
            </div>
            <div className="container">
              <div>{description3}</div>
              <div className="title">20%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefistSale;
