import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMainCleaning } from '../../../../redux/slices/calculator'
import {
  servicesDataTypeCleaning,
  servicesDataTypeHouse,
  servicesDataTypeRooms,
  servicesDataTypeBathrooms,
  servicesDataTypeFloors,
} from '../../../../data'
import { Link } from 'react-router-dom'

const CalculatorCleaning = () => {
  const [selectedOption, setSelectedOption] = useState(null)

  const dispatch = useDispatch()
  const { city } = useSelector((state) => state.city)

  const [optionCleaning, setOptionCleaning] = useState({
    optionCleaning: {
      cleaningType: 0,
      roomType: 0,
      bathroom: 0,
      roomFlatSupporting: 0,
      square: 1,
      roomFlatGeneral: 0,
      roomFlat: 0,
      whatPurpose: '',
      numberOfFloors: 1,
      elevator: false,
    },
  })

  // Изменение типа уборки
  const toggleActiveButton = (id) => {
    const item = {
      city: city.selected,
      typeId: id,
      roomType: optionCleaning.optionCleaning.roomType,
      name: servicesDataTypeCleaning[id].name,
      square: optionCleaning.optionCleaning.square,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }
    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        cleaningType: id,
      },
    })

    dispatch(updateMainCleaning(item))
  }
  // Изменение количества комнат
  const toggleRoomFlat = (index) => {
    const item = {
      city: city.selected,
      rooms: index,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }
    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        roomFlat: index,
        roomFlatGeneral: index,
        roomFlatSupporting: index,
      },
    })
    dispatch(updateMainCleaning(item))
  }
  // Изменение количества ванных комнат
  const toggleBathroom = (id) => {
    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: id,
    }
    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        bathroom: id,
      },
    })
    dispatch(updateMainCleaning(item))
  }
  // Изменение количества этажей
  const toggleFloor = (id) => {
    const item = {
      name: servicesDataTypeFloors[id].name,
      title: servicesDataTypeFloors[id].title,
      price: servicesDataTypeFloors[id].price,
      count: servicesDataTypeFloors[id].count + 1,
    }
    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        floor: id,
      },
    })
    dispatch(updateMainCleaning(item))
  }

  // Изменение типа помещения
  const toggleTab = (id) => {
    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: id,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }

    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        roomType: id,
      },
    })
    dispatch(updateMainCleaning(item))
  }
  const toggleRoomFlatTwo = (id) => {
    const item = {
      city: city.selected,
      rooms: id,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }

    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        roomFlat: id,
        roomFlatGeneral: id,
        roomFlatSupporting: id,
      },
    })
    dispatch(updateMainCleaning(item))
  }
  const toggleRoomFlatRepair = (id) => {
    const item = {
      city: city.selected,
      rooms: id,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }

    setOptionCleaning({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        roomFlat: id,
        roomFlatGeneral: id,
        roomFlatSupporting: id,
      },
    })
    dispatch(updateMainCleaning(item))
  }

  const handleChangeInput = ({ target: { value } }) => {
    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: /\d+/.test(Number(value))
        ? Number(value)
        : optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
    }
    setOptionCleaning((prev) => ({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        square: /\d+/.test(Number(value))
          ? Number(value)
          : prev.optionCleaning.square,
      },
    }))
    dispatch(updateMainCleaning(item))
  }

  const handleWhatPurpose = ({ target: { value } }) => {
    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
      whatPurpose: value,
      numberOfFloors: optionCleaning.optionCleaning.numberOfFloors,
      elevator: optionCleaning.optionCleaning.elevator,
    }

    setOptionCleaning((prev) => ({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        whatPurpose: value,
      },
    }))
    dispatch(updateMainCleaning(item))
  }
  const handleNumberOfFloors = ({ target: { value } }) => {
    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
      whatPurpose: optionCleaning.optionCleaning.whatPurpose,
      numberOfFloors: /\d+/.test(Number(value))
        ? Number(value)
        : optionCleaning.numberOfFloors,
      elevator: optionCleaning.optionCleaning.elevator,
    }

    setOptionCleaning((prev) => ({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        numberOfFloors: /\d+/.test(Number(value))
          ? Number(value)
          : prev.optionCleaning.numberOfFloors,
      },
    }))
    dispatch(updateMainCleaning(item))
  }
  const handleElevator = (event) => {
    setSelectedOption(event.target.value)
    const elevator = event.target.value === 'Да'

    const item = {
      city: city.selected,
      rooms: optionCleaning.optionCleaning.roomFlatSupporting,
      roomType: optionCleaning.optionCleaning.roomType,
      typeId: optionCleaning.optionCleaning.cleaningType,
      name: servicesDataTypeCleaning[optionCleaning.optionCleaning.cleaningType]
        .name,
      square: optionCleaning.optionCleaning.square,
      bathroom: optionCleaning.optionCleaning.bathroom,
      whatPurpose: optionCleaning.optionCleaning.whatPurpose,
      numberOfFloors: optionCleaning.optionCleaning.numberOfFloors,
      elevator: elevator,
    }

    setOptionCleaning((prev) => ({
      optionCleaning: {
        ...optionCleaning.optionCleaning,
        elevator: elevator,
      },
    }))
  }

  return (
    <div className="calculator__cleaning-wrapper">
      <div className="calculator__cleaning-titles calculator__cleaning-tabs">
        {servicesDataTypeCleaning.map((item, index) => (
          <div
            key={index}
            onClick={() => toggleActiveButton(index)}
            className={
              optionCleaning.optionCleaning.cleaningType === index
                ? 'calculator__cleaning-title calculator__cleaning-title-active'
                : 'calculator__cleaning-title'
            }
          >
            {item.name}
            <span className="calculator-text__svg">
              <svg
                className="additional-services__item-img-info"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
                  fill="white"
                />
                <path
                  d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
                  fill="white"
                />
                <path
                  d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
                  fill="white"
                />
              </svg>
              <Link
                to={'/prices'}
                className="calculator-text__svg-modal"
                style={{ textDecoration: 'none' }}
              >
                Узнать в чем разница
              </Link>
            </span>
          </div>
        ))}
      </div>

      <div className="calculator__cleaning-content calculator__cleaning-content-border">
        <div
          className={
            optionCleaning.optionCleaning.cleaningType === 0
              ? 'content content__active'
              : 'content'
          }
        >
          <div className="calculator__cleaning-tabs">
            {servicesDataTypeHouse.map((item, index) => (
              <div
                key={index}
                onClick={() => toggleTab(index)}
                className={
                  optionCleaning.optionCleaning.roomType === index
                    ? 'tab tab__active'
                    : 'tab'
                }
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className="calculator__cleaning-content">
            <div
              className={
                optionCleaning.optionCleaning.roomType === 0
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Количество комнат
                  <span className="calculator-text__svg">
                    <svg
                      className="additional-services__item-img-info"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
                        fill="black"
                      />
                      <path
                        d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
                        fill="black"
                      />
                      <path
                        d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
                        fill="black"
                      />
                    </svg>
                    <Link
                      to={'/prices'}
                      className="calculator-text__svg-modal"
                      style={{ textDecoration: 'none' }}
                    >
                      Узнать в чем разница
                    </Link>
                  </span>
                </span>

                <ul className="content__rooms">
                  {servicesDataTypeRooms.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => toggleRoomFlat(index)}
                      className={
                        optionCleaning.optionCleaning.roomFlatSupporting ===
                        index
                          ? 'tab content__rooms_active'
                          : 'tab'
                      }
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="content__container">
                <div className="calculator-text__container">
                  <span className="calculator-text calculator-text-bathroom-icon">
                    Количество санузлов
                  </span>
                  {/* <img src={QuestionIcon} alt="icon" /> */}
                </div>
                <ul className="content__rooms">
                  {servicesDataTypeBathrooms.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => toggleBathroom(index)}
                      className={
                        optionCleaning.optionCleaning.bathroom === index
                          ? 'tab content__rooms_active'
                          : 'tab'
                      }
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className={
                optionCleaning.optionCleaning.roomType === 1
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="calculator-text-wrapper">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Что убираем</span>
                <label className="content__label" htmlFor="">
                  <input
                    onChange={handleWhatPurpose}
                    placeholder="Офис / Ресторан"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Сколько у Вас этажей</span>
                <label className="content__label">
                  <input
                    onChange={handleNumberOfFloors}
                    placeholder="1"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>
              <div className="calculator-text-wrapper">
                <span className="calculator-text">Есть лифт?</span>
                <div>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Да' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Да' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Да
                    <input
                      id="radioYes"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Да"
                    />
                  </label>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Нет' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Нет' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Нет
                    <input
                      id="radioNo"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Нет"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                optionCleaning.optionCleaning.roomType === 2
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>
              <div className="content__container">
                <span className="calculator-text">Количество этажей</span>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleFloor(0)}
                    className={
                      optionCleaning.optionCleaning.floor === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleFloor(1)}
                    className={
                      optionCleaning.optionCleaning.floor === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                  <li
                    onClick={() => toggleFloor(2)}
                    className={
                      optionCleaning.optionCleaning.floor === 2
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            optionCleaning.optionCleaning.cleaningType === 1
              ? 'content content__active'
              : 'content'
          }
        >
          <div className="calculator__cleaning-tabs">
            <div
              onClick={() => toggleTab(0)}
              className={
                optionCleaning.optionCleaning.roomType === 0
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Квартира
            </div>
            <div
              onClick={() => toggleTab(1)}
              className={
                optionCleaning.optionCleaning.roomType === 1
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Нежилое помещение
            </div>
            <div
              onClick={() => toggleTab(2)}
              className={
                optionCleaning.optionCleaning.roomType === 2
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Дом
            </div>
          </div>

          <div className="calculator__cleaning-content">
            <div
              className={
                optionCleaning.optionCleaning.roomType === 0
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Количество комнат
                  <span className="calculator-text__svg">
                    <svg
                      className="additional-services__item-img-info"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
                        fill="black"
                      />
                      <path
                        d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
                        fill="black"
                      />
                      <path
                        d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
                        fill="black"
                      />
                    </svg>
                    <Link
                      to={'/prices'}
                      className="calculator-text__svg-modal"
                      style={{ textDecoration: 'none' }}
                    >
                      Узнать в чем разница
                    </Link>
                  </span>
                </span>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleRoomFlatTwo(0)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(1)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(2)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 2
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    3
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(3)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 3
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    4
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(4)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 4
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    5
                  </li>
                </ul>
              </div>
              <div className="content__container">
                <div className="calculator-text__container">
                  <span className="calculator-text calculator-text-bathroom-icon">
                    Количество санузлов
                  </span>
                  {/* <img src={QuestionIcon} alt="icon" /> */}
                </div>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleBathroom(0)}
                    className={
                      optionCleaning.optionCleaning.bathroom === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleBathroom(1)}
                    className={
                      optionCleaning.optionCleaning.bathroom === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                optionCleaning.optionCleaning.roomType === 1
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="calculator-text-wrapper">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Что убираем</span>
                <label className="content__label" htmlFor="">
                  <input
                    onChange={handleWhatPurpose}
                    placeholder="Офис / Ресторан"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Сколько у Вас этажей</span>
                <label className="content__label" htmlFor="">
                  <input
                    onChange={handleNumberOfFloors}
                    placeholder="1"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>
              <div className="calculator-text-wrapper">
                <span className="calculator-text">Есть лифт?</span>
                <div>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Да' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Да' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Да
                    <input
                      id="radioYes"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Да"
                    />
                  </label>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Нет' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Нет' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Нет
                    <input
                      id="radioNo"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Нет"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                optionCleaning.optionCleaning.roomType === 2
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>
              <div className="content__container">
                <span className="calculator-text">Количество этажей</span>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleRoomFlatTwo(0)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(1)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(2)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 2
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            optionCleaning.optionCleaning.cleaningType === 2
              ? 'content content__active'
              : 'content'
          }
        >
          <div className="calculator__cleaning-tabs">
            <div
              onClick={() => toggleTab(0)}
              className={
                optionCleaning.optionCleaning.roomType === 0
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Квартира
            </div>
            <div
              onClick={() => toggleTab(1)}
              className={
                optionCleaning.optionCleaning.roomType === 1
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Нежилое помещение
            </div>
            <div
              onClick={() => toggleTab(2)}
              className={
                optionCleaning.optionCleaning.roomType === 2
                  ? 'tab tab__active'
                  : 'tab'
              }
            >
              Дом
            </div>
          </div>

          <div className="calculator__cleaning-content">
            <div
              className={
                optionCleaning.optionCleaning.roomType === 0
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Количество комнат
                  <span className="calculator-text__svg">
                    <svg
                      className="additional-services__item-img-info"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
                        fill="black"
                      />
                      <path
                        d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
                        fill="black"
                      />
                      <path
                        d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
                        fill="black"
                      />
                    </svg>
                    <Link
                      to={'/prices'}
                      className="calculator-text__svg-modal"
                      style={{ textDecoration: 'none' }}
                    >
                      Узнать в чем разница
                    </Link>
                  </span>
                </span>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleRoomFlatRepair(0)}
                    className={
                      optionCleaning.optionCleaning.roomFlat === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleRoomFlatRepair(1)}
                    className={
                      optionCleaning.optionCleaning.roomFlat === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                  <li
                    onClick={() => toggleRoomFlatRepair(2)}
                    className={
                      optionCleaning.optionCleaning.roomFlat === 2
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    3
                  </li>
                  <li
                    onClick={() => toggleRoomFlatRepair(3)}
                    className={
                      optionCleaning.optionCleaning.roomFlat === 3
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    4
                  </li>
                  <li
                    onClick={() => toggleRoomFlatRepair(4)}
                    className={
                      optionCleaning.optionCleaning.roomFlat === 4
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    5
                  </li>
                </ul>
              </div>
              <div className="content__container">
                <div className="calculator-text__container">
                  <span className="calculator-text calculator-text-bathroom-icon">
                    Количество санузлов
                  </span>
                  {/* <img src={QuestionIcon} alt="icon" /> */}
                </div>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleBathroom(0)}
                    className={
                      optionCleaning.optionCleaning.bathroom === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleBathroom(1)}
                    className={
                      optionCleaning.optionCleaning.bathroom === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                optionCleaning.optionCleaning.roomType === 1
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="calculator-text-wrapper">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Что убираем</span>
                <label className="content__label" htmlFor="">
                  <input
                    onChange={handleWhatPurpose}
                    placeholder="Офис / Ресторан"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>

              <div className="calculator-text-wrapper">
                <span className="calculator-text">Сколько у Вас этажей</span>
                <label className="content__label" htmlFor="">
                  <input
                    onChange={handleNumberOfFloors}
                    placeholder="1"
                    className="content__input"
                    type="text"
                  />
                </label>
              </div>
              <div className="calculator-text-wrapper">
                <span className="calculator-text">Есть лифт?</span>
                <div>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Да' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Да' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Да
                    <input
                      id="radioYes"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Да"
                    />
                  </label>
                  <label
                    className="calculator-content__label"
                    style={{
                      background:
                        selectedOption === 'Нет' ? '#2696D6' : '#FFFFFF',
                      color: selectedOption === 'Нет' ? '#FFFFFF' : '#000000',
                    }}
                  >
                    Нет
                    <input
                      id="radioNo"
                      onChange={handleElevator}
                      className="calculator-content__input"
                      type="radio"
                      name="lift"
                      value="Нет"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div
              className={
                optionCleaning.optionCleaning.roomType === 2
                  ? 'content content__active'
                  : 'content'
              }
            >
              <div className="content__container">
                <span className="calculator-text">
                  Площадь Вашего помещения
                </span>
                <label className="content__label" htmlFor="">
                  <input
                    value={optionCleaning.optionCleaning.square}
                    onChange={handleChangeInput}
                    className="content__input"
                    type="text"
                  />
                  <span>М2</span>
                </label>
              </div>
              <div className="content__container">
                <span className="calculator-text">Количество этажей</span>
                <ul className="content__rooms">
                  <li
                    onClick={() => toggleRoomFlatTwo(0)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 0
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    1
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(1)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 1
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    2
                  </li>
                  <li
                    onClick={() => toggleRoomFlatTwo(2)}
                    className={
                      optionCleaning.optionCleaning.roomFlatGeneral === 2
                        ? 'tab content__rooms_active'
                        : 'tab'
                    }
                  >
                    3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalculatorCleaning
