import { useSelector } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  clearProducts,
  fetchDiscount,
} from "../../../../redux/slices/calculator";
import ModalOrder from "../modal-order/ModalOrder";

// import { fetchUserInfo } from "../../../../redux/slices/user";
// import Registration from "../../../../components/client/header/Registration";
import {
  fetchOrderCalculator,
  changeOrderData,
} from "../../../../redux/slices/calculator";
import { months } from "../../../../data";
import LinksCalculator from "../../../../shared/ui/LinksCalculator/LinksCalculator";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import "react-dadata/dist/react-dadata.css";
import { AddressSuggestions } from "react-dadata";
import "./CalculatorDataOrder.scss";

const CalculatorDataOrder = ({ setValuesPaymentPage }) => {
  let navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const tokenAPI = "3d63f990833cd7209df7786bd37d68a30d8f9df9";

  const dispatch = useDispatch();
  const { items, discount, totalPrice, totalPriceWithDiscount, dates } =
    useSelector(({ calculator }) => calculator);
  const { city } = useSelector(({ city }) => city);
  const { user } = useSelector(state => state.user);
  const onClickClear = () => {
    dispatch(clearProducts(city.selected));
  };
  const [promo, setPromo] = useState("");

  const token = !window.localStorage.getItem("token");

  const [showRegistration, setShowRegistration] = useState(false);

  const handleOpenModal = () => {
    setShowRegistration(true);
  };

  const handleChange = event => {
    setPromo(event.target.value);
  };

  const handleSubmitPromo = event => {
    if (promo) {
      dispatch(fetchDiscount(promo));
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    values,
    getValues,
    trigger,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      region: "",
      address: "",
      flatNumber: "",
      intercom: "",
      phoneNumber: "",
      comment: "",
      user_name: "",
      additional_services: [],
    },
  });

  const scrollToFirstInvalidField = () => {
    const inputFields = document.querySelectorAll(".calculator__data-input"); // Выберите все поля ввода
    for (let inputField of inputFields) {
      if (!inputField.classList.contains("invalid")) {
        // Найдите первое незаполненное поле
        inputField.scrollIntoView({ behavior: "smooth" }); // Прокручиваем до поля
        break; // Прерываем цикл, после нахождения первого незаполненного поля
      }
    }
  };

  const [isFieldsFilled, setIsFieldsFilled] = useState(true);

  const [regionValue, setRegionValue] = useState("");
  const [isRegionValid, setIsRegionValid] = useState(true);

  const [addressValue, setAddressValue] = useState("");
  const [usernameValue, setUsernameValue] = useState("");
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [flatNumberValue, setFlatNumberValue] = useState("");
  const [isFlatNumberValid, setIsFlatNumberValid] = useState(true);

  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const handleRegionChange = e => {
    setRegionValue(e.target.value);
    updateIsFieldsFilled();
  };
  const handleRegionBlur = () => {
    if (regionValue === "") {
      setIsRegionValid(false);
    } else {
      setIsRegionValid(true);
    }
  };

  const handleAddressBlur = () => {
    if (addressValue.trim() === "") {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  };
  // const handleAddressChange = e => {
  //   setAddressValue(e.target.value);
  //   updateIsFieldsFilled();
  // };
  // const handleAddressChange = suggestion => {
  //   if (suggestion && suggestion.value) {
  //     setAddressValue(suggestion.value);
  //     updateIsFieldsFilled(); // Проверка заполненности полей
  //     console.log(suggestion.value);
  //   }
  // };

  // const handleAddressChange = suggestion => {
  //   if (suggestion && suggestion.value) {
  //     setAddressValue(suggestion.value); // Обновляем состояние для отображения
  //     setValue("address", suggestion.value); // Обновляем значение в useForm
  //   }
  // };
  const handleAddressChange = suggestion => {
    if (suggestion && suggestion.value) {
      setAddressValue(suggestion.value);
      setValue("address", suggestion.value);
      setIsAddressValid(true);
    } else {
      setIsAddressValid(false);
    }
    updateButtonState(); // Проверяем состояние кнопки
  };

  const handleUsernameChange = e => {
    setUsernameValue(e.target.value);
    updateIsFieldsFilled();
  };

  const handleFlatNumberBlur = () => {
    if (flatNumberValue.trim() === "") {
      setIsFlatNumberValid(false);
    } else {
      setIsFlatNumberValid(true);
    }
  };
  // const handleFlatNumberChange = e => {
  //   setFlatNumberValue(e.target.value);
  //   updateIsFieldsFilled();
  // };
  const handleFlatNumberChange = e => {
    setFlatNumberValue(e.target.value);
    updateIsFieldsFilled();
    if (e.target.value.trim() !== "") {
      setIsFlatNumberValid(true);
    } else {
      setIsFlatNumberValid(false);
    }
    updateButtonState(); // Проверяем состояние кнопки
  };

  const handlePhoneNumberBlur = () => {
    if (phoneNumberValue.trim() === "") {
      setIsPhoneNumberValid(false);
    } else {
      setIsPhoneNumberValid(true);
    }
  };
  // const handlePhoneNumberChange = e => {
  //   setPhoneNumberValue(e.target.value);
  //   updateIsFieldsFilled();
  // };
  const handlePhoneNumberChange = e => {
    setPhoneNumberValue(e.target.value);
    updateIsFieldsFilled();
    if (e.target.value.trim() !== "") {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
    updateButtonState(); // Проверяем состояние кнопки
  };

  const updateIsFieldsFilled = () => {
    if (regionValue && addressValue && flatNumberValue && phoneNumberValue) {
      setIsFieldsFilled(true);
    } else {
      setIsFieldsFilled(false);
    }
  };

  const handleToOrderPayment = async () => {
    if (await trigger()) {
      let data = getValues();
      dispatch(changeOrderData({ orderData: data }));
      navigate("/payment-order");
    } else {
      setIsFieldsFilled(false);
      console.log("не все поля заполнены");
      scrollToFirstInvalidField();
    }
  };

  if (
    regionValue &&
    addressValue &&
    flatNumberValue &&
    (!token || (token && phoneNumberValue))
  ) {
    const button = document.querySelector(".calculator__data-button");
    if (button) {
      button.classList.remove("calculator__data-button-disabled");
    }
  }

  const onSubmit = async values => {
    setValuesPaymentPage(values);

    values.payment_type = payment ? "Безналичный расчет" : "Наличные";
    const data = await dispatch(fetchOrderCalculator(values));
    console.log(data);
    reset();
    setTimeout(() => {
      setShowPopup(true);
    }, 100);
    return data;
  };

  const [payment, setPayment] = useState(0);
  const togglePayment = index => {
    setPayment(index);
  };

  const targetDivRef = useRef(null);

  const handleScrollToTarget = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    updateButtonState();
  }, [
    isPhoneNumberValid,
    isFlatNumberValid,
    isAddressValid,
    phoneNumberValue,
    usernameValue,
    flatNumberValue,
    addressValue,
  ]);

  const [placeholder, setPlaceholder] = useState("Введите улицу и номер дома");
  const [isFocused, setIsFocused] = useState(false);

  // Функция для обновления состояния кнопки
  const updateButtonState = () => {
    if (isPhoneNumberValid && isFlatNumberValid && isAddressValid) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  return (
    <>
      {!token ? (
        <>
          <div className="calculator__data">
            {!token ? (
              <div className="calculator__data-title">
                Бонусы:
                {user.items.bonusBalance ? (
                  <> {user.items.bonusBalance}р</>
                ) : (
                  "0р"
                )}
              </div>
            ) : null}
            <form ref={targetDivRef} onSubmit={handleSubmit(onSubmit)}>
              {/* <input
                className={`calculator__data-input ${
                  !isRegionValid ? "invalid" : ""
                }`}
                {...register("region", { required: "Город" })}
                type="text"
                placeholder="Город"
                value={regionValue}
                onChange={handleRegionChange}
                // onBlur={handleRegionBlur}
              />
              {!isRegionValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )} */}
              <div className="address-suggestions-wrapper">
                {!isFocused && !addressValue && (
                  <div className="custom-placeholder">
                    Введите улицу и номер дома
                  </div>
                )}
                <AddressSuggestions
                  className={`calculator__data-input custom-address-input`}
                  token={tokenAPI}
                  value={addressValue}
                  onChange={handleAddressChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  filterLocations={[
                    { kladr_id: "77" }, // Москва
                    { kladr_id: "50" }, // Московская область
                    { kladr_id: "78" }, // Санкт-Петербург
                  ]}
                />
              </div>
              {/* <input
                className="calculator__data-input"
                {...register("address", { required: "Улица / Номер дома" })}
                type="text"
                placeholder="Улица/Номер дома"
                defaultValue
                value={addressValue}
                onChange={handleAddressChange}
                // onBlur={handleAddressBlur}
              />
              {!isAddressValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )} */}
              <input
                className="calculator__data-input"
                {...register("flatNumber", {
                  required: "Укажите номер квартиры",
                })}
                type="text"
                placeholder="Квартира"
                defaultValue
                value={flatNumberValue}
                onChange={handleFlatNumberChange}
                // onBlur={handleFlatNumberBlur}
              />
              {!isFlatNumberValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )}
              <input
                className="calculator__data-input"
                {...register("intercom")}
                type="text"
                placeholder="Наличие домофона"
                defaultValue
              />
              <textarea
                {...register("comment")}
                className="calculator__data-textarea"
                placeholder="Ваши пожелания"
                cols="10"
                rows="10"
                defaultValue></textarea>
              <ul className="calculator__data-list">
                {items.map((item, index) => (
                  <li key={index} {...register("additional_services")}>
                    <div>{item.name}</div>
                    <div>
                      {item.count && item.count > 1 ? `${item.count} x ` : null}
                      {item.price}₽
                    </div>
                  </li>
                ))}
                {discount ? (
                  <li>
                    <div>Скидка - {discount.discount * 100}%</div>
                    <div> -{totalPrice * discount.discount}₽</div>
                  </li>
                ) : null}
                {dates.map((item, index) => {
                  const date = new Date(item.year, item.month - 1, item.day);
                  const day = date.getDate();
                  const month = months[date.getMonth()];
                  const time = `${item.hours
                    .toString()
                    .padStart(2, "0")}:${item.minutes
                    .toString()
                    .padStart(2, "0")}`;

                  return (
                    <li key={index} {...register("additional_services")}>
                      <div>Дата:</div>
                      <div>{`${day} ${month} ${time}`}</div>
                    </li>
                  );
                })}
              </ul>
              <div className="calculator__data-total">
                {totalPrice ? (
                  <>
                    <div>{totalPriceWithDiscount * dates.length}₽ Сумма</div>
                    <div>{dates.length} шт.</div>
                  </>
                ) : (
                  <div>Выберите услугу</div>
                )}
              </div>
              <div
                className="calculator__data-pay-delete"
                onClick={() => onClickClear()}>
                Удалить все услуги
              </div>
              <div className="calculator__data-input-promo">
                <input
                  type="text"
                  className="calculator__data-input"
                  placeholder="Промокод"
                  value={promo}
                  onChange={handleChange}
                />
                <div
                  className={`calculator__data-input-promo-button ${
                    discount ? "calculator__data-input-promo-button-bg" : ""
                  }`}
                  // className="calculator__data-input-promo-button"
                  onClick={handleSubmitPromo}>
                  Применить
                </div>
              </div>
              {/* <input*/}
              {/*  type="submit"*/}
              {/*  className="calculator__data-button"*/}
              {/*  value={'Перейти к оплате'}*/}
              {/*/> */}
              {!isFieldsFilled ? <div>Заполните данные</div> : null}
              <span
                onClick={isButtonEnabled ? handleToOrderPayment : null}
                className={`calculator__data-button ${
                  isButtonEnabled ? "" : "calculator__data-button-disabled"
                }`}>
                Перейти к оплате
              </span>
            </form>
            <div>
              Отменить услугу можно не позднее, чем за 1 час до начала оказания
              услуг
            </div>

            <LinksCalculator />
          </div>
          <div
            className="calculator-data__mobile"
            onClick={handleScrollToTarget}>
            <div>
              {totalPriceWithDiscount * dates.length}₽
              <BsArrowRight className="calculator-data__mobile-arrow" />
            </div>
          </div>
          {showPopup && <ModalOrder />}
        </>
      ) : (
        <>
          <div ref={targetDivRef} className="calculator__data">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="address-suggestions-wrapper">
                {!isFocused && !addressValue && (
                  <div className="custom-placeholder">
                    Введите улицу и номер дома
                  </div>
                )}
                <AddressSuggestions
                  className={`calculator__data-input custom-address-input`}
                  token={tokenAPI}
                  value={addressValue}
                  onChange={handleAddressChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  filterLocations={[
                    { kladr_id: "77" }, // Москва
                    { kladr_id: "50" }, // Московская область
                    { kladr_id: "78" }, // Санкт-Петербург
                  ]}
                />
              </div>
              {/* <input
                className={`calculator__data-input ${
                  !isRegionValid ? "invalid" : ""
                }`}
                {...register("region", { required: "Город" })}
                type="text"
                placeholder="Город"
                value={regionValue}
                onChange={handleRegionChange}
              /> */}
              {/* {!isRegionValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )} */}
              {/* <input
                className="calculator__data-input"
                {...register("address", { required: "Улица / Номер дома" })}
                type="text"
                placeholder="Улица/Номер дома"
                value={addressValue}
                onChange={handleAddressChange}
              />
              {!isAddressValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )} */}
              <input
                className="calculator__data-input"
                {...register("flatNumber", {
                  required: "Укажите номер квартиры",
                })}
                type="text"
                placeholder="Квартира"
                defaultValue
                value={flatNumberValue}
                onChange={handleFlatNumberChange}
              />
              {!isFlatNumberValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )}
              <input
                className="calculator__data-input"
                {...register("intercom")}
                type="text"
                placeholder="Наличие домофона"
                // defaultValue
              />
              <input
                className="calculator__data-input"
                {...register("user_name")}
                type="text"
                placeholder="Ваше имя"
                value={usernameValue}
                onChange={handleUsernameChange}
              />
              <input
                className="calculator__data-input"
                {...register("phoneNumber", {
                  required: "Укажите номер телефона",
                })}
                type="tel"
                placeholder="+7 000 999 00 00"
                defaultValue
                value={phoneNumberValue}
                onChange={handlePhoneNumberChange}
                // onBlur={handlePhoneNumberBlur}
              />
              {!isPhoneNumberValid && (
                <span className="error-message">
                  Поле обязательно для заполнения
                </span>
              )}
              <textarea
                {...register("comment")}
                className="calculator__data-textarea"
                placeholder="Ваши пожелания"
                cols="10"
                rows="10"></textarea>
              <ul className="calculator__data-list">
                {items.map((item, index) => (
                  <li key={index} {...register("additional_services")}>
                    <div>{item.name}</div>
                    <div>
                      {item.count && item.count > 1 ? `${item.count} x ` : null}
                      {item.price}₽
                    </div>
                  </li>
                ))}

                {discount ? (
                  <li>
                    <div>Скидка - {discount.discount * 100}%</div>
                    <div> -{totalPrice * discount.discount}₽</div>
                  </li>
                ) : null}
                {dates.map((item, index) => {
                  const date = new Date(item.year, item.month - 1, item.day);
                  const day = date.getDate();
                  const month = months[date.getMonth()];
                  const time = `${item.hours
                    .toString()
                    .padStart(2, "0")}:${item.minutes
                    .toString()
                    .padStart(2, "0")}`;
                  return (
                    <li key={index} {...register("additional_services")}>
                      <div>Дата:</div>
                      <div>{`${day} ${month} ${time}`}</div>
                    </li>
                  );
                })}
              </ul>
              <div className="calculator__data-total">
                {totalPrice ? (
                  <>
                    <div>{totalPriceWithDiscount * dates.length}₽ Сумма</div>
                    <div>{dates.length} шт.</div>
                  </>
                ) : (
                  <div>Выберите услугу</div>
                )}
              </div>
              {items.some(item => item.time) && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "12px",
                    opacity: "0.5",
                  }}>
                  <div>Примерное время уборки</div>
                  <div>{items.find(item => item.time).time}</div>
                </span>
              )}
              <div
                className="calculator__data-pay-delete"
                onClick={() => onClickClear()}>
                Удалить все услуги
              </div>
              <span
                onClick={isButtonEnabled ? handleToOrderPayment : null}
                className={`calculator__data-button ${
                  isButtonEnabled ? "" : "calculator__data-button-disabled"
                }`}>
                Перейти к оплате
              </span>
            </form>

            <div>
              Отменить услугу можно не позднее, чем за 1 час до начала оказания
              услуг
            </div>
            <br />
            <LinksCalculator />
          </div>
          <div
            className="calculator-data__mobile"
            onClick={handleScrollToTarget}>
            <div>
              {totalPriceWithDiscount * dates.length}₽
              <BsArrowRight className="calculator-data__mobile-arrow" />
            </div>
          </div>
          {showPopup && <ModalOrder />}
        </>
      )}
    </>
  );
};

export default CalculatorDataOrder;
