import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckPaymentStatus } from "../../redux/slices/paymentStatus";
import "./calculator-page/modal-order/ModalOrder.scss";

const Payment = () => {
  const dispatch = useDispatch();
  const { message } = useSelector(state => state.paymentStatus);

  useEffect(() => {
    const searchString = new URLSearchParams(window.location.search);
    const id = searchString.get('id');
    dispatch(fetchCheckPaymentStatus(id))
  }, [dispatch])

  return (
    <div className="main__header-info-name">
      {message === 'Ошибка запроса' ? (
        <div className="modal-order modal-order-payment">
          <div className="title">
            Повторите платеж, произошла ошибка
          </div>
          <div className="intro">
            <strong>
              {message}
            </strong>
          </div>
        </div>
      ) : (
        <div className="modal-order modal-order-payment">
          <div className="title">
            Спасибо, что доверяете нам <br />
            заботу о Вашем доме!
          </div>
          <div className="intro">Услуга отобразится в Вашем личном кабинете.</div>
          <br />
          <div className="intro">
            <strong>
              {message}
            </strong>
          </div>
        </div>
      )}
    </div>
  );

};

export default Payment;