import { useState } from "react";
import CalculatorCalendar from "../calculator-calendar/CalculatorCalendar";
import CalculatorDataOrder from "../calculator-data-order/CalculatorDataOrder";
import {
  furnitureServicesDataMoscow,
  furnitureServicesDataSaintPeterburg,
} from "../../../../data";
import { addProduct, minusItem } from "../../../../redux/slices/calculator";
import { useDispatch, useSelector } from "react-redux";
import "./CalculatorFurniture.scss";

const CalculatorFurniture = () => {
  const [toggleState, setToggleState] = useState(1);
  const dispatch = useDispatch();
  const items = useSelector(({ calculator }) => calculator.items);
  const { city } = useSelector(state => state.city);

  const City = () => {
    if (
      city &&
      city.selected &&
      city.cities[city.selected] === "Санкт-Петербург"
    ) {
      return furnitureServicesDataSaintPeterburg;
    } else {
      return furnitureServicesDataMoscow;
    }
  };

  const toggleTab = index => {
    setToggleState(index);
  };

  const onClickAdd = index => {
    const selectedCity = City();
    const selectedItem = selectedCity[index];

    const existingItem = items.find(item => item.id === selectedItem.id);
    if (existingItem && existingItem.count >= 8) {
      return;
    }

    const item = {
      id: selectedItem.id,
      name: selectedItem.name,
      price: selectedItem.price,
      count: existingItem ? existingItem.count + 1 : 1,
    };

    dispatch(addProduct(item));
  };

  const onClickRemove = index => {
    const selectedCity = City();
    const selectedItem = selectedCity[index];

    const existingItem = items.find(item => item.id === selectedItem.id);
    if (existingItem) {
      if (existingItem.count > 1) {
        dispatch(minusItem(selectedItem.id));
      } else {
        dispatch(minusItem(selectedItem.id, true)); // если хотите полностью удалить элемент, передайте второй аргумент
      }
    }
  };

  return (
    <section className="calculator__main">
      <div className="calculator-stock calculator__main">
        <div className="calculator__main-services">
          <ul className="additional-services__items">
            {City().map((item, index) => (
              <li key={item.id} className="additional-services__item">
                <div>
                  <div className="additional-services__item-text-main">
                    <div className="additional-services__item-text">
                      {item.name}
                      {item.imgInfo ? <span>{item.imgInfo}</span> : ""}
                    </div>
                    <div className="additional-services__item-text">
                      {item.price === 0 ? "" : `${item.price}₽`}
                      {item.additionText}
                    </div>
                  </div>
                  <img src={item.img} alt={item.name} width={82} />
                  {!item.inputs ? (
                    <div className="additional-services-add-buttons">
                      <button
                        onClick={() => onClickRemove(index)}
                        className="additional-services-add-button">
                        -
                      </button>
                      <div className="additional-services-add-button-count">
                        {items.map(cartItem => {
                          if (cartItem.id === item.id) {
                            return cartItem.count;
                          }
                          return null;
                        })}
                      </div>
                      <button
                        onClick={() => onClickAdd(index)}
                        className="additional-services-add-button">
                        +
                      </button>
                    </div>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>

          <div className="calculator-stock__content">
            <CalculatorCalendar />
          </div>
        </div>

        <div className="calculator__data-sticky">
          <CalculatorDataOrder />
        </div>
      </div>
    </section>
  );
};

export default CalculatorFurniture;
