import { Link } from "react-router-dom";
import Divan from "../../../../assets/divan2.jpg";
import "./PlaceOrder.scss";
const PlaceOrder = () => {
  return (
    <div className="place-order">
      <div className="landing__container">
        <div className="place-order__wrapper">
          <div className="benefits__title">Химчистка от 2500р</div>
          <Link
            to={"/calculator-furniture"}
            style={{
              textDecoration: "none",
            }}
            className="landing__form-button">
            Рассчитать стоимость
          </Link>
        </div>
      </div>
      <div className="place-order__bg-mob">
        <img src={Divan} alt="" />
      </div>
      <div className="place-order__bg"></div>
    </div>
  );
};

export default PlaceOrder;
