import Slider from "react-slick";
import "./BeforeAfter.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import before from "../../assets/beforeAfter/before.jpg";
import before2 from "../../assets/beforeAfter/before2.jpg";
import before3 from "../../assets/beforeAfter/before3.jpg";
import before4 from "../../assets/beforeAfter/before4.jpg";
import before5 from "../../assets/beforeAfter/before5.jpg";
import before6 from "../../assets/beforeAfter/before6.jpg";

const BeforeAfterSlider = () => {
  const settings = {
    arrows: false,
    dots: false,
    // autoplay: true,
    // autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const beforeArrey = [before6, before, before3, before4, before5];

  return (
    <div
      style={{
        padding: "0 28px",
      }}>
      <Slider {...settings}>
        {beforeArrey.map((item, index) => (
          <div key={index} className="slider__wrapper-item">
            <img src={item} alt="before" width={240} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BeforeAfterSlider;
