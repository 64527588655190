import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchInfoOrder = createAsyncThunk('order/fetchOrder', async () => {
  const { data } = await axios.get('/api/user/info/orders');
  return data;
});

export const updateOrder = createAsyncThunk('order/updateOrder', async (values,thunkAPI) => {
  const newData = {
    address: values.address,
    intercom: values.intercom,
    comment: values.comment,
  }
  const { data } = await axios.put(`/api/order/${values.id}`, newData);
  return data;
});

export const cancelOrder = createAsyncThunk('order/cancelOrder', async (orderId) => {
  const { data } = await axios.put(`/api/order/cancel/${orderId}`);
  return data;
});


const initialState = {
  order: {
    items: [],
    status: 'loading'
  }
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  extraReducers: {
    [fetchInfoOrder.pending]: (state) => {
      state.order.status = [];
      state.order.status = 'loading';
    },
    [fetchInfoOrder.fulfilled]: (state, actions) => {
      state.order.items = actions.payload;
      state.order.status = 'loaded';
    },
    [fetchInfoOrder.rejected]: (state) => {
      state.order.status = [];
      state.order.status = 'error';
    },
    [updateOrder.pending]: (state) => {
      state.order.status = 'loading';
    },
    [updateOrder.fulfilled]: (state, actions) => {
      state.order.status = 'loaded';
    },
    [updateOrder.rejected]: (state) => {
      state.order.status = 'error';
    },
    [cancelOrder.pending]: (state) => {
      state.order.status = 'loading';
    },
    [cancelOrder.fulfilled]: (state, actions) => {
      state.order.status = 'loaded';
    },
    [cancelOrder.rejected]: (state) => {
      state.order.status = 'error';
    }
  }
});

export const orderReducer = orderSlice.reducer;