import Reliability from "./assets/img-landing/reliability.svg";
import Tag from "./assets/img-landing/tag.svg";
import Insignia from "./assets/img-landing/insignia.svg";
import App from "./assets/img-landing/app.svg";
import Settings from "./assets/img-landing/settings.svg";
import Schedule from "./assets/img-landing/schedule.svg";
import TablewareImg from "./assets/img-services/tableware.svg";
import WindowImg from "./assets/img-services/window.svg";
import WashImg from "./assets/img-services/wash.svg";
import IroningImg from "./assets/img-services/ironing.svg";
import DisinfectionImg from "./assets/img-services/disinfection.svg";
import Chandelier from "./assets/chandelier-svgrepo-com.svg";
import Cleaner from "./assets/cleaner-svgrepo-com.svg";
import Curtains from "./assets/curtains-window-svgrepo-com.svg";
// import KeyImg from "./assets/img-services/key.svg";
// import DepartureImg from "./assets/img-services/departure.svg";
import Key from "./assets/img-services/key2.svg";
import LeavingTheRegionImg from "./assets/img-services/leavingTheRegion.svg";
import TrayImg from "./assets/img-services/tray.svg";
import UnderwearImg from "./assets/img-services/underwear.svg";
import BalconyImg from "./assets/img-services/balcony.svg";
import FridgeImg from "./assets/img-services/fridge.svg";
import OvenImg from "./assets/img-services/oven.svg";
import BakeImg from "./assets/img-services/bake.svg";
import PutAwayImg from "./assets/img-services/putAway.svg";
import vacuumCleaner from "./assets/img-services/vacuum-cleaner.svg";
import vacuum from "./assets/img-services/vacuum.svg";
import SliderPromoCode from "./assets/slider-promo.png";
import Wool from "./assets/img-services/wool.svg";

import bed from "./assets/furniture/bed-svgrepo-com.svg";
import sofa from "./assets/furniture/sofa-svgrepo.svg";
import carpet from "./assets/furniture/carpet-svgrep.svg";
import chair from "./assets/furniture/chair-furniture.svg";
import mattress from "./assets/furniture/mattress.svg";
import chairDecor from "./assets/furniture/decor.svg";
import comfortable from "./assets/furniture/comfortable-home-lazy.svg";

export const TOKEN = "6666974110:AAGID9itA-IsfnkWLvovmT_LbZazJfTARws";
export const CHAT_ID = "-1002090515268";
export const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
const test = 0;
export const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрб",
  "декабрь",
];

export const BenefistItems = [
  {
    title: "Надежность",
    text: "Компенсация за испорченные вещи",
    img: Reliability,
  },
  {
    title: "Личный кабинет",
    text: "Отслеживайте и настраивайте даты оказания услуг, их продолжительность и стоимость",
    img: App,
  },
  {
    title: "Качество",
    text: "Сделаем уборку качественно или вернем деньги",
    img: Insignia,
  },
  {
    title: "Опытные клинеры",
    text: "Строгий отбор и проффесиональное обучение",
    img: Tag,
  },
  {
    title: "Приложение",
    text: "Управляйте своей уборкой с комфортом",
    img: Settings,
  },
  {
    title: "Календарь",
    text: "Выполним уборку в назначенное время",
    img: Schedule,
  },
];

export const QuestionsText = [
  {
    title: "Почему нам можно доверять?",
    text: "«ТвояУборка» это сервис для людей которые ценят своё время. Мы работаем ради того, чтобы клиенты были довольны и не отвлекались на уборку, а доверили ее нам.        ",
  },
  {
    title: "Чем поддерживающая уборка отличается от генеральной?",
    text: "Мы советуем выбирать поддерживающую уборку, чтобы Ваш дом всегда находился в чистоте. Генеральная уборка идеально подойдёт тем, кто недавно переехал или если у Вас была бурная вечеринка.",
  },
  {
    title: "Клинер приедет со своими средствами или мне предоставить свои?",
    text: "У клинера с собой весь необходимый инвентарь для качественной поддерживающей уборки: губки и салфетки, моющие средства. Вы можете подготовить пылесос, чтобы сделать вашу уборку более тщательной. Если у вас нет пылесоса, клинер очистит поверхности там, где достанет, своими силами.",
  },
  {
    title: "Клинер сможет убраться в труднодоступных местах?",
    text: "Вы можете подготовить стремянку, чтобы клинер очистил доступные вертикальные и горизонтальные поверхности даже на высоте. Также клинер сможет помыть поверхности под кроватью, в стыках между мебелью и прочие в зоне доступа или полностью с вашей помощью. Жалюзи, стены и потолки не входят в поддерживающую уборку — только в генеральную.",
  },
  {
    title: "Я могу оставить клинера одного в квартире?",
    text: "Да, вы можете спокойно оставить клинера наводить порядок и отправиться по делам. Также Вы можете поручить нам особые задачи, например, Наш клинер приедет и заберёт ключи перед уборкой, а после привезёт обратно.",
  },
  {
    title: "Как считать количество санузлов?",
    text: "Одна ванная и один туалет, это один санузел. Не важно раздельные они или совмещённые. Второй санузел будет рассчитывается как дополненительный.",
  },
  {
    title: "Как оформить подписку на уборку и получать скидку?",
    text: "Начните оформлять разовую уборку: введите дату и время,параметры квартиры, привяжите карту, а в конце выберите подходящий вариант регулярности подписки на уборки. После этого в разделе «Мои заказы» вы увидите 4 ближайшие уборки со скидкой. Подписка продлевается автоматически, но каждая уборка оплачивается отдельно. При необходимости уборки можно переносить или отменять",
  },
  {
    title: "Что делать если после уборке обнаружились недочеты?",
    text: "Срочно! Свяжитесь с нами любым доступным методом. Можете так же сфотографировать недочёт и отправить его нашему менеджеру по мессенджеру. Мы сразу же ответим Вам по сложившийся проблеме и найдём способ решить её.",
  },
];

export const SliderItems = [
  { img: SliderPromoCode },
  { img: SliderPromoCode },
  { img: SliderPromoCode },
];

export const servicesDataTypeCleaning = [
  {
    id: 0,
    name: "Поддерживающая",
    price: 100,
    count: 0,
  },
  {
    id: 1,
    name: "Генеральная",
    price: 200,
    count: 0,
  },
  {
    id: 2,
    name: "После ремонта",
    price: 300,
    count: 0,
  },
];

export const servicesDataTypeHouse = [
  {
    id: 0,
    name: "Квартира",
    price: 100,
    count: 0,
  },
  {
    id: 1,
    name: "Нежилое помещение",
    price: 200,
    count: 0,
  },
  {
    id: 2,
    name: "Дом",
    price: 300,
    count: 0,
  },
];

export const servicesDataTypeRooms = [
  {
    id: 0,
    name: "Однокомнатная",
    title: "1",
    price: 100,
    count: 0,
  },
  {
    id: 1,
    name: "Двухкомнатная",
    title: "2",
    price: 200,
    count: 0,
  },
  {
    id: 2,
    name: "Трехкомнатная",
    title: "3",
    price: 300,
    count: 0,
  },
  {
    id: 3,
    name: "Четырехкомнатная",
    title: "4",
    price: 400,
    count: 0,
  },
  {
    id: 4,
    name: "Пятикомнатная",
    title: "5",
    price: 500,
    count: 0,
  },
];

export const servicesDataTypeBathrooms = [
  {
    id: 0,
    name: "Один санузел",
    title: "1",
    price: 1000,
    count: 0,
  },
  {
    id: 1,
    name: "Два санузла",
    title: "2",
    price: 2000,
    count: 0,
  },
];

export const mainPricesMoscow = {
  flats: [
    // квартиры
    {
      typeId: 0, //поддерживающая
      prices: [2620, 2990, 3800, 4500, 5900],
      priceBathrooms: 600,
      time: ["2 часа", "2 часа 30 мин", "3 часа 30 мин", "3 часа", "3 часа"],
    },
    {
      typeId: 1, //Генеральная
      prices: [5400, 6500, 7950, 9600, 12500],
      priceBathrooms: 900,
      time: ["3 часа", "2 часа 30 мин", "3 часа 30 мин", "3 часа", "3 часа"],
    },
    {
      typeId: 2, //После ремонта
      prices: [7900, 9500, 10900, 12900, 14900],
      priceBathrooms: 1200,
      time: [
        "3 часа",
        "4 часа 30 мин",
        "6 часов 30 мин",
        "6 часов",
        "6 часов 30 мин",
      ],
    },
  ],
  nonLiving: [
    // нежилые
    {
      typeId: 0, //поддерживающая
      pricesForSquare: 50,
    },
    {
      typeId: 1, //Генеральная
      pricesForSquare: 60,
    },
    {
      typeId: 2, //После ремонта
      pricesForSquare: 75,
    },
  ],
  houses: [
    // дом
    {
      typeId: 0, //поддерживающая
      pricesForSquare: 30,
    },
    {
      typeId: 1, //Генеральная
      pricesForSquare: 34,
    },
    {
      typeId: 2, //После ремонта
      pricesForSquare: 55,
    },
  ],
};

export const mainPricesSaintPeterburg = {
  flats: [
    // квартиры
    {
      typeId: 0, //поддерживающая
      prices: [2520, 2720, 3600, 4200, 5200], // Увеличены на 12%
      priceBathrooms: 600, // Увеличено на 12%
    },
    {
      typeId: 1, //Генеральная
      prices: [4500, 5500, 6600, 8600, 10500], // Увеличены на 7%
      priceBathrooms: 600, // Увеличено на 7%
    },
    {
      typeId: 2, //После ремонта
      prices: [6900, 7900, 8600, 9900, 12900], // Без изменений
      priceBathrooms: 1200, // Без изменений
    },
  ],
  nonLiving: [
    // нежилые
    {
      typeId: 0, //поддерживающая
      pricesForSquare: 50, // Увеличено на 12%
    },
    {
      typeId: 1, //Генеральная
      pricesForSquare: 60, // Увеличено на 7%
    },
    {
      typeId: 2, //После ремонта
      pricesForSquare: 75, // Без изменений
    },
  ],
  houses: [
    // дом
    {
      typeId: 0, //поддерживающая
      pricesForSquare: 30, // Увеличено на 12%
    },
    {
      typeId: 1, //Генеральная
      pricesForSquare: 34, // Увеличено на 7%
    },
    {
      typeId: 2, //После ремонта
      pricesForSquare: 55, // Без изменений
    },
  ],
};

export const servicesDataTypeFloors = [
  {
    id: 0,
    name: "Один этаж",
    title: "1",
    price: 100,
    count: 0,
  },
  {
    id: 1,
    name: "Два этажа",
    title: "2",
    price: 200,
    count: 0,
  },
  {
    id: 2,
    name: "Три этажа",
    title: "3",
    price: 300,
    count: 0,
  },
  {
    id: 3,
    name: "Четыре этажа",
    title: "4",
    price: 400,
    count: 0,
  },
  {
    id: 4,
    name: "Пять этажей",
    title: "5",
    price: 500,
    count: 0,
  },
];

export const servicesDataMoscow = [
  {
    id: 0,
    count: 0,
    name: "Помыть посуду",
    price: 250,
    additionText: "/ 30 мин",
    img: TablewareImg,
  },
  {
    id: 1,
    count: 0,
    name: "Мойка окон",
    price: 400,
    additionText: "",
    img: WindowImg,
    imgInfo: (
      <svg
        className="additional-services__item-img-info"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
          fill="black"
        />
        <path
          d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
          fill="black"
        />
        <path
          d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
          fill="black"
        />
      </svg>
    ),
    textInfo:
      "В стоимость входит мойка окон снаружи и внутри, а также мойка рамы и подоконника.",
  },
  {
    id: 2,
    count: 0,
    name: "Мойка балконного остекления",
    price: 810,
    additionText: "/ балкон",
    img: WindowImg,
  },
  {
    id: 3,
    count: 0,
    name: "Стирка вещей",
    price: 650,
    additionText: "/ ч",
    img: WashImg,
  },
  {
    id: 4,
    count: 0,
    name: "Глажка вещей",
    price: 480,
    additionText: "/ ч",
    img: IroningImg,
  },
  {
    id: 5,
    count: 0,
    name: "Дезинфекция",
    price: 360,
    additionText: "",
    img: DisinfectionImg,
  },
  {
    id: 6,
    count: 0,
    name: "Забрать ключи",
    price: 800,
    additionText: "",
    inputs: true,
    img: Key,
  },
  // {
  //   id: 7,
  //   count: 0,
  //   name: 'Выезд за МКАД',
  //   price: 500,
  //   additionText: '',
  //   img: DepartureImg,
  // },
  {
    id: 7,
    count: 0,
    name: "Выезд за МКАД Коректируется после оформления",
    price: 0,
    additionText: "",
    img: LeavingTheRegionImg,
  },
  {
    id: 8,
    count: 0,
    name: "Помыть лоток",
    price: 190,
    additionText: "",
    img: TrayImg,
  },
  {
    id: 9,
    count: 0,
    name: "Поменять белье",
    price: 200,
    additionText: "",
    img: UnderwearImg,
  },
  {
    id: 10,
    count: 0,
    name: "Убраться на балконе",
    price: 850,
    additionText: "",
    img: BalconyImg,
  },
  {
    id: 11,
    count: 0,
    name: "Помыть холодильник",
    price: 400,
    additionText: "",
    img: FridgeImg,
  },
  {
    id: 12,
    count: 0,
    name: "Помыть духовку",
    price: 550,
    additionText: "",
    img: OvenImg,
  },
  {
    id: 13,
    count: 0,
    name: "Помыть СВЧ-печь",
    price: 300,
    additionText: "",
    img: BakeImg,
  },
  {
    id: 14,
    count: 0,
    name: "Особые поручения",
    price: 400,
    additionText: "/ 30 мин",
    img: PutAwayImg,
  },
  {
    id: 15,
    count: 0,
    name: "Вывоз пылесоса",
    price: 2500,
    additionText: "",
    imgInfo: (
      <svg
        className="additional-services__item-img-info"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
          fill="black"
        />
        <path
          d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
          fill="black"
        />
        <path
          d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
          fill="black"
        />
      </svg>
    ),
    textInfo:
      "Пылесос входит в стоимость в генеральную уборку и после ремонта.",
    img: vacuumCleaner,
  },
  {
    id: 16,
    count: 0,
    name: "Парогенератор",
    price: 1100,
    additionText: "",
    imgInfo: (
      <svg
        className="additional-services__item-img-info"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
          fill="black"
        />
        <path
          d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
          fill="black"
        />
        <path
          d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
          fill="black"
        />
      </svg>
    ),
    textInfo: "Убивает 100% микробов",
    img: vacuum,
  },
  {
    id: 17,
    count: 0,
    name: "Люстра",
    price: 300,
    additionText: "",
    img: Chandelier,
  },
  {
    id: 18,
    count: 0,
    name: "Дополнительный Клинер",
    price: 3000,
    additionText: "",
    img: Cleaner,
  },
  {
    id: 19,
    count: 0,
    name: "Карнизы",
    price: 450,
    additionText: "",
    img: Curtains,
  },
  {
    id: 20,
    count: 0,
    name: "Уборка от шерсти - домашних животных",
    price: 1800,
    additionText: "",
    img: Wool,
  },
];

export const servicesDataSaintPeterburg = [
  {
    id: 0,
    count: 0,
    name: "Помыть посуду",
    price: 200,
    additionText: "/ 30 мин",
    img: TablewareImg,
  },
  {
    id: 1,
    count: 0,
    name: "Мойка окон",
    price: 350,
    additionText: "",
    img: WindowImg,
    imgInfo: (
      <svg
        className="additional-services__item-img-info"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="white"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.693 13.4074C9.12117 13.4074 8.6582 13.8839 8.6582 14.4558C8.6582 15.014 9.10754 15.5042 9.693 15.5042C10.2785 15.5042 10.7414 15.0141 10.7414 14.4558C10.7414 13.8839 10.2649 13.4074 9.693 13.4074Z"
          fill="black"
        />
        <path
          d="M9.86984 5.46891C8.03168 5.46891 7.1875 6.55817 7.1875 7.29344C7.1875 7.82449 7.6368 8.06957 8.00445 8.06957C8.73969 8.06957 8.44016 7.02113 9.82898 7.02113C10.5098 7.02113 11.0545 7.3207 11.0545 7.94703C11.0545 8.68234 10.292 9.10441 9.84262 9.48566C9.44777 9.82605 8.93035 10.3843 8.93035 11.5553C8.93035 12.2633 9.12094 12.4676 9.67922 12.4676C10.3464 12.4676 10.4825 12.168 10.4825 11.9093C10.4825 11.2013 10.4962 10.7928 11.2451 10.2073C11.6127 9.9214 12.7701 8.99551 12.7701 7.71559C12.7701 6.43567 11.6127 5.46891 9.86984 5.46891Z"
          fill="black"
        />
        <path
          d="M10 0C4.47328 0 0 4.47254 0 10V19.2188C0 19.6502 0.349766 20 0.78125 20H10C15.5267 20 20 15.5275 20 10C20 4.47328 15.5275 0 10 0ZM10 18.4375H1.5625V10C1.5625 5.33684 5.33621 1.5625 10 1.5625C14.6632 1.5625 18.4375 5.33621 18.4375 10C18.4375 14.6632 14.6638 18.4375 10 18.4375Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    id: 2,
    count: 0,
    name: "Мойка балконного остекления",
    price: 710,
    additionText: "/ балкон",
    img: WindowImg,
  },
  {
    id: 3,
    count: 0,
    name: "Стирка вещей",
    price: 450,
    additionText: "/ ч",
    img: WashImg,
  },
  {
    id: 4,
    count: 0,
    name: "Глажка вещей",
    price: 390,
    additionText: "/ ч",
    img: IroningImg,
  },
  {
    id: 5,
    count: 0,
    name: "Дезинфекция",
    price: 360,
    additionText: "",
    img: DisinfectionImg,
  },
  {
    id: 6,
    count: 0,
    name: "Забрать ключи",
    price: 650,
    additionText: "",
    inputs: true,
    img: Key,
  },
  // {
  //   id: 7,
  //   count: 0,
  //   name: 'Выезд за МКАД',
  //   price: 0,
  //   additionText: '',
  //   img: DepartureImg,
  // },
  {
    id: 7,
    count: 0,
    name: "Выезд за МКАД Коректируется после оформления",
    price: 0,
    additionText: "",
    img: LeavingTheRegionImg,
  },
  {
    id: 8,
    count: 0,
    name: "Помыть лоток",
    price: 160,
    additionText: "",
    img: TrayImg,
  },
  {
    id: 9,
    count: 0,
    name: "Поменять белье",
    price: 200,
    additionText: "",
    img: UnderwearImg,
  },
  {
    id: 10,
    count: 0,
    name: "Убраться на балконе",
    price: 750,
    additionText: "",
    img: BalconyImg,
  },
  {
    id: 11,
    count: 0,
    name: "Помыть холодильник",
    price: 400,
    additionText: "",
    img: FridgeImg,
  },
  {
    id: 12,
    count: 0,
    name: "Помыть духовку",
    price: 550,
    additionText: "",
    img: OvenImg,
  },
  {
    id: 13,
    count: 0,
    name: "Помыть СВЧ-печь",
    price: 250,
    additionText: "",
    img: BakeImg,
  },
  {
    id: 14,
    count: 0,
    name: "Особые поручения",
    price: 300,
    additionText: "/ 30 мин",
    img: PutAwayImg,
  },
];

export const pricesSupportingRoomPage = [
  {
    title: "1-комнатная",
    price: 2520,
  },
  {
    title: "2-комнатная",
    price: 2800,
  },
  {
    title: "3-комнатная",
    price: 3472,
  },
  {
    title: "4-комнатная",
    price: 4704,
  },
  {
    title: "5-комнатная",
    price: 5712,
  },
];

export const pricesGeneralRoomPage = [
  {
    title: "1-комнатная",
    price: 5253,
  },
  {
    title: "2-комнатная",
    price: 6313,
  },
  {
    title: "3-комнатная",
    price: 8356,
  },
  {
    title: "4-комнатная",
    price: 10185,
  },
  {
    title: "5-комнатная",
    price: 13375,
  },
];

export const pricesRepairsRoomPage = [
  {
    title: "1-комнатная",
    price: 7000,
  },
  {
    title: "2-комнатная",
    price: 8000,
  },
  {
    title: "3-комнатная",
    price: 9100,
  },
  {
    title: "4-комнатная",
    price: 10500,
  },
  {
    title: "5-комнатная",
    price: 14900,
  },
];

export const supportingRoomData = {
  Комната: [
    "Протираем пыль со всех доступных поверхностей",
    "Очищаем зеркала и стеклянные поверхности",
    "Аккуратно складываем вещи",
    "Протираем от пыли торшеры и настенные бра",
    "Пылесосим и моем пол, плинтусы",
    "Развешиваем верхнюю одежду",
    "Расставляем обувь",
  ],
  Кухня: [
    "Моем раковину",
    "Протираем пыль со всех доступных поверхностей",
    "Моем столешницу",
    "Протираем фасады шкафов",
    "Пылесосим и моем пол, плинтусы",
    "Собираем и выносим мусор, меняем мешки",
    "Моем и дезинфицируем мусорное ведро",
  ],
  Ванная: [
    "Моем ванну, душевую кабину и раковину",
    "Чистим унитаз, биде",
    "Очищаем зеркала и стеклянные поверхности",
    "Протираем пыль со всех доступных поверхностей",
    "Пылесосим и моем пол, плинтусы",
    "Собираем мусор, меняем мешки",
  ],
};

export const generalRoomData = {
  Комната: [
    "Протираем пыль со всех поверхностей и предметов",
    "Очищаем зеркала и стеклянные поверхности",
    "Протираем торшеры и настенные бра",
    "Моем розетки и выключатели",
    "Моем шкафы и тумбы внутри при условии, что они пустые",
    "Моем шкафы сверху",
    "Моем дверные ручки и межкомнатные двери",
    "Пылесосим мебель",
    "Моем радиаторы отопления",
    "Отодвигаем мебель при мытье пола",
    "Пылесосим и моем пол, чистим плинтусы",
    "Моем входную дверь",
  ],
  Кухня: [
    "Моем снаружи плиту, холодильник и вытяжку",
    "Моем вытяжку изнутри (фильтры, сетки)",
    "Удаляем сложные жировые загрязнения",
    "Полностью очищаем кухонный фартук",
    "Протираем пыль со всех поверхностей и предметов",
    "Протираем шкафы сверху",
    "Протираем фасады шкафов",
    "Моем все шкафы и ящики внутри при условии, что они пустые",
    "Чистим розетки и выключатели",
    "Отодвигаем мебель при мытье пола",
    "Пылесосим и моем пол, чистим плинтусы",
    "Моем и дезинфицируем мусорное ведро",
  ],
  Ванная: [
    "Моем ванну, душевую кабину и раковину",
    "Чистим краны и душевые лейки",
    "Моем и дезинфицируем унитаз, биде",
    "Чистим настенный кафель",
    "Моем дверные ручки и двери",
    "Моем розетки и выключатели",
    "Моем шкафы сверху",
    "Моем шкафы внутри при условии, что они пустые",
    "Протираем пыль со всех поверхностей и предметов",
    "Очищаем зеркала и стеклянные поверхности",
    "Пылесосим и моем пол, тщательно чистим плинтусы",
    "Собираем мусор, меняем мешки",
  ],
};

export const afterRepairRoomData = {
  Комната: [
    "Чистим от строительной пыли стены, пол и потолок (кроме натяжного и потолка армстронг)",
    "Оттираем следы и капли краски, штукатурки, затирки, клея (не более 2 см диаметром)",
    "Снимаем защитную пленку и чехлы с мебели",
    "Очищаем мебель от строительной пыли",
    "Протираем пыль со всех поверхностей и предметов",
    "Очищаем зеркала и стеклянные поверхности",
    "Протираем торшеры и настенные бра",
    "Моем шкафы сверху",
    "Моем шкафы внутри при условии, что они пустые",
    "Моем дверные ручки и двери",
    "Моем розетки и выключатели",
    "Моем межкомнатные двери",
    "Моем радиаторы отопления",
    "Моем входную дверь",
    "Отодвигаем мебель при мытье пола",
    "Пылесосим и моем пол, чистим плинтусы",
    "Собираем и выносим мусор, меняем мешки",
  ],
  Кухня: [
    "Моем снаружи плиту, холодильник и вытяжку",
    "Моем одну духовку изнутри",
    "Моем вытяжку изнутри (фильтры, сетки)",
    "Удаляем сложные жировые загрязнения",
    "Полностью очищаем кухонный фартук",
    "Протираем пыль со всех поверхностей и предметов",
    "Протираем шкафы сверху",
    "Протираем фасады шкафов",
    "Моем все шкафы и ящики внутри при условии, что они пустые",
    "Чистим розетки и выключатели",
    "Отодвигаем мебель при мытье пола",
    "Пылесосим и моем пол, чистим плинтусы",
    "Моем и дезинфицируем мусорное ведро",
  ],
  Ванная: [
    "Моем ванну, душевую кабину и раковину",
    "Чистим краны и душевые лейки",
    "Моем и дезинфицируем унитаз, биде",
    "Чистим настенный кафель",
    "Моем дверные ручки и двери",
    "Моем розетки и выключатели",
    "Моем шкафы сверху",
    "Моем шкафы внутри при условии, что они пустые",
    "Протираем пыль со всех поверхностей и предметов",
    "Очищаем зеркала и стеклянные поверхности",
    "Пылесосим и моем пол, тщательно чистим плинтусы",
    "Собираем мусор, меняем мешки",
  ],
};

export const furnitureServicesDataMoscow = [
  {
    id: 0,
    count: 0,
    name: "Диван",
    price: 6000,
    additionText: "",
    img: sofa,
  },
  {
    id: 1,
    count: 0,
    name: "Кресло",
    price: 4000,
    additionText: "",
    img: chairDecor,
  },
  {
    id: 2,
    count: 0,
    name: "Матрас",
    price: 5000,
    additionText: "",
    img: mattress,
  },
  {
    id: 3,
    count: 0,
    name: "Ковер",
    price: 2500,
    additionText: "",
    img: carpet,
  },
  {
    id: 4,
    count: 0,
    name: "Кровать",
    price: 1500,
    additionText: "",
    img: bed,
  },
  {
    id: 5,
    count: 0,
    name: "Стул",
    price: 2500,
    additionText: "",
    img: chair,
  },
  {
    id: 6,
    count: 0,
    name: "Другая мебель",
    price: 2500,
    additionText: "",
    img: comfortable,
    textInfo: "Уточнить цену по фото",
  },
];

export const furnitureServicesDataSaintPeterburg = [
  {
    id: 0,
    count: 0,
    name: "Диван",
    price: 6000,
    additionText: "",
    img: sofa,
  },
  {
    id: 1,
    count: 0,
    name: "Кресло",
    price: 4000,
    additionText: "",
    img: chairDecor,
  },
  {
    id: 2,
    count: 0,
    name: "Матрас",
    price: 5000,
    additionText: "",
    img: mattress,
  },
  {
    id: 3,
    count: 0,
    name: "Ковер",
    price: 2500,
    additionText: "",
    img: carpet,
  },
  {
    id: 4,
    count: 0,
    name: "Кровать",
    price: 1500,
    additionText: "",
    img: bed,
  },
  {
    id: 5,
    count: 0,
    name: "Стул",
    price: 2500,
    additionText: "",
    img: chair,
  },
  {
    id: 6,
    count: 0,
    name: "Другая мебель",
    price: 2500,
    additionText: "",
    img: comfortable,
    textInfo: "Уточнить цену по фото",
  },
];
