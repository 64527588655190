import Slider from "react-slick";
import "./Feedback.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeedbackSlider = () => {
  const settings = {
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider__content">
      <Slider {...settings} className="slider__wrapper">
        <div className="feedback__slider-item">
          <div className="feedback__slider-item-title">
            Замечательная девушка Сара убрала у меня. Пришла вовремя, очень
            приветливая и внимательна
          </div>
          <div className="feedback__slider-item-bottom">
            <div>
              <div className="title">Клиент</div>
              <div>Карина</div>
            </div>
            <div>
              <div className="title">Убиралась</div>
              <div>Сара</div>
            </div>
            <div>
              <div className="title">Оценка</div>
              <div>5</div>
            </div>
          </div>
        </div>
        <div className="feedback__slider-item">
          <div className="feedback__slider-item-title">
            Девушка старалась все сделать хорошо. Довольна! Спасибо за уборку
          </div>
          <div className="feedback__slider-item-bottom">
            <div>
              <div className="title">Клиент</div>
              <div>Елена</div>
            </div>
            <div>
              <div className="title">Убиралась</div>
              <div>Сара</div>
            </div>
            <div>
              <div className="title">Оценка</div>
              <div>5</div>
            </div>
          </div>
        </div>
        <div className="feedback__slider-item">
          <div className="feedback__slider-item-title">
            Супер быстро, качественно. Все вовремя. Приятная девушка! Рекомендую
          </div>
          <div className="feedback__slider-item-bottom">
            <div>
              <div className="title">Клиент</div>
              <div>Любовь</div>
            </div>
            <div>
              <div className="title">Убиралась</div>
              <div>Сара</div>
            </div>
            <div>
              <div className="title">Оценка</div>
              <div>5</div>
            </div>
          </div>
        </div>
        <div className="feedback__slider-item">
          <div className="feedback__slider-item-title">
            Очень довольна! Рекомундую! Девушка Сара работала на совесть и
            справилась быстро!
          </div>
          <div className="feedback__slider-item-bottom">
            <div>
              <div className="title">Клиент</div>
              <div>Дарья</div>
            </div>
            <div>
              <div className="title">Убиралась</div>
              <div>Сара</div>
            </div>
            <div>
              <div className="title">Оценка</div>
              <div>5</div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default FeedbackSlider;
