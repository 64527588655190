import Cash from "../../../assets/pay/cash.svg";
import Mir from "../../../assets/pay/mir.svg";
import Visa from "../../../assets/pay/visa.svg";
import MasterCard from "../../../assets/pay/Mastercard.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  changeOrderData,
  fetchOrderCalculator,
} from "../../../redux/slices/calculator";
import "../balance/Balance.scss";
import { useForm } from "react-hook-form";
import { memo } from "react";
import ModalOrder from "../calculator-page/modal-order/ModalOrder";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CHAT_ID, URI_API } from "../../../data";

const CalculatorOrder = memo(() => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [bonusAmount, setBonusAmount] = useState(0);
  const [payment, setPayment] = useState(0); // сделал по дефолту безналичный расчет, возможно в будующем Альфред захочет убрать возможность заказа для не авторизированного пользователя наличными
  const { totalPriceWithDiscount, dates, orderData } = useSelector(
    state => state.calculator
  );
  const [endPrice, setEndPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [orderNumber, setOrderNumber] = useState(0);
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    if (user && user.items && user.items._id) {
      if (
        user.items.balance &&
        totalPriceWithDiscount * dates.length > user.items.balance
      ) {
        setUserBalance(user.items.balance);
        setEndPrice(totalPriceWithDiscount * dates.length - user.items.balance);
        setOrderNumber(user.items.orderNumber);
      } else {
        setUserBalance(totalPriceWithDiscount * dates.length);
        setEndPrice(0);
        setOrderNumber(user.items.orderNumber);
      }
    } else {
      setEndPrice(totalPriceWithDiscount);
    }
  }, []);

  // const isAuth = useSelector(selectIsAuth);

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      payment_type: "",
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (user && user.items && user.items._id) {
      if (
        totalPriceWithDiscount * dates.length - userBalance - bonusAmount <
        0
      ) {
        const minusAmount = Math.abs(
          totalPriceWithDiscount * dates.length - userBalance - bonusAmount
        );
        setUserBalance(userBalance - minusAmount);
        setEndPrice(0);
      } else if (
        totalPriceWithDiscount * dates.length - userBalance - bonusAmount >=
          0 &&
        userBalance <= user.items.balance
      ) {
        const minusAmount = Math.abs(
          totalPriceWithDiscount * dates.length - userBalance - bonusAmount
        );
        const newUserBalance = Math.min(
          Math.max(userBalance + minusAmount, 0),
          user.items.balance
        );
        setUserBalance(newUserBalance);
        setEndPrice(
          totalPriceWithDiscount * dates.length - bonusAmount - newUserBalance
        );
      } else if (
        totalPriceWithDiscount * dates.length - userBalance - bonusAmount >=
          0 &&
        userBalance > user.items.balance
      ) {
        setUserBalance(user.items.balance);
        setEndPrice(
          totalPriceWithDiscount * dates.length -
            bonusAmount -
            user.items.balance
        );
      } else {
        setEndPrice(
          totalPriceWithDiscount * dates.length - userBalance - bonusAmount
        );
      }
    } else {
      setEndPrice(totalPriceWithDiscount);
    }
  }, [bonusAmount]);

  const onSubmit = async () => {
    let newData = Object.assign({}, orderData);
    switch (payment) {
      case 0: // безналичный расчет
        newData.payment_type = "Безналичный расчет";
        break;
      default: // наличные
        newData.payment_type = "Наличные";
        break;
    }
    newData.userBalance = userBalance;
    newData.bonusAmount = bonusAmount;
    newData.endPrice = endPrice;
    newData.orderNumber = orderNumber;

    let data;
    if (user && user.items && user.items._id) {
      data = await dispatch(fetchOrderCalculator(newData));
    } else {
      data = await dispatch(fetchOrderCalculator(newData));
    }

    // Создаем упрощенное сообщение для Telegram
    const message = `Поступил новый заказ`;
    // Отправляем сообщение в Telegram
    try {
      await axios.post(URI_API, {
        chat_id: CHAT_ID,
        text: message,
      });
      console.log("Message sent to Telegram");
    } catch (error) {
      console.error(
        "Error sending message to Telegram:",
        error.response ? error.response.data : error.message
      );
    }

    dispatch(changeOrderData({ orderData: null }));

    const paymentData = {
      paymentType: payment === 0 ? "Безналичный расчет" : "Наличные",
      userBalance,
      bonusAmount,
      endPrice,
      orderNumber,
    };

    setTimeout(() => {
      navigate("/thanks", { state: { paymentData } });
    }, 1000);

    return data;
  };

  // const onSubmit = async () => {
  //   let newData = Object.assign({}, orderData)
  //   switch (payment) {
  //     case 0: // безналичный расчет
  //       newData.payment_type = 'Безналичный расчет'
  //       break
  //     default: // наличные
  //       newData.payment_type = 'Наличные'
  //       break
  //   }
  //   // console.log('newData', newData)
  //   newData.userBalance = userBalance
  //   newData.bonusAmount = bonusAmount
  //   newData.endPrice = endPrice
  //   newData.orderNumber = orderNumber
  //   let data
  //   if (user && user.items && user.items._id) {
  //     data = await dispatch(fetchOrderCalculator(newData))
  //   } else {
  //     data = await dispatch(fetchOrderCalculator(newData))
  //   }
  //   // openModal()

  //   dispatch(changeOrderData({ orderData: null }))

  //   const paymentData = {
  //     paymentType: payment === 0 ? 'Безналичный расчет' : 'Наличные',
  //     userBalance,
  //     bonusAmount,
  //     endPrice,
  //     orderNumber,
  //   }

  //   setTimeout(() => {
  //     navigate('/thanks', { state: { paymentData } })
  //     // navigate('/thanks')
  //   }, 1000)

  //   return data
  // }

  const togglePayment = index => {
    setPayment(index);
  };

  const increaseBalance = () => {
    if (user.items.bonusBalance && bonusAmount < user.items.bonusBalance) {
      setBonusAmount(bonusAmount + 1);
    }
  };

  const decreaseBalance = () => {
    if (bonusAmount > 0) {
      setBonusAmount(bonusAmount - 1);
    }
  };

  const handleBonusBalance = el => {
    if (user.items.bonusBalance && el.target.value <= user.items.bonusBalance) {
      setBonusAmount(el.target.value);
    }
  };

  const shouldDisableCashPayment = totalPrice => {
    return totalPrice > 8500;
  };

  return (
    <section className="balance">
      <div className="balance__title">Выберите способ оплаты:</div>
      <div className="balance__amount-wrapper balance__amount-wrapper-box">
        <div className="balance__attention-text">
          Если, Ваш заказ оформляется более, чем на 8500 руб. <br />
          Оплата заказа доступна, только по 100% предоплате.
        </div>
      </div>
      <form>
        <label
          className={`balance__item ${payment === 0 ? "active" : ""}`}
          onClick={() => togglePayment(0)}>
          <div className="balance__item-img">
            <img src={Mir} alt="Онлайн" style={{ position: "absolute" }} />
          </div>
          <div className="balance__item-img">
            <img src={Visa} alt="Онлайн" style={{ position: "absolute" }} />
          </div>
          <div className="balance__item-img">
            <img
              src={MasterCard}
              alt="Онлайн"
              style={{ position: "absolute" }}
            />
          </div>
        </label>
        {!shouldDisableCashPayment(totalPriceWithDiscount) && (
          <label
            className={`balance__item ${payment === 1 ? "active" : ""}`}
            onClick={() => togglePayment(1)}>
            <div>
              <div>наличными или картой после оказании услуги</div>
              <div className="balance__item-img" style={{ margin: "0 auto" }}>
                <img
                  src={Cash}
                  alt="Наличные"
                  style={{ position: "absolute" }}
                />
              </div>
            </div>
          </label>
        )}
        {user && user.items && user.items._id ? (
          <label className={`balance__item active `}>
            {/* <input type="radio" {...register("payment_type")} value="Бонусный счет" style={{ opacity: '0' }} /> */}
            <div className="balance__item-container">
              <div>
                Бонусный счет:{" "}
                {user.items.bonusBalance ? user.items.bonusBalance : 0}
              </div>
              <div className="balance__item-img balance__item-img-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}>
                  <input
                    onChange={handleBonusBalance}
                    value={bonusAmount}
                    min={0}
                    max={user.items.bonusBalance}
                    className="balance__bonus-input"
                    type="number"
                  />
                  <div
                    className="balance__button-bonus"
                    onClick={() => decreaseBalance()}>
                    -
                  </div>
                  <div
                    className="balance__button-bonus"
                    onClick={() => increaseBalance()}>
                    +
                  </div>
                </div>
              </div>
            </div>
          </label>
        ) : null}
        <div className="balance__amount-wrapper">
          <div className="balance__amount">
            Стоимость заказа: {totalPriceWithDiscount * dates.length}₽
          </div>
          <div className="balance__amount">Итого к оплате: {endPrice}₽</div>
        </div>
      </form>
      <div className="balance__button" onClick={onSubmit}>
        Далее
      </div>
      {/* {isModalOpen ? <div>!{endPrice}</div> : null} */}
    </section>
  );
});

export default CalculatorOrder;
