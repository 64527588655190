import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoOrder } from "../../../redux/slices/order";
// import { selectIsAuth } from '../../../redux/slices/auth';
import "./CompletedServices.scss";
import { memo } from "react";
import { months } from "../../../data";
const CompletedServices = memo(() => {
  const dispatch = useDispatch();
  // const isAuth = useSelector(selectIsAuth);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(fetchInfoOrder());
  }, [dispatch]);

  // Компонент для отображения одной звезды
  const Star = ({ filled, onClick }) => {
    const starStyle = {
      cursor: "pointer",
      color: filled ? "gold" : "lightgray",
    };

    return (
      <span style={starStyle} onClick={onClick}>
        ★
      </span>
    );
  };

  const StarRating = () => {
    const [stars, setStars] = useState(Array(5).fill(false));

    // Функция обработки клика по звезде
    const handleStarClick = index => {
      const newStars = stars.map((star, i) => i <= index);
      setStars(newStars);
    };

    return (
      <>
        {stars.map((filled, index) => (
          <Star
            key={index}
            filled={filled}
            onClick={() => handleStarClick(index)}
          />
        ))}
      </>
    );
  };

  return (
    <section>
      <ul className="completed-services__items">
        {order.items.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              color: "#b2b2b2",
              padding: "32px 0",
            }}>
            У вас нет заказов
          </div>
        ) : (
          <>
            {order.items
              .slice()
              .reverse()
              .map((item, index) => {
                const date = new Date(item.date);
                const day = date.getDate();
                const month = months[date.getMonth()];
                const hours = date.getHours();
                const minutes = date.getMinutes().toString().padStart(2, "0");
                if (item.state === true) {
                  return (
                    <li
                      className="completed-services__item completed-services__item-block"
                      key={index}
                      style={{
                        padding: "18px 14px",
                      }}>
                      <div className="block-main">
                        <div className="text">
                          <div>{item.group}</div>
                        </div>
                        <div className="text">
                          <div>Заказ № {item.orderNumber}</div>
                        </div>
                        <div className="text block">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "140px",
                            }}>
                            <StarRating />
                          </div>
                          <div className="completed-services__button">
                            Оставить чаевые
                          </div>
                        </div>
                        <div className="">{`${day} ${month} ${hours}:${minutes}`}</div>
                      </div>

                      <div className="block-main top block-main__right">
                        <div className="completed-services__button">
                          Чек об оплате
                        </div>
                        <div className="price">{item.price} ₽</div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
          </>
        )}
      </ul>
    </section>
  );
});

export default CompletedServices;
