import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Получение информации о своём аккаунте
export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
  const { data } = await axios.get('/api/user/info/settings');
  return data;
});

// Изменение информации о своём аккаунте
export const fetchUserInfoChange = createAsyncThunk('user/fetchUserInfoChange', async (params) => {
  const { data } = await axios.put('/api/user/info/settings', params);
  return data;
});

// Получение списка заказов, оформленных пользователем
export const fetchUserInfoOrders = createAsyncThunk('user/fetchUserInfoOrders', async () => {
  const { data } = await axios.get('/api/user/info/orders');
  return data;
});

// Запрос на пополнение баланса
export const fetchUserUpdateBalance = createAsyncThunk('user/fetchUserUpdateBalance', async (amount) => {
  const { data } = await axios.post('/api/payment/upbalance',{amount});
  window.location.assign(data.url);
});



const initialState = {
  user: {
    items: [],
    status: 'loading'
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {

    // Получение информации о своём аккаунте
    [fetchUserInfo.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfo.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfo.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Изменение информации о своём аккаунте
    [fetchUserInfoChange.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfoChange.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfoChange.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },

    // Получение списка заказов, оформленных пользователем
    [fetchUserInfoOrders.pending]: (state) => {
      state.user.status = [];
      state.user.status = 'loading';
    },
    [fetchUserInfoOrders.fulfilled]: (state, actions) => {
      state.user.items = actions.payload;
      state.user.status = 'loaded';
    },
    [fetchUserInfoOrders.rejected]: (state) => {
      state.user.status = [];
      state.user.status = 'error';
    },
    [fetchUserUpdateBalance.pending]: (state) => {
      state.user.status = 'loading';
    },
    [fetchUserUpdateBalance.fulfilled]: (state, actions) => {
      state.user.status = 'loaded';
    },
    [fetchUserUpdateBalance.rejected]: (state) => {
      state.user.status = 'error';
    }
  }
});

export const userReducer = userSlice.reducer;