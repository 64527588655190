import Visa from "../../../assets/pay/visa.svg";
import MasterCard from "../../../assets/pay/Mastercard.svg";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuth } from "../../../redux/slices/auth";
import "./Balance.scss";
import { useState } from "react";
import { fetchUserUpdateBalance } from "../../../redux/slices/user";

const Balance = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('0');

  const isAuth = useSelector(selectIsAuth);
  const handleChangeInput = (event) => {
    // console.log(event.target.value)
    setAmount(event.target.value);
  }

  const handleSubmitPay = () => {
    dispatch(fetchUserUpdateBalance(amount))
  }

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/auth" />;
  }
  return (
    <section className="balance">
      <div className="balance__title">Выберите способ пополнения баланса:</div>
      <div className="balance__item">
        <div className="balance__item-img"><img src={Visa} alt="visa" /></div>
        <div className="balance__item-img"><img src={MasterCard} alt="masterCard" /></div>
      </div>
      <input className="balance__input" type="number" value={amount} onChange={handleChangeInput} placeholder="Сумма" />
      <a className="balance__button" onClick={handleSubmitPay}>Далее!</a>
    </section>
  )
}

export default Balance;