import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUserInfo, fetchUserInfoChange } from "../../../../redux/slices/user";
import { useForm } from "react-hook-form";

const AddNumber = () => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    // dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      "phone_number": ""
    }
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUserInfoChange(values));
    setOpen(!open);
    document.location.reload();
    reset();
    return data;
  }

  return (
    <div className="setting__data-item">
      <div>Ваш номер</div>
      {user.items && user.items.phone_number ? (
        <div>{user.items.phone_number}</div>
      ) : (
        <div>Телефон не установлен</div>
      )}
      <div onClick={handleOpen} className="setting__data-button">Изменить</div>
      {open &&
        <form onSubmit={handleSubmit(onSubmit)} >
          <input
            className="setting__data-change-modal-input"
            {...register('phone_number', {
              required: true
            })}
            placeholder="Ваш номер"
            type="phone_number"
          />
          <button type="submit" className="setting__address-modal-button">Сохранить</button>
        </form>
      }
    </div>
  )
}

export default AddNumber;