import BeforeAfterSlider from "./BeforeAfterSlider";
import "./BeforeAfter.scss";

const BeforeAfter = () => {
  return (
    <div
      className="beforeAfter__wrapper"
      style={{
        width: "50%",
      }}>
      <div
        className="benefits__title"
        style={{
          textAlign: "center",
        }}>
        До и после
      </div>
      <div>
        <BeforeAfterSlider />
      </div>
    </div>
  );
};

export default BeforeAfter;
