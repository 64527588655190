import { useEffect, useState } from "react";
import CalculatorAddServices from "./calculator-add-services/CalculatorAddServices";
import CalculatorCleaning from "./calculator-cleaning/CalculatorCleaning";
import CalculatorDataOrder from "./calculator-data-order/CalculatorDataOrder";
// import CalculatorOrder from '../calculator-order/CalculatorOrder'
import CalculatorSale from "../../../assets/sale8.jpg";
import "./CalculatorPage.scss";
import { Helmet } from "react-helmet-async";

const CalculatorPage = () => {
  const [valuesPaymentPage, setValuesPaymentPage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Калькулятор стоимости нашей уборки в Москве</title>
        <meta
          name="description"
          content="Рассчитайте стоимость уборки вашей квартиры с помощью нашего калькулятора. Наш калькулятор поможет вам быстро и удобно рассчитать стоимость уборки."
        />
      </Helmet>
      <section className="calculator__main">
        <div className="calculator__main-services">
          <CalculatorCleaning />
          <CalculatorAddServices />
        </div>
        <div className="calculator__data-sticky">
          <span>
            <CalculatorDataOrder setValuesPaymentPage={setValuesPaymentPage} />
            {/* <span className="calculator__main-promo">
              <img
                src={CalculatorSale}
                alt="Промо-код"
                className="calculator__main-pormo-img"
              />
            </span> */}
          </span>
        </div>
      </section>
    </>
  );
};

export default CalculatorPage;
