import "./Uslugi.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
export const Uslugi = () => {
  return (
    <>
      <Helmet>
        <title>Услуги профессионального клининга от Твоя Уборка в Москве</title>
        <meta
          name="description"
          content="Узнайте подробнее о наших услугах: уборка квартир, домов, офисов, мойка окон и многое другое. Профессиональный подход и качество обслуживания!"
        />
      </Helmet>
      <div className="uslugi-container">
        <h1 className="uslugi-title">Наши услуги</h1>
        <p className="uslugi-description">
          Мы предлагаем широкий спектр клининговых услуг по Москве, включая
          уборку квартир, офисов и химчистку мебели.
        </p>
        <div className="uslugi-grid">
          <Link to="/" className="usluga-card">
            <h2 className="usluga-title">Уборка квартир</h2>
            <p className="usluga-description">
              Профессиональная уборка квартир любой сложности. Мы заботимся о
              вашем комфорте и чистоте вашего дома.
            </p>
          </Link>
          <Link to="/uslugi/uborka-pomesheniy/office/" className="usluga-card">
            <h2 className="usluga-title">Уборка офисов</h2>
            <p className="usluga-description">
              Комплексная уборка офисных помещений, чтобы ваш бизнес процветал в
              чистоте и порядке.
            </p>
          </Link>
          <Link to="/cleaning" className="usluga-card">
            <h2 className="usluga-title">Химчистка мебели</h2>
            <p className="usluga-description">
              Профессиональная химчистка диванов, кроватей и другой мягкой
              мебели. Мы обеспечим свежесть и чистоту.
            </p>
          </Link>
        </div>
      </div>
      <section className="uslugi-container">
        <h1>
          Услуги профессионального клининга в Москве от компании "Твоя уборка"
        </h1>
        <p>
          Жизнь в большом городе требует от нас скорости, гибкости и постоянной
          активности. В условиях мегаполиса, такого как Москва, каждая минута на
          счету. Порой времени едва хватает на важные дела, а домашние
          обязанности остаются на втором плане. Именно в такие моменты на помощь
          приходят профессиональные клининговые услуги.
        </p>
        <h2>Преимущества профессиональной уборки</h2>
        <p>
          Наша клининговая компания "Твоя уборка" предлагает широкий спектр
          услуг, начиная от базовой уборки квартиры и заканчивая
          специализированными услугами, такими как химчистка. Мы понимаем, что
          каждая квартира уникальна, поэтому подходим к каждой задаче
          индивидуально. Наша цель – обеспечить идеальную чистоту в вашем доме,
          чтобы вы могли наслаждаться свободным временем, не думая о бытовых
          хлопотах. Когда речь идет об услугах уборки квартиры, важно понимать,
          что профессиональная клининговая услуга – это не просто стандартная
          уборка. Это высококачественный сервис, включающий использование
          специализированных средств и оборудования, а также команду опытных
          специалистов. Мы не только тщательно убираем каждую комнату, но и
          обеспечиваем долгосрочный эффект чистоты, благодаря правильному
          подходу к каждому материалу и поверхности.
        </p>
        <h3>Виды услуг</h3>
        <p>
          Клининговые услуги в Москве становятся все более востребованными, и не
          зря. Мы предлагаем разнообразные услуги уборки квартиры, среди
          которых:
        </p>
        <ul>
          <li>
            Клининговая услуга уборка квартиры: мы приходим в удобное для вас
            время и оперативно наводим порядок, убирая пыль, подметаем и моем
            полы, проводим дезинфекцию поверхностей.
          </li>
          <li>
            Уборка офиса: чистота рабочего пространства – залог продуктивности и
            здоровья сотрудников. обеспечим профессиональную уборку вашего
            офиса, включая чистку рабочих мест, санузлов и зон общего
            пользования.
          </li>
          <li>
            Хранение на складе: вы можете хранить вещи в безопасных и чистых
            условия.
          </li>
          <li>
            Химчистка: мягкой мебели, ковров, штор и других текстильных изделий.
            Мы используем безопасные и эффективные средства для удаления пятен и
            освежения тканей.
          </li>
        </ul>
        <h3>Цены на услуги уборки квартиры в Москве</h3>
        <p>
          Когда речь заходит о клининговых услугах в Москве, одним из важных
          вопросов всегда остается стоимость. Мы предлагаем гибкую систему
          ценообразования, благодаря которой каждый клиент сможет подобрать
          оптимальный для себя вариант. Стоимость наших услуг зависит от объема
          работы, сложности задач.
        </p>
        <h4>Как заказать клининговые услуги</h4>
        <p>
          Заказать наши услуги клининга в Москве очень просто. Мы ценим ваше
          время, поэтому весь процесс максимально упрощен: достаточно оставить
          заявку на сайте или позвонить нам. После этого наш менеджер свяжется с
          вами, чтобы уточнить все детали и подобрать наиболее удобное время для
          уборки. Мы работаем как с частными клиентами, так и с корпоративными
          заказчиками, обеспечивая высокий уровень сервиса для всех.
        </p>
      </section>
    </>
  );
};
