import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfoChange } from '../../../redux/slices/user'
import { useEffect } from 'react'
import './CompletionRegistration.scss'

const CompletionRegistration = ({
  openModal,
  handleOpenModal,
  handleCloseModal,
}) => {
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserInfoChange())
  }, [dispatch])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      address: ['', '', ''],
    },
  })
  useEffect(() => {
    if (user && user.items && user.items.name) {
      setValue('name', user.items.name)
    }
  }, [user])

  const handleDone = () => {
    openModal(false)
  }

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUserInfoChange(values))
    console.log(data)
    handleCloseModal()
    return data
  }

  return (
    <>
      <div className="completion-registration__wrapper"></div>
      <div className="completion-registration">
        <div className="completion-registration__title">
          Завершите регистрацию
        </div>
        <form
          className="completion-registration__form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label>
            <div className="completion-registration__form-text">
              Как к Вам обращаться?
            </div>
            <input
              type="text"
              placeholder={`Имя${
                errors.name ? ` (${errors.name.message})` : ''
              }`}
              {...register('name', { required: 'Введите имя!' })}
            />
          </label>
          <label>
            <div className="completion-registration__form-text">
              Адрес оказания услуги (по умолчанию)
            </div>
            <div className="completion-registration__form-inputs">
              <input
                type="text"
                placeholder={`Область${
                  errors.address && errors.address[0]
                    ? ` (${errors.address[0].message})`
                    : ''
                }`}
                {...register('address[0]', { required: 'Укажите город' })}
              />
              <input
                type="text"
                placeholder={`Город/район, улица, дом, квартира${
                  errors.address && errors.address[1]
                    ? ` (${errors.address[1].message})`
                    : ''
                }`}
                {...register('address[1]', {
                  required: 'Укажите район, улица, дом, квартира',
                })}
              />
              <input
                type="text"
                placeholder={`Наличие домофона${
                  errors.address && errors.address[2]
                    ? ` (${errors.address[2].message})`
                    : ''
                }`}
                {...register('address[2]', { required: 'Укажите домофон' })}
              />
              {/* {errors.address && <div className="completion-registration__form-error">Укажите адрес</div>} */}
            </div>
          </label>
          <button
            type="submit"
            onClick={handleDone}
            className="completion-registration__form-button"
          >
            Сохранить
          </button>
          <a
            href="/"
            // type="button"
            onClick={handleCloseModal}
            className="completion-registration__form-button"
          >
            закрыть
          </a>
        </form>
      </div>
    </>
  )
}

export default CompletionRegistration
