import { Link } from 'react-router-dom';
const LinksCalculator = () => {
  return (
    <div className="calculator__data-text">
      Нажимая кнопку, Вы принимаете
      <Link className="calculator__data-agreement" to="/agreement">Условия пользовательского соглашения</Link>
      и даете согласие на
      <Link className="calculator__data-agreement" to="/policy">Обработку персональных данных</Link>
    </div>
  )
}

export default LinksCalculator;