import "./AboutUs.scss";
import { Helmet } from "react-helmet-async";
export const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Твоя Уборка – узнай о нашем клининговом агентстве</title>
        <meta
          name="description"
          content="Узнайте больше о нашей команде и услугах, которые мы предоставляем. Мы – команда экспертов в области уборки, и мы готовы предложить вам только самое лучшее."
        />
      </Helmet>
      <div className="about-container">
        <h1 className="about-title">О нас</h1>
        <section className="about-section">
          <h2 className="about-subtitle">Наша миссия</h2>
          <p className="about-text">
            Мы стремимся обеспечить чистоту и комфорт в домах и офисах наших
            клиентов, предоставляя качественные и надежные клининговые услуги по
            Москве.
          </p>
        </section>
        <section className="about-section">
          <h2 className="about-subtitle">Наша команда</h2>
          <p className="about-text">
            Наша команда состоит из профессионалов с большим опытом работы в
            сфере клининга. <br />
            Каждый член нашей команды проходит тщательное обучение и готов к
            любым задачам.
          </p>
        </section>
        <section className="about-section">
          <h2 className="about-subtitle">Контакты</h2>
          <p className="about-text">
            Вы можете связаться с нами по телефону +7 915 264-00-11. <br />
            Мы всегда рады ответить на ваши вопросы!
          </p>
        </section>
      </div>
    </>
  );
};
