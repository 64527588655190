import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./Agreement.scss";

const Agreement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Договор на услуги уборки в Москве – Твоя Уборка</title>
        <meta
          name="description"
          content="Все нюансы услуги уборки обговорены в договоре. Мы гарантируем прозрачные условия, которые будут четко прописаны в договоре. Вам не придется беспокоиться!"
        />
      </Helmet>
      <section className="landing__container">
        <div className="landing__additional-title">
          ПУБЛИЧНАЯ ОФЕРТА «ТВОЯУБОРКА»
        </div>
        <div className="landing__additional-text-ct">
          Интернет-магазин <strong>«ТВОЯУБОРКА»</strong>, расположенный на
          доменном имени
          <a href="https://tvoyauborka.ru"> https://tvoyauborka.ru </a>
          организационно-правовая форма - «Индивидуальный предприниматель
          «Юсупов.А.Ш.» именуемая под коммерческим обозначением клининговая
          компания <strong>«ТВОЯУБОРКА»</strong> (далее —
          Исполнитель(И.П.«Юсупов.А.Ш.») в лице Руководителя Юсупова Альфреда
          Шамилевича, действующего на основании - Свидетельства о
          государственной регистрации физического лица в качестве
          индивидуального предпринимателя (ОГРНИП 318774600645097 ИНН
          772092052229) от «27»ноября 2018года.) содержит все существенные
          условия по оказанию клининговых услуг юридическим или физическим
          лицам, оформившим заказ на сайте tvoyauborka.ru или по телефону +7 915
          264-00-11 именуемое в дальнейшем «ИП ЮСУПОВ», публикует Публичную
          оферту о продаже Услуг дистанционным способом.
        </div>
        <div className="landing__additional-title">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</div>
        <ul className="landing__additional-list">
          <li>
            1.1. Публичная оферта (далее – «Оферта») - публичное предложение
            Продавца, адресованное неопределенному кругу лиц, заключить с
            Продавцом договор купли-продажи Услуг дистанционным способом (далее
            - «Договор») на условиях, содержащихся в настоящей Оферте, включая
            все Приложения.
          </li>
          <li>
            1.2. Заказ Услуг на сайте Интернет-магазина{" "}
            <strong>«ТВОЯУБОРКА»</strong>, – позиции указанные Покупателем из
            ассортимента Услуг, предложенного к продаже, при оформлении заявки
            на приобретение Услуг на сайте Интернет-магазина или через
            Оператора.
          </li>
          <li>
            1.3. Если посетитель оформляет заказ, оплачивает его, то согласно
            п.2 ст. 437 Гражданского Кодекса РФ, такие действия рассматриваются
            как акцепт оферты. Пунктом 3 ст. 438 ГК РФ акцепт публичной оферты
            признан равносильным письменному договору.
          </li>
          <li>
            1.4. Возникшие между сторонами правоотношения регулируются нормами
            ГК РФ, применяемыми, другими действующими законами и нормативными
            актами.
          </li>
          <li>
            1.5. Оформляя заказ, физическое лицо подтверждает этим действием
            свою право и дееспособность.
          </li>
        </ul>
        <div className="landing__additional-title">2. ОБЩИЕ ПОЛОЖЕНИЯ</div>
        <ul className="landing__additional-list">
          <li>
            2.1. Заказ Покупателем Услуг, размещенного на сайте
            Интернет-магазина <strong>«ТВОЯУБОРКА»</strong> означает, что
            Покупатель согласен со всеми условиями настоящей Оферты.
          </li>
          <li>
            2.2. Администрация сайта Интернет-магазина{" "}
            <strong>«ТВОЯУБОРКА»</strong> имеет право вносить изменения в Оферту
            без уведомления Покупателя.
          </li>
          <li>
            2.3. Срок действия Оферты не ограничен, если иное не указано на
            сайте Интернет-магазина.
          </li>
          <li>
            2.4. Продавец предоставляет Покупателю полную и достоверную
            информацию о Услуге, включая информацию об основных потребительских
            свойствах Услуги.
          </li>
        </ul>
        <div className="landing__additional-title">3. ЦЕНА УСЛУГ</div>
        <ul className="landing__additional-list">
          <li>
            3.1. Цена на каждую позицию Услуги указана на сайте
            Интернет-магазина <strong>«ТВОЯУБОРКА»</strong>.
          </li>
          <li>
            3.2. Продавец имеет право в одностороннем порядке изменить цену на
            любую позицию Услуги.
          </li>
          <li>
            3.3. В случае изменения цены на заказанные Услуги Продавец обязуется
            в течение количество дней проинформировать Покупателя об изменении
            цены Услуги.
          </li>
          <li>
            3.4. Покупатель вправе подтвердить либо аннулировать Заказ на
            приобретение Услуги, если цена изменена Продавцом после оформления
            Услуги.
          </li>
          <li>
            3.5. Изменение Продавцом цены на оплаченный Покупателем Услуги не
            допускается.
          </li>
          <li>
            3.6. Обязательства Покупателя по оплате Услуги считаются
            исполненными с момента поступления Продавцом денежных средств.
          </li>
          <li>
            3.7. Расчеты между Продавцом и Покупателем за Услуги производятся 3
            способами, указанными на сайте Интернет-магазина
            <strong>«ТВОЯУБОРКА»</strong> а именно оплата наличными, по карте,
            интернет эквайринг.
          </li>
        </ul>
        <div className="landing__additional-title">4. ОФОРМЛЕНИЕ ЗАКАЗА</div>
        <ul className="landing__additional-list">
          <li>
            4.1. Заказ Услуги осуществляется Покупателем через Оператора по
            номер телефону +7 915 264-00-11 или через сервис сайта{" "}
            <strong>«ТВОЯУБОРКА»</strong> https://tvoyauborka.ru.
          </li>
          <li>
            4.2. При регистрации на сайте Интернет-магазина Покупатель обязуется
            предоставить следующую регистрационную информацию:
          </li>
          <li>
            4.2.1. фамилия, имя, отчество Покупателя или указанного им лица
            (получателя);
          </li>
          <li>4.2.3. адрес электронной почты;</li>
          <li>4.2.4. контактный телефон.</li>
          <li>
            4.3. Наименование, количество, цена выбранного Покупателем Услуги
            указываются в корзине Покупателя на сайте{" "}
            <strong>«ТВОЯУБОРКА»</strong>.
          </li>
          <li>
            4.4. Продавец оказывает Покупателю услуги по указанному адресу пункт
            4.2.2.
          </li>
          <li>
            4.5. Если Продавцу необходима дополнительная информация, он вправе
            запросить ее у Покупателя. В случае не предоставления необходимой
            информации Покупателем, Продавец не несет ответственности за
            выбранный Покупателем Услуги.
          </li>
          <li>
            4.6. При оформлении Заказа через Оператора (п. 4.1. настоящей
            Оферты) Покупатель обязуется предоставить информацию, указанную в п.
            4.2. настоящей Оферты.
          </li>
          <li>
            4.7. Принятие Покупателем условий настоящей Оферты осуществляется
            посредством внесения Покупателем соответствующих данных в
            регистрационную форму на сайте <strong>«ТВОЯУБОРКА»</strong> или при
            оформлении Заказа через Оператора. После оформления Заказа через
            Оператора данные о Покупателе регистрируются в базе данных Продавца.
            Утвердив Заказ выбранного Товара, Покупатель предоставляет Оператору
            необходимую информацию в соответствии с порядком, указанном в п.
            4.2. настоящей Оферты.
          </li>
          <li>
            4.8. Продавец не несет ответственности за содержание и достоверность
            информации, предоставленной Покупателем при оформлении Услуги.
          </li>
          <li>
            4.9. Покупатель несет ответственность за достоверность
            предоставленной информации при оформлении Заказа.
          </li>
          <li>
            4.9.1. Договор купли-продажи дистанционным способом между Продавцом
            и Покупателем считается заключенным с момента выдачи Продавцом
            Покупателю кассового или товарного чека либо иного документа,
            подтверждающего оплату Услуги
          </li>
        </ul>
        <div className="landing__additional-title">
          5. Права и обязанности Заказчика
        </div>
        <div>Заказчик обязуется:</div>
        <ul className="landing__additional-list">
          <li>
            5.1. Обеспечить допуск Исполнителя на Объект уборки в
            предусмотренный настоящим Договором срок в состоянии,
            соответствующем условиям Заказа, с составлением соответствующего
            Акта. В Акте стороны обязаны описать все особенности, повреждения,
            недостатки и иные свойства Объекта, препятствующие выполнению работ
            и не описанные настоящим Договором. В случае, если указанные
            особенности, повреждения, недостатки и иные свойства Объекта,
            препятствующие выполнению работ, выявятся в процессе выполнения
            работ, Исполнитель вправе, без применения к ней каких-либо штрафных
            санкций, приостановить выполнение работ до момента согласования
            Сторонами дальнейших действий по выполнению работ. Если такое
            согласование не будет достигнуто в течение рабочего часа, то начиная
            со следующего часа, персонал Исполнителя будет считаться находящимся
            в вынужденном простое не по вине Исполнителя, с последующей оплатой.
          </li>
          <li>
            5.2. Подготовить Объект для возможности проведения работ по его
            уборке Исполнителем без причинения ущерба Объекту, в том числе
            провести необходимое закрепление и ремонт предметов экстерьера и
            интерьера, изолировать помещения, в которых производится уборка от
            иных помещений, удалить из Объекта ценные, хрупкие и иные не
            подлежащие уборке предметы и/или вещи, а также письменно
            проинформировать Исполнителя о наличии таких предметов и/или вещей
            на Объекте и необходимости применения специальных методов их очистки
            и/или сохранности. В случае непредоставления такой информации
            Исполнитель не несет ответственности за возможное повреждение таких
            предметов и/или вещей.
          </li>
          <li>
            5.3. Обеспечить присутствие на Объекте в течение всего периода
            проведения уборки ответственных представителей Заказчика с правом
            подписания от имени Заказчика всех необходимых в соответствии с
            условиями настоящего Договора актов, согласований, разрешений и иных
            документов, а также для решения возникающих вопросов в оперативном
            порядке.
          </li>
          <li>
            5.4. Обеспечить беспрепятственный доступ персонала Исполнителя с
            необходимым инвентарем и оборудованием в течение всего периода
            производства работ во все подлежащие уборке помещения на Объекте.
            При необходимости оформления пропусков на Объект Заказчик обязуется
            предупредить об этом Исполнителя заблаговременно, а также обязуется
            оформить указанные пропуска по письменной заявке Исполнителя до
            момента передачи Объекта для проведения уборки.
          </li>
          <li>
            5.5. Обеспечить Исполнителя исправными источниками: электроэнергии
            (220 Вт, 50 Гц), холодного и горячего водоснабжения, исправной
            системой канализации, предоставить места складирования отходов,
            мусора. В случае производства работ при недостаточном естественном
            освещении, обеспечить нормальное искусственное освещение Объекта
            уборки.
          </li>
          <li>
            5.6. Предоставить помещение (место) на Объекте или в
            непосредственной близости от Объекта, пригодное для переодевания
            сотрудников Исполнителя, а также обеспечить персоналу Исполнителя
            возможность использования туалетов и умывальников для личной
            гигиены.
          </li>
          <li>
            5.7. Создать безопасные условия для проведения работ сотрудниками
            Исполнителя на Объекте, а также исключить иные факторы, которые
            могут повлечь травмирование сотрудников Исполнителя, при условии
            соблюдения сотрудниками Исполнителя техники безопасности.
          </li>
          <li>
            5.8. Незамедлительно сообщить Исполнителю о возникновении ситуации,
            которая может привести к аварии или несчастному случаю, о несчастных
            случаях, произошедших на Объекте Заказчика с сотрудниками
            Исполнителя.
          </li>
          <li>
            5.9. На основании информации, поступившей от Исполнителя,
            незамедлительно принимать меры по устранению обстоятельств,
            препятствующих проведению работ Исполнителем. Нести ответственность
            на действия третьих лиц, препятствующих выполнению работ
            Исполнителем, как за свои собственные. В случае, если
            обстоятельства, препятствующие ведению работ Исполнителем, не будут
            устранены в течение текущего рабочего часа то, начиная со следующего
            часа, персонал Исполнителя будет считаться находящимся в вынужденном
            простое не по вине Исполнителя с последующей оплатой.
          </li>
          <li>
            5.10. Принять надлежащим образом выполненные работы в порядке,
            предусмотренном настоящим Договором.
          </li>
          <li>5.11. Осуществить оплату стоимости услуг Исполнителя.</li>
          <li>
            5.12. Заказчик имеет право контролировать процесс выполнения работ
            Исполнителем, не вмешиваясь при этом в его деятельность, за
            исключением случаев, когда такое вмешательство может и должно
            предотвратить возможный ущерб от выполнения работ.
          </li>
        </ul>
        <div className="landing__additional-title">
          6. Права и обязанности Исполнителя
        </div>
        <div>Исполнитель обязуется:</div>
        <ul className="landing__additional-list">
          <li>6.1. Выполнять работы качественно и в согласованные сроки.</li>
          <li>
            6.3. Незамедлительно информировать Заказчика (его представителя на
            Объекте) в случае возникновения обстоятельств, препятствующих
            выполнению работ Исполнителя, с правом приостановки работ и
            продления сроков проведения уборки до получения соответствующих
            указаний Заказчика и подписания (при необходимости) соответствующих
            дополнений к настоящему Договору.
          </li>
          <li>
            6.4. Исполнитель вправе отказаться от исполнения настоящего Договора
            в одностороннем внесудебном порядке путем направления Заказчику
            письменного уведомления, в случае задержки в оплате Заказчиком любых
            сумм по настоящему Договору, а также вынужденного простоя не по вине
            Исполнителя более 2 (двух) часов. В этом случае все ранее уплаченные
            Заказчиком по настоящему Договору авансовые платежи возврату не
            подлежат, а засчитываются в качестве компенсации убытков
            Исполнителя, включая упущенную выгоду.
          </li>
          <li>
            6.5. Исполнитель оставляет за собой право самостоятельно
            осуществлять кадровую политику и управление персоналом (в т.ч.
            расстановку персонала по обслуживаемым Объектам, замену персонала,
            перемещение персонала на другие Объекты и т.д.)
          </li>
          <li>
            6.6. Исполнитель назначает представителя с полномочиями на сдачу
            работ и подписание претензий и замечаний, а также для решения
            оперативных вопросов, возникающих в процессе уборки Объекта.
          </li>
          <li>
            6.7. Исполнитель (или уполномоченное Исполнителем лицо) имеет право
            посещать и осматривать (в том числе предварительно) помещения
            Заказчика, в которых ведутся (или должны вестись) работы
            Исполнителем, с целью контроля и уточнения данных, указанных в
            Заявке.
          </li>
          <li>
            6.8. В случае изменения согласованного в соответствии с Заказом
            перечня работ в сторону увеличения или усложнения, Исполнитель
            вправе потребовать соразмерного увеличения стоимости услуг по
            настоящему Договору, а Заказчик обязан их оплатить.
          </li>
        </ul>
        <div className="landing__additional-title">
          7. Стоимость услуг и порядок расчетов по договору.
        </div>
        <ul className="landing__additional-list">
          <li>
            7.1. Оплата услуг, предоставляемых ЗАКАЗЧИКУ, осуществляется путём
            оплаты выставленного счёта и подписания Акта по предоставленным
            услугам.
          </li>
          <li>
            7.2. Оплата производится ЗАКАЗЧИКОМ путем перечисления денежных
            средств на расчетный счет ИСПОЛНИТЕЛЯ на основании счета,
            выставленного ИСПОЛНИТЕЛЕМ или с помощью других видов платежей по
            согласованию с ИСПОЛНИТЕЛЕМ.
          </li>
          <li>
            7.3. В целях Договора Оферты принимается оплата от ЗАКАЗЧИКА: – в
            безналичной форме с расчетного счета ЗАКАЗЧИКА на расчетный счет
            ИСПОЛНИТЕЛЯ; – через отделения Сбербанка РФ или других действующих
            банков России; — с помощью других видов платежей по согласованию с
            ИСПОЛНИТЕЛЕМ.
          </li>
          <li>
            7.4. ЗАКАЗЧИК самостоятельно отслеживает изменение реквизитов
            ИСПОЛНИТЕЛЯ, указанных в настоящем Договоре, и несет ответственность
            за правильность производимых им платежей.
          </li>
        </ul>
        <div className="landing__additional-title">
          8. Защита персональных данных
        </div>
        <ul className="landing__additional-list">
          <li>
            8.1. В соответствии с ч.2 ст.6 Федерального закона от 27 июля 2006
            г. No 152-ФЗ «О персональных данных», обработка персональных данных
            Пользователя, осуществляется в целях исполнения настоящего договора,
            одной из сторон которых является Пользователь. Персональная
            информация Пользователей распространению не подлежит за исключением
            случаев, предусмотренных законодательством РФ.
          </li>
          <li>
            8.2. Исполнитель обязуется использовать все личные данные
            Пользователя, указываемые им в процессе оформления Заказа,
            исключительно для оформления продажи соответствующих услуг,
            идентификации и поддержки Пользователя.
          </li>
          <li>
            8.3. Заказчик не вправе воспроизводить, повторять и копировать,
            продавать и перепродавать, а также использовать для каких-либо
            коммерческих целей сайт, его контент, или доступ к нему, кроме тех
            случаев, когда Заказчик получил такое разрешение от Исполнителя,
            либо, когда это прямо предусмотрено настоящим Договором.
          </li>
          <li>
            8.4. Путем присоединения к настоящему Договору Пользователь дает
            согласие на получение от Исполнителя рассылок
            рекламно-информационного характера. Указанные рассылки содержат
            информацию о предстоящих акциях и других мероприятиях Компании
            Исполнителя, включая рекламные рассылки и специальные предложения.
            Рассылки поступают в виде письма на электронный адрес и/или
            короткого сообщения (sms) на номер телефона, указанный
            Пользователем. Рекламно-информационные материалы могут
            представляться также в виде бумажно-полиграфической и сувенирной
            продукции и доставляться на указанный Пользователем почтовый адрес в
            виде писем и посылок или любом иным способом.
          </li>
        </ul>
        <div className="landing__additional-title">
          9. Ответственность сторон.Ограничение ответственности. Форс—мажор.
        </div>
        <ul className="landing__additional-list">
          <li>
            9.1. За невыполнение или ненадлежащее выполнение обязательств по
            настоящему договору публичной оферты Исполнитель и Заказчик несут
            ответственность в соответствии с действующим законодательством.
          </li>
          <li>
            9.2. Исполнитель не несет ответственности за невозможность
            обслуживания Заказчика по каким-либо независящим от него техническим
            причинам, включая нарушение работы каналов связи, неисправность
            оборудования и т.п.
          </li>
          <li>
            9.3. Исполнитель не несет ответственности за нарушение условий
            договора публичной оферты, если такое нарушение вызвано действием
            обстоятельств непреодолимой силы (форс- мажор): действия органов
            государственной власти, пожар, наводнение, землетрясение, другие
            стихийные действия, отсутствие электроэнергии и/или сбои работы
            компьютерной сети, забастовки, гражданские волнения, беспорядки,
            действиями (бездействиями) администрации здания препятствующими
            Исполнителю в доступе к помещению, любые иные обстоятельства,
            которые могут повлиять на выполнение Исполнителем условий настоящей
            публичной оферты и неподконтрольные Исполнителю.
          </li>
          <li>
            9.4. Заказчик принимает на себя всю ответственность за обеспечение
            беспрепятственного доступа к помещению, уборка которого была
            заказана с его аккаунта.
          </li>
          <li>
            9.4.1. В случае невозможности обеспечить доступ к помещению в ранее
            согласованные дату и время Заказчик должен уведомить Исполнителя о
            необходимости переноса уборки не позднее, чем за сутки до
            предполагаемой уборки.
          </li>
          <li>
            9.5. В случае если Заказчик не уведомил Исполнителя о необходимости
            переноса и не обеспечил в ранее оговоренные дату и время доступ к
            помещению, Исполнитель имеет право отказаться от исполнения услуги,
            вследствие чего невыполнение уборки будет считаться «пропущенным
            мероприятием».
          </li>
          <li>
            9.6. Заказчик принимает на себя всю ответственность за обеспечение
            личного присутствия либо другого лица из числа проживающих в
            помещении во время оказания услуги по уборке помещения.
          </li>
          <li>
            9.6.1. В случае невозможности обеспечить доступ в помещение
            вследствие отсутствия Заказчика либо другого лица, проживающего в
            помещении, в ранее согласованные дату и время Заказчик должен
            уведомить Исполнителя о необходимости переноса уборки не позднее,
            чем за сутки до предполагаемой уборки.
          </li>
          <li>
            9.6.2. В случае невозможности обеспечения доступа в помещение для
            уборки, вследствие отсутствия Заказчика либо другого лица,
            проживающего в помещении, в результате чего выполнение услуги будет
            невозможным, Исполнитель вправе отказаться от оказания услуги,
            вследствие чего невыполнение уборки будет считаться «пропущенным
            мероприятием».
          </li>
          <li>
            9.7. Заказчик принимает на себя всю ответственность за сохранность
            личных и ценных вещей проживающих.
          </li>
          <li>
            9.7.1. В случае если оказание услуг не предполагает личного
            присутствия Заказчика, либо одного из проживающих в помещении,
            Заказчик обязуется обеспечить отсутствие личных и ценных вещей в
            убираемом помещении.
          </li>
          <li>
            9.7.2. Если процесс оказания услуг предполагает необходимость
            личного присутствия одного из проживающих либо иного лица, то вся
            ответственность за сохранность личных и ценных вещей в процессе
            оказания услуг ложится на Заказчика, либо другое лицо, проживающее в
            данном помещении и присутствующее при выполнении уборки.
          </li>
          <li>
            9.7.3. При несоблюдении Заказчиком условий, указанных в п. 9.6.1 и
            9.6.2. Исполнитель не несет ответственности за пропажу личных и
            ценных вещей лиц, проживающих в данном помещении. В случае
            обнаружения пропажи личных и/или ценных вещей, Заказчик вправе
            предъявить претензию по факту такого обнаружения только в период
            проведения уборки либо непосредственно после ее окончания. Факт
            подписания Заказчиком Акта приемки выполненных работ свидетельствует
            об отсутствии претензий в части сохранности личных и ценных вещей
            лиц, проживающих в помещении.
          </li>
          <li>
            9.8. В случае обнаружения недостатков во время приемки результата
            выполненной услуги Заказчик вправе потребовать от Исполнителя
            безвозмездного устранения обнаруженных недостатков или возмещения
            понесенных им расходов на исправление недостатков своими средствами
            или третьими лицами. Факт обнаружения недостатков должен быть
            зафиксирован в Акте приема выполненных работ (услуг) с последующим
            выставлением рекламации. При несоблюдении Заказчиком данных условий
            Исполнитель оставляет за собой право считать Договор оферты
            выполненным надлежащим образом и в полном объеме.
          </li>
          <li>
            9.9. Совокупная ответственность Исполнителя по договору Оферты, по
            любому иску или претензии в отношении Договора оферты или его
            исполнения, ограничивается стоимостью услуги, по которой у Заказчика
            возникли претензии.
          </li>
          <li>
            9.10. Споры по исполнению настоящего договора, возникшие по
            инициативе Заказчика, подлежат рассмотрению с обязательным
            соблюдением досудебного претензионного порядка. Претензия
            направляется Исполнителю с использованием формы обратной связи,
            доступной на Веб-сайте Исполнителя или инфм способом. Срок ответа на
            претензию не может превышать 20 дней с момента получения претензии
            Исполнителем. В случае невозможности урегулирования спора в
            досудебном порядке, дальнейший спор подлежит рассмотрению в суде
            общей юрисдикции по месту нахождения Исполнителя.
          </li>
          <li>
            9.11. Исполнитель вправе без объяснения причин отказать Заказчику в
            оформлении заказа. При этом, в случае если Заказчиком были оплачены
            услуги при оформлении заказа, Исполнитель обязуется либо направить
            Заказчику подтверждение заказа, либо отказаться от оформления заказа
            и вернуть Заказчику уплаченные им денежные средства.
          </li>
          <li>
            9.12. Заказчик обязуется ознакомиться с актуальной версией Договора
            при каждом посещении сайта до момента пользования сайтом.
          </li>
          <li>
            9.13. Заказчик обязуется соблюдать условия настоящего Договора и
            правила пользования сайтом.
          </li>
          <li>
            9.14. Заказчик обязуется предоставлять достоверную и полную
            информацию при использовании сайта.
          </li>
          <li>
            9.15. Заказчик обязуется самостоятельно проверить данные заказа
            перед его оформлением. Заказчик несет полную ответственность за
            достоверность и правомерность использования данных, указываемых им
            при оформлении заказа.
          </li>
          <li>
            9.16. В максимальной степени, разрешенной применимым
            законодательством и несмотря на любые другие положения настоящего
            Договора, общий размер ответственности Исполнителя перед Заказчиком
            или любым физическим или юридическим лицом, предъявляющим требования
            посредством Заказчика, в результате отношений с Заказчиком или по
            указанию последнего, в отношении любых оснований для предъявления
            иска, претензий, ущерба, требований, ответственности, убытков и
            процессов любого типа и характера, включая все судебные издержки,
            судебные расходы, и расходы на оплату услуг представителей,
            возникающие из или связанные каким-либо образом с настоящим
            Договором, независимо от причины или причин включая, но не
            ограничиваясь этим, любые нарушения Договора, расходы на исправление
            недостатков, неосторожность или объективную ответственность не будет
            превышать 5 000 (пять тысяч) рублей. Указанное ограничение
            ответственности Исполнитель не распространяется на убытки и
            ответственность, вызванные умышленными действиями Исполнителя.
          </li>
          <li>
            9.17. Заказчик принимает на себя полную ответственность и риски,
            связанные с оформлением Заказов, заключение и исполнением Договора с
            Исполнителем. Заказчик несет единоличную ответственность за любую
            проблему, возникшую в процессе оформления, подтверждения и оплаты
            Заказа. В подобном случае Исполнитель не несет ответственность за
            любой ущерб (денежного или иного характера), возникший в результате
            проблемы, вызванной или обусловленной ошибкой или халатностью
            Заказчика.
          </li>
          <li>
            9.18. Все ценные вещи, а также иные материальные ценности Заказчика
            и/или третьих лиц во время оказания Исполнителем услуг, должны быть
            в недоступном месте.
          </li>
        </ul>
        <br />
        <div>
          <div>РЕКВИЗИТЫ ПРОДАВЦА</div>
          <div>Продавец: «ТВОЯУБОРКА» ИП Юсупов</div>
          <div>Телефон</div>
          <div>+7 915 264-00-11</div>
          <div>Банковские реквизиты</div>
          <div>
            К/с 30101810200000000593 Рас/с 40802810202630001729 Бик: 044525593
          </div>
          <div>
            123317 г Москва., Пресненская наб., дом 12,Башня- Восток 25 этаж,
            офис 2.
          </div>
          <br />
          <div>
            Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не
            согласны с каким- либо пунктом оферты, Вы вправе отказаться от
            покупки Товаров, предоставляемых Продавцом, и не совершать действий,
            указанный в п. 2.1. настоящей Оферты. Согласен с договором
          </div>
        </div>
        <br />
      </section>
    </>
  );
};

export default Agreement;
