import Office from '../../../assets/office.jpg'
import { YandexLogin } from '../../../components/yandex'
import './CleaningOffice.scss'

const clientID = '38fad74eb4444c649c22b27c1a04f3ac'

const CleaningOffice = () => {
  const loginSuccess = (userData) => {}

  return (
    <section className="benefits__wrapper">
      <div className="landing__container cleaning__office">
        <div>
          <div className="benefits__title">
            Уборка офисов и коммерческих помещений любой сложности
          </div>
          <YandexLogin clientID={clientID} onSuccess={loginSuccess}>
            <button className="button">Войти</button>
          </YandexLogin>
        </div>
        <img src={Office} alt="" />
      </div>
    </section>
  )
}

export default CleaningOffice
