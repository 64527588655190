import { useState } from "react";
import { Navigate } from "react-router-dom";
import Close from "../../../assets/close.svg"
import { useSelector } from "react-redux";
import { selectIsAuth } from "../../../redux/slices/auth";
import "./Timetable.scss";

const Timetable = () => {

  const isAuth = useSelector(selectIsAuth);
  const [posts, setPost] = useState([
    {
      id: 1,
      title: 'Расписание 1',
      text: 'Запланирована уборка на 16:00, 7 окт 2021',
      date: '7 окт 2021 15:00',
      notificationIcon: <div className="timetable__notification-icon"></div>,
    },
    {
      id: 2,
      title: 'Расписание 2',
      text: 'Запланирована уборка на 16:00, 7 окт 2021',
      date: '7 окт 2021 15:00',
      notificationIcon: <div className="timetable__notification-icon"></div>,
    },
    {
      id: 3,
      title: 'Расписание 3',
      text: 'Запланирована уборка на 16:00, 7 окт 2021',
      date: '7 окт 2021 15:00',
      notificationIcon: <div className="timetable__notification-icon"></div>,
    },
    {
      id: 4,
      title: 'Расписание 4',
      text: 'Запланирована уборка на 16:00, 7 окт 2021',
      date: '7 окт 2021 15:00',
      notificationIcon: <div className="timetable__notification-icon"></div>,
    },
    {
      id: 5,
      title: 'Расписание 5',
      text: 'Запланирована уборка на 16:00, 7 окт 2021',
      date: '7 окт 2021 15:00',
      notificationIcon: <div className="timetable__notification-icon"></div>,
    },
  ]);
  const removeItem = (items) => {
    setPost(posts.filter(p => p.id !== items.id))
  };

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to="/auth" />;
  }
  return (
    <ul className="timetable__items">
      {posts.length !== 0
        ?
        <div>
          {posts.map((items, id) => (
            <li key={id} className="timetable__item">
              {items.notificationIcon}
              <div>
                <div className="timetable__item-title">{items.title}</div>
                <div className="timetable__item-text">{items.text}</div>
              </div>
              <div>
                {items.date}
                <span>
                  <img
                    onClick={() => removeItem(items)}
                    className="timetable__item-img" src={Close} alt=""
                  />
                </span>
              </div>
            </li>
          ))}
        </div>
        :
        <div style={{ textAlign: 'center', fontSize: '22px', padding: '40vh 0' }}>У вас нет уведомлений</div>
      }
    </ul >
  )
}

export default Timetable;