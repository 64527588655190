import React from 'react';
import {useDispatch} from "react-redux";
import {cancelOrder, fetchInfoOrder} from "../../../redux/slices/order";

const CancelOrder = ({orderId, orderNumber = 1, handleCloseModal}) => {
  const dispatch = useDispatch();
  const handleCancelOrder = () => {
    dispatch(cancelOrder(orderId))
    dispatch(fetchInfoOrder())
    handleCloseModal()
  }


  return (
    <>
      <div className="completion-registration__wrapper"></div>
      <div className="completion-registration">
        <div className="completion-registration__title">Отменить заказ
          №{orderNumber.toString().padStart(5, '0')}{/* добавить номер заказа как будет реализовано*/}</div>
        <div className="completion-registration__form-text">Вы действительно хотите отменить заказ?</div>
        <span
          href="/"
          type="button"
          onClick={handleCancelOrder}
          className="completion-registration__form-button"
        >
        Да
        </span>
        <span
          href="/"
          type="button"
          onClick={handleCloseModal}
          className="completion-registration__form-button"
        >
        Нет
      </span>
      </div>
    </>
  );
};

export default CancelOrder;