import "./Prices.scss";
import {
  servicesDataMoscow,
  pricesSupportingRoomPage,
  pricesGeneralRoomPage,
  pricesRepairsRoomPage,
} from "../../../data";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Prices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Прайс-лист на услуги клининга Твоя Уборка</title>
        <meta
          name="description"
          content="Ознакомьтесь с нашим прайс-листом на услуги уборки. Прозрачные цены и качественный сервис! Ознакомьтесь с прайс-листом и выберите подходящий вариант для вас!"
        />
      </Helmet>
      <section className="landing__container">
        <div className="landing__additional-title">
          Какие услуги мы предоставляем?
        </div>
        <div className="prices-container">
          <div className="prices-title__blue">ТвояУборка</div>
          <div className="prices-info__container">
            <div className="prices-title__black">
              Поддерживающая “стандартная” уборка
            </div>
            <div className="prices-lists__container">
              <ul className="prices-list">
                <li>Вся квартира:</li>
                <li>Моем полы</li>
                <li>Чистим ковёр</li>
                <li>Чистим мебель</li>
                <li>Протираем пыль</li>
                <li>Моем зеркала</li>
                <li>Сложим вещи</li>
                <li>Вынесем мусор</li>
              </ul>
              <ul className="prices-list">
                <li>Кухня:</li>
                <li>Моем полы</li>
                <li>Моем раковину</li>
                <li>Моем столешницу</li>
                <li>Моем плиту</li>
                <li>Моем обеденный стол</li>
              </ul>
              <ul className="prices-list">
                <li>Ванная и санузел:</li>
                <li>Моем полы</li>
                <li>Моем ванну или душевую</li>
                <li>Моем раковину</li>
                <li>Моем унитаз</li>
                <li>Моем биде</li>
              </ul>
            </div>
            <ul className="prices-list__info">
              {pricesSupportingRoomPage.map((item, index) => {
                return (
                  <li key={index}>
                    <div>{item.title}</div>
                    <div>{item.price} ₽</div>
                  </li>
                );
              })}
            </ul>
            <div>*1 санузел входит в базовую стоимость</div>
            <div>**Дополнительный санузел - 600 ₽</div>
          </div>

          <div className="prices-info__container">
            <div className="prices-title__black">Генеральная уборка</div>
            <div className="prices-lists__container">
              <ul className="prices-list">
                <li>Вся квартира:</li>
                <li>Моем полы и пылесосим</li>
                <li>Чистим ковёр</li>
                <li>Чистим мебель</li>
                <li>Протираем пыль</li>
                <li>Моем зеркала</li>
                <li>Складываем вещи</li>
                <li>Выносим мусор</li>
                <li>Убираемся в гардеробной</li>
                <li>Убираемся внутри шкафов</li>
                <li>Протираем стены</li>
              </ul>
              <ul className="prices-list">
                <li>Кухня:</li>
                <li>Моем полы и пылесосим</li>
                <li>Моем раковину</li>
                <li>Моем столешницу</li>
                <li>Моем плиту</li>
                <li>Моем обеденный стол</li>
                <li>Моем шкафы на кухне</li>
                <li>Моем вытяжку</li>
              </ul>
              <ul className="prices-list">
                <li>Ванная и санузел:</li>
                <li>Моем полы</li>
                <li>Моем ванну или душевую</li>
                <li>Моем раковину</li>
                <li>Моем унитаз</li>
                <li>Моем биде</li>
                <li>Моем настенную плитку</li>
              </ul>
            </div>
            <ul className="prices-list__info">
              {pricesGeneralRoomPage.map((item, index) => {
                return (
                  <li key={index}>
                    <div>{item.title}</div>
                    <div>{item.price} ₽</div>
                  </li>
                );
              })}
            </ul>
            <div>*1 санузел входит в базовую стоимость</div>
            <div>**Дополнительный санузел - 600 ₽</div>
          </div>

          <div className="prices-info__container">
            <div className="prices-title__black">Уборка после ремонта</div>
            <div className="prices-lists__container">
              <ul className="prices-list">
                <li>Моем полы и пылесосим</li>
                <li>Осуществляем дезинфекцию</li>
                <li>Моем настенную плитку и кафель</li>
                <li>Чистим мебель</li>
                <li>Протираем пыль</li>
                <li>Снимаем скотч</li>
                <li>Убираем следы краски</li>
                <li>Моем радиаторы отопления</li>
                <li>Протираем стены</li>
              </ul>
            </div>
            <ul className="prices-list__info">
              {pricesRepairsRoomPage.map((item, index) => {
                return (
                  <li key={index}>
                    <div>{item.title}</div>
                    <div>{item.price} ₽</div>
                  </li>
                );
              })}
            </ul>
            <div>*1 санузел входит в базовую стоимость</div>
            <div>**Дополнительный санузел - 600 ₽</div>
          </div>

          <div className="prices-title__black">Дополнительные услуги</div>
          <ul className="prices-services">
            {servicesDataMoscow.map((service, item) => {
              return (
                <li key={item}>
                  <div className="prices-services__block">
                    <img width={125} src={service.img} alt={service.alt} />
                    <div>{service.name}</div>
                  </div>
                  <div>{service.price} ₽</div>
                </li>
              );
            })}
          </ul>

          <Link to="/calculator" className="prices-button">
            Заказать
          </Link>
        </div>
      </section>
    </>
  );
};

export default Prices;
