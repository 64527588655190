import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchRequest = createAsyncThunk('api/fetchRequest', async (params) => {
  const { data } = await axios.post('/api/request', params);
  return data;
});

const initialState = {
  data: null,
  status: 'loading',
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRequest.pending]: (state) => {
      state.status = 'loading';
      state.data = null;
    },
    [fetchRequest.fulfilled]: (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    },
    [fetchRequest.rejected]: (state) => {
      state.status = 'error';
      state.data = null;
    },
  },
});

export const requestReducer = requestSlice.reducer;