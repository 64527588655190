import { useDispatch, useSelector } from 'react-redux'
import LocationClient from '../../../assets/location-client.svg'
import LocationClientWhite from '../../../assets/LocationClientWhite.svg'
import { useEffect, useState } from 'react'
import { setCity } from '../../../redux/slices/city'
import { clearProducts } from '../../../redux/slices/calculator'
import axios from 'axios'

const ModalCities = ({ navScroll, additionalClass }) => {
  const [cityName, setCityName] = useState('')
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { city } = useSelector((state) => state.city)

  useEffect(() => {
    // Получение города по ip
    try {
      const getData = async () => {
        const response = await axios.get('api/checkip')
        const data = response.data
        console.log(data)
        if (data.status == 'success') {
          if (
            data.regionName == 'Московская область' ||
            data.regionName == 'Москва'
          ) {
            setCityName(data.city)
            dispatch(setCity(0))
          } else if (
            data.regionName == 'Санкт-Петербург' ||
            data.regionName == 'Ленинградская область'
          ) {
            setCityName(data.city)
            dispatch(setCity(1))
          } else {
            setCityName(`${data.city} - В этом регионе услуги не оказываются`)
          }
        }
      }
      getData()
    } catch (error) {
      console.error('Error fetching location data:', error)
    }
  }, [])

  const onClickCities = (i) => {
    setOpen(false)
    dispatch(setCity(i))
    dispatch(clearProducts(i))
    setCityName(city && city.cities ? city.cities[i] : '')
  }

  const classes = `${additionalClass}`

  return (
    <div
      className={
        navScroll ? `nav__citie nav__citie-scroll` : `nav__citie ${classes}`
      }
    >
      <div>
        <img
          className="nav__icon-client"
          src={navScroll ? LocationClientWhite : LocationClient}
          alt="icon"
        />
        <span onClick={() => setOpen(!open)}>{cityName}</span>
      </div>
      <div className="nav__cabinet-modal nav__citie-modal">
        {open && (
          <>
            <span
              onClick={() => setOpen(false)}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: -100,
              }}
            ></span>
            {open &&
              city &&
              city.cities &&
              city.cities.map((item, index) => (
                <div key={index}>
                  <div
                    onClick={() => onClickCities(index)}
                    className="nav__cabinet-modal-link"
                  >
                    {item}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

export default ModalCities
