import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import BenefitBlock from "../../../components/benefit-block/BenefitBlock";
import Furniclean from "./furniclean/Furniclean";
import Questions from "./questions/Questions";
import PlaceOrder from "./place-order/PlaceOrder";
import Feedback from "../landing/feedback/Feedback";
import { Helmet } from "react-helmet-async";

const CleaningPage = () => {
  const tabs = [
    {
      title: "Химчистка и стирка",
      content: "с удобной доставкой",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = index => {
    setActiveTab(index);
  };

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };
  return (
    <>
      <Helmet>
        <title>Заказать качественуя химчистку ковров и мебели в Москве</title>
        <meta
          name="description"
          content="Профессиональная химчистка в Москве. Мы предлагаем услуги по профессиональной химчистке ковров и мебели, возвращая им первоначальный вид и свежесть."
        />
      </Helmet>
      <section style={{ overflow: "hidden" }}>
        <div className="landing__main">
          <div className="landing__container service-list-container">
            <ul className="service-list">
              <li>
                <Link to="/uslugi/uborka-kvartir">
                  <div>Уборка Квартиры</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/office/">
                  <div>Уборка офиса</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                  <div>Склад</div>
                </Link>
              </li>
              <li>
                <Link to="/cleaning">
                  <div>Химчистка</div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="landing__wrapper-form">
          <div className="landing__container landing__container-tab">
            <div></div>

            {activeTab !== -1 ? (
              <div className="landing__main-form landing__main-form-tab">
                <div className="landing__form">
                  <div className="landing__form-title">
                    <div>Химчистка и стирка</div>
                    <div>с удобной доставкой</div>
                  </div>
                  <Link
                    to={"/calculator-furniture"}
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}>
                    Заказать онлайн
                  </Link>
                  <div className="landing__form-border">
                    <a
                      href="https://yandex.ru/business/widget/request/company/157082417183"
                      target="_blank"
                      className="landing__form-button"
                      style={{
                        textDecoration: "none",
                      }}
                      rel="noreferrer">
                      Перезвонить мне
                    </a>
                  </div>
                  <div className="landing__promo">
                    <div
                      className="promo"
                      ref={promoRef}
                      onClick={copyToClipboard}>
                      First
                    </div>
                    <div className="title">
                      <strong>Скидка 8%</strong> на первый заказ{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <BenefitBlock
          description1={"За каждую уборку начисляем"}
          description2={"Первая уборка дешевле на"}
          description3={"Уборка каждую неделю выгодна на"}
        />
        <div className="benefits__wrapper">
          <Furniclean />
        </div>
        <PlaceOrder />
        <Feedback />
        <div className="benefits__wrapper">
          <Questions />
        </div>
        <div className="landing__container" style={{ padding: "22px 0" }}>
          <h1>
            Химчистка и профессиональный клининг от "Твоя уборка": забота о
            чистоте без компромиссов
          </h1>
          <p>
            Чистота – не просто дань внешнему виду, а залог здоровья и комфорта.
            В мегаполисе, таком как Москва, где ритм жизни диктует свои правила,
            времени на поддержание порядка в доме или офисе катастрофически не
            хватает. Именно поэтому химчистка и клининг становятся незаменимыми
            услугами. Компания "Твоя уборка" предлагает комплексный подход к
            химчистке, который гарантирует идеальную чистоту и сохранение
            качества вещей.
          </p>
          <h2>Химчистка от "Твоя уборка": чистота без компромиссов</h2>
          <p>
            Когда речь идет о профессиональной химчистке, важным аспектом
            является использование высококачественных средств и оборудования.
            Компания "Твоя уборка" уделяет особое внимание каждой детали, чтобы
            ваши вещи сохраняли свой первоначальный вид и служили дольше. В
            процессе химчистки мы используем только безопасные и
            сертифицированные составы, которые эффективно удаляют загрязнения,
            не повреждая ткань и не вызывая аллергических реакций.
          </p>
          <h2>
            Химчистка мебели: безупречная чистота и забота о ваших любимых
            предметах
          </h2>
          <p>
            Мебель – это не просто функциональные предметы интерьера, но и часть
            вашего комфорта и уюта. Со временем мягкая мебель накапливает пыль,
            пятна и другие загрязнения, которые сложно удалить в домашних
            условиях. Профессиональная химчистка мебели от компании "Твоя
            уборка" позволяет сохранить ее внешний вид и продлить срок службы,
            обеспечивая безупречную чистоту и свежесть.
          </p>
          <p>
            Мы тщательно изучаем материал и степень загрязнения вашей мебели,
            подбирают оптимальные чистящие средства и методы, чтобы сохранить
            текстуру и цвет. Химчистка мебели включает в себя удаление пятен,
            очистку от пыли и аллергенов, Независимо от того, нужна ли вам
            химчистка диванов, кресел, матрасов или ковров, мы гарантируем
            высокое качество работы.
          </p>
          <h2>Почему стоит выбрать "Твоя уборка”?</h2>
          <p>
            Мы понимаем, что качество услуг – это главный критерий при выборе
            компании. "Твоя уборка" предлагает клиентам ряд преимуществ, которые
            делают нас лидерами на рынке химчистки и клининга в Москве:
          </p>
          <ul>
            <li>
              Индивидуальный подход: внимательно изучаем потребности каждого
              клиента и предлагаем решения, максимально соответствующие его
              ожиданиям. Наша цель – удовлетворить все запросы и превзойти ваши
              ожидания.
            </li>
            <li>
              Безопасность: используем только проверенные и безопасные средства,
              которые не наносят вреда ни вам, ни окружающей среде. Каждый этап
              уборки контролируется нашими специалистами, что исключает любые
              неприятные сюрпризы.
            </li>
            <li>
              Оптимальные цены: "Твоя уборка" предлагает доступные услуги без
              ущерба качеству. Мы понимаем, что цена имеет значение, поэтому
              стараемся предложить лучшие условия для наших клиентов. Вы можете
              легко заказать химчистку или клининг по разумной стоимости.
            </li>
            <li>
              Скорость и удобство: мы ценим ваше время и предлагаем удобные
              временные интервалы для оказания услуг. Наши специалисты
              оперативно выполняют свою работу, не нарушая привычного ритма
              вашей жизни.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default CleaningPage;
