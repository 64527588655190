import Accordion from "./Accordion";
const Questions = () => {
  const items = [
    {
      title: "Запахи тоже выводите?",
      content:
        "Запахи полностью удаляются вместе грязью при промывке дивана. Дополнительно можно обработать мебель отдушками.",
    },
    {
      title: "Убираете любые пятна?",
      content: `
        В 90% случаев успешно удаляются все загрязнения, имеющиеся на обивке дивана. 
        Исключения могут составлять очень старые сложные пятна. 
        Перед началом чистки мастер осмотрит диван и предупредит о наличии загрязнений, которые, возможно, не удастся вывести. 
        Также не всегда получается удалить разводы от бытовой химии, образовавшиеся в результате самостоятельных попыток затереть обивку.
        `,
    },
    {
      title: "Химчистка дома – это вообще безопасно?",
      content: `
        Применяемые нами составы совершенно безвредны для чистки диванов на дому. 
        Детям и домашним животным во время проведения химчистки для безопасности достаточно находиться в других комнатах. 
        После окончания работ мастера рекомендуется проветрить помещение.
        `,
    },
    {
      title: "Сколько времени обычно занимает чистка дивана?",
      content: `
        Продолжительность работы мастера зависит от размера дивана, 
        количества посадочных мест на нем и от того, насколько сильно он испачкан. 
        В среднем, стоит рассчитывать не менее чем на 30 минут.
            `,
    },
  ];
  return (
    <div className="landing__container">
      <div className="benefits__title">Частые вопросы</div>
      <Accordion items={items} />
    </div>
  );
};

export default Questions;
