import React, { useState } from "react";
import "./Accordion.scss";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex;

    return (
      <div
        key={index}
        className={`accordion-item ${isActive ? "active" : ""}`}
        onClick={() => onTitleClick(index)}>
        <div className="title">
          <div className="title__text">{item.title}</div>
          <div className={`cross-icon ${isActive ? "rotate" : ""}`}>
            &times;
          </div>
        </div>
        <div className={`accordion-content ${isActive ? "active" : ""}`}>
          <p className="description">{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="ui styled accordion">{renderedItems}</div>;
};

export default Accordion;
