import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchUserInfo, fetchUserInfoChange } from "../../../../redux/slices/user";
import { useForm } from "react-hook-form";

const AddPassword = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  // useEffect(() => {
  //   dispatch(fetchUserInfo());
  //   dispatch(fetchUserInfoChange());
  // }, [dispatch]);
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      "old_password": "",
      "new_password": ""
    }
  });

  const handleOpen = () => {
    setOpen(!open);
  };

  const onSubmit = async (values) => {
    const data = await dispatch(fetchUserInfoChange(values));
    setOpen(!open);
    reset();
    document.location.reload();
    return data;
  }

  return (
    <div className="setting__data-item">
      <div>Пароль</div>
      <div>
        {user.items && user.items.password ? (
          <div>{user.items.password}</div>
        ) : (
          <div>Пароль не установлен</div>
        )}
      </div>
      <div onClick={handleOpen} className="setting__data-button">Изменить</div>
      {open &&
        <form onSubmit={handleSubmit(onSubmit)} >
          <input
            className="setting__data-change-modal-input"
            {...register('old_password', {
              required: true
            })}
            placeholder="Старый пароль"
            type="password"
          />
          <input
            className="setting__data-change-modal-input"
            {...register('new_password', {
              required: true
            })}
            placeholder="Новый пароль"
            type="password"
          />
          <button type="submit" className="setting__address-modal-button">Сохранить</button>
        </form>
      }
    </div>
  )
}

export default AddPassword;