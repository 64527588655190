import Logo from "../../../assets/logo-client.svg";
import LogoWhite from "../../../assets/logo-white.svg"
import { useState } from "react";
import { Link } from "react-router-dom";

const ModalCalculator = ({ navScroll }) => {

  const [openCalculator, setOpenCalculator] = useState(false);
  const handleOpenCalculator = () => {
    setOpenCalculator(!openCalculator);
  };
  return (
    <div className="nav__logo-wrapper">
      <Link to='/'><img src={navScroll ? LogoWhite : Logo} alt="logo" /></Link>
      {openCalculator && (
        <div onClick={handleOpenCalculator} className="nav__cabinet-modal">
          <div>
            <Link className="nav__cabinet-modal-link" to="/calculator">Калькулятор уборки</Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalCalculator;