import Routers from "./router/Routers";
import "./App.css";

const App = () => {
  return (
    <div className="app">
      <Routers />
    </div>
  );
}

export default App;
