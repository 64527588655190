import React from "react";
import { Link } from "react-router-dom";

export const Sitemap = () => {
  return (
    <section className="landing__container">
      <div className="landing__additional-title">Карта сайта</div>
      <ul className="landing__additional-list">
        <li>
          <Link to="/">Главная страница</Link>
        </li>
        <li>
          <Link to="/uslugi">Наши услуги</Link>
        </li>
        <li>
          <Link to="/calculator">Калькулятор</Link>
        </li>
        <li>
          <Link to="/uslugi/uborka-pomesheniy/office/">Уборка офиса</Link>
        </li>
        <li>
          <Link to="/uslugi/uborka-kvarti">Уборка Квартир</Link>
        </li>
        <li>
          <Link to="/uslugi/uborka-domov">Уборка домов</Link>
        </li>
        <li>
          <Link to="/uslugi/uborka-pomesheniy/">Уборка помещений</Link>
        </li>
        <li>
          <Link to="/cleaning">Химчистка</Link>
        </li>
        <li>
          <Link to="/uslugi/uborka-pomesheniy/warehouse/">Склад</Link>
        </li>
        <li>
          <Link to="/prices">Прайс-лист</Link>
        </li>
        <li>
          <Link to="/agreement">Договор</Link>
        </li>
        <li>
          <Link to="/policy">Политика конфиденциальности</Link>
        </li>
        <li>
          <Link to="/feedback">Отзывы</Link>
        </li>
        <li>
          <Link to="/contacts">Контакты</Link>
        </li>
        <li>
          <Link to="/karta-sayta">Карта сайта</Link>
        </li>
        <li>
          <Link to="/about">О нас</Link>
        </li>
      </ul>
    </section>
  );
};
