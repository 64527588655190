import './ThanksPage.scss'

const ThanksPage = () => {
  return (
    <div>
      <h1 className="thanks-page__title">
        Спасибо, что доверяете нам заботу о Вашем доме!
      </h1>
    </div>
  )
}

export default ThanksPage
