import logo from "../../../assets/auth/logo.svg";
import "./Auth.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { YandexLogin } from "../../../components/yandex";
import { useForm, watch } from "react-hook-form";
import { fetchAuth, sendCode } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

export const Auth = () => {
  const clientID = "38fad74eb4444c649c22b27c1a04f3ac";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const loginSuccess = userData => {};

  const { isPhoneChecked, isSendingCode, idCode } = useSelector(
    state => state.auth
  );

  const [authCode, setAuthCode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [hasInputStarted, setHasInputStarted] = useState(false);

  const { register, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const watchLogin = watch("login");

  const handleAuthCode = e => {
    setAuthCode(e.target.value);
  };

  // Проверка на валидность телефона
  const validatePhone = phone => {
    // Простой пример валидации: номер телефона должен начинаться с 7 и быть длиной 11 символов
    const phoneRegex = /^7\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSendCodePhone = () => {
    const loginValue = getValues("login");
    dispatch(sendCode(getValues("login")));
    setIsOpen(true);
    handleOpenPhone();

    setIsCodeSent(true);
    if (!validatePhone(loginValue)) {
      alert("Пожалуйста, введите корректный номер телефона.");
      return;
    }
    setIsCodeSent(true);
  };

  const handleOpenPhone = () => {
    setIsPhone(true);
  };

  // Проверка на валидность телефона при каждом изменении значения
  useEffect(() => {
    setIsPhoneValid(validatePhone(watchLogin));
  }, [watchLogin]);

  const onSubmit = async values => {
    const dataSend = {
      ...values,
      code: authCode,
      idCode,
    };

    const data = await dispatch(fetchAuth(dataSend));
    window.localStorage.setItem("formClose", false);
    window.localStorage.setItem("token", data.payload.accessToken);

    if (!data.payload) {
      return alert("Не удалось авторизоваться!");
    }

    if (data.payload) {
      window.localStorage.setItem("token", data.payload.accessToken);
      navigate("/main");
    }
  };

  // if (!window.localStorage.getItem('token') && !isAuth) {
  //   return <Navigate to="/" />
  // }

  return (
    <section className="auth">
      <div className="auth__wrapper">
        <img className="auth__logo" src={logo} alt="" />
        <div className="auth__form-container">
          <h1>Войти</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!isCodeSent && (
              <>
                <input
                  className="auth__form-input"
                  type="number"
                  {...register("login", { required: "Укажите телефон" })}
                  placeholder="7 (___) ___ __ __"
                  onInput={() => setHasInputStarted(true)}
                />
                {/* {hasInputStarted && !isPhoneValid && (
                  <div style={{ color: "red", fontSize: "13px" }}>
                    Укажите телефон формата 7 (___) ___ __ __
                  </div>
                )} */}
                <div
                  className={`auth__form-button ${
                    !isPhoneValid ? "disabled" : ""
                  }`}
                  onClick={isPhoneValid ? handleSendCodePhone : null} // Блокируем onClick, если телефон не валиден
                >
                  Войти
                </div>
              </>
            )}

            {isCodeSent && (
              <>
                <div>Введите код из СМС</div>
                <input
                  className="auth__form-input"
                  value={authCode}
                  onChange={handleAuthCode}
                  placeholder="Введите код"
                />
                <button type="submit" className="auth__form-button">
                  Войти
                </button>
              </>
            )}
            <YandexLogin clientID={clientID} onSuccess={loginSuccess}>
              <button className="auth__form-button auth__form-button-yandex">
                <img
                  src="https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg"
                  alt="yandex"
                  height="30px"
                />
                Войти с Яндекс ID
              </button>
            </YandexLogin>
          </form>
        </div>
      </div>
    </section>
  );
};
