import Images1 from "../../../assets/feedback/1.jpg";
import Images2 from "../../../assets/feedback/2.jpg";
import Images3 from "../../../assets/feedback/3.jpg";
import Images4 from "../../../assets/feedback/4.jpg";
import Images5 from "../../../assets/feedback/5.jpg";
import Images6 from "../../../assets/feedback/6.jpg";
import { Helmet } from "react-helmet-async";
import "./FeedBackPage.scss";

const FeedBackPage = () => {
  return (
    <>
      <Helmet>
        <title>Отзывы о Твоя Уборка – нам очень важна ваша оценка</title>
        <meta
          name="description"
          content="Ознакомьтесь с отзывами наших клиентов и делитесь своим мнением о наших услугах! Оставьте свой отзыв о наших услугах –это поможет нам стать лучше!"
        />
      </Helmet>
      <div className="landing__container">
        <div className="benefits__title">Средняя оценка за уборку - 5</div>
        <div className="feedback-page">
          <div>
            <img src={Images1} alt="" width={260} />
          </div>
          <div>
            <img src={Images2} alt="" width={260} />
          </div>
          <div>
            <img src={Images3} alt="" width={260} />
          </div>
          <div>
            <img src={Images4} alt="" width={260} />
          </div>
          <div>
            <img src={Images5} alt="" width={260} />
          </div>
          <div>
            <img src={Images6} alt="" width={260} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBackPage;
