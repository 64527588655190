import { Link } from "react-router-dom";
import "./LinksCalculator.scss";

const LinksCalculator = () => {
  return (
    <ul className="links-calculator">
      <li>
        <Link to="/calculator">ТвояУборка</Link>
      </li>
    </ul>
  )
}

export default LinksCalculator;