import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAuth, sendCode } from '../../../redux/slices/auth'
import { useNavigate } from 'react-router-dom'
import CloseButton from '../../../assets/modal-close.svg'
import { YandexLogin } from '../../yandex'

const clientID = '38fad74eb4444c649c22b27c1a04f3ac'

const Sign = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [sign, setSign] = useState(false)
  const [phone, setPhone] = useState(false)
  const { isPhoneChecked, isSendingCode, idCode } = useSelector(
    (state) => state.auth
  )
  const [authCode, setAuthCode] = useState('')

  const [isOpen, setIsOpen] = useState(false)
  const [isPhone, setIsPhone] = useState(false)

  const loginSuccess = (userData) => {}
  const handleSign = () => {
    setSign(!sign)
  }

  const handlePhone = () => {
    setPhone(!phone)
    setSign(!sign)
    window.location.reload()
  }

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
  })

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value)
  }

  // const [modalPhone, setModalPhone] = useState(false)
  const handleSendCodePhoneRegister = () => {
    dispatch(sendCode(getValues('login')))
    // setModalPhone(true)
  }

  const handleSendCodePhone = () => {
    dispatch(sendCode(getValues('login')))
    setIsOpen(true)
    handleOpenPhone()
  }

  const handleOpenPhone = () => {
    setIsPhone(true)
  }

  const onSubmit = async (values) => {
    const dataSend = {
      ...values,
      code: authCode,
      idCode,
    }

    const data = await dispatch(fetchAuth(dataSend))
    window.localStorage.setItem('formClose', false)
    window.localStorage.setItem('token', data.payload.accessToken)

    if (!data.payload) {
      return alert('Не удалось авторизоваться!')
    }

    if (data.payload) {
      window.localStorage.setItem('token', data.payload.accessToken)
      navigate('/main')
    }
  }

  return (
    <>
      <div onClick={handleSign} className="nav__login-button">
        Войти
      </div>
      {sign && (
        <div>
          <div onClick={handleSign} className="nav__sign-modal-wrapper"></div>
          <div>
            <div
              onClick={handlePhone}
              className="nav__sign-modal-wrapper"
            ></div>
            <div className="nav__sign-modal">
              <button onClick={handlePhone} className="nav__sign-modal-close">
                <img src={CloseButton} alt="close" />
              </button>
              <div className="nav__sign-modal-title">Вход</div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {!isSendingCode ? (
                  <>
                    <div
                      className="nav__sign-modal-button"
                      style={{ textAlign: 'center' }}
                      onClick={handleSendCodePhoneRegister}
                    >
                      Войти по номеру телефона
                    </div>
                    <YandexLogin clientID={clientID} onSuccess={loginSuccess}>
                      <button className="nav__sign-modal-input nav__sign-modal-input-yandex">
                        <img
                          src="https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg"
                          alt="yandex"
                          height="30px"
                        />
                        Войти с Яндекс ID
                      </button>
                    </YandexLogin>
                  </>
                ) : null}
                {/* 
                {modalPhone && (
                  <>
                    <button
                      onClick={() => setModalPhone(false)}
                      className="nav__sign-modal-close"
                    >
                      <img src={CloseButton} alt="close" />
                    </button>
                    <p className="modal-btn-mobile">
                      Введите Ваш номер телефона
                    </p>
                    <input
                      className="nav__sign-modal-input"
                      type="number"
                      {...register('login', { required: 'Укажите телефон' })}
                      placeholder="7 (___) ___ __ __"
                    />
                    <a
                      onClick={handleSendCodePhone}
                      className="nav__sign-modal-button"
                    >
                      Продолжить
                    </a>
                  </>
                )} */}

                {isSendingCode && !isPhone ? (
                  <>
                    <p className="modal-btn-mobile">
                      Введите Ваш номер телефона
                    </p>
                    <input
                      className="nav__sign-modal-input"
                      type="number"
                      {...register('login', { required: 'Укажите телефон' })}
                      placeholder="7 (___) ___ __ __"
                    />
                    <a
                      onClick={handleSendCodePhone}
                      className="nav__sign-modal-button"
                    >
                      Продолжить
                    </a>
                  </>
                ) : null}
                {isOpen ? (
                  <>
                    <p className="modal-btn-mobile">
                      Вам на телефон был отправлен код
                    </p>
                    <input
                      className="nav__sign-modal-input"
                      value={authCode}
                      onChange={handleAuthCode}
                      placeholder="Введите код"
                    />
                    <button type="submit" className="nav__sign-modal-button">
                      Войти
                    </button>
                  </>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Sign
