import { useState } from "react";
import Supporting1 from "../../../../assets/questionsOrder/Supporting1.jpg";
// import Supporting2 from '../../../../assets/questionsOrder/Supporting2.jpg'
import General1 from "../../../../assets/questionsOrder/General1.jpg";
import General2 from "../../../../assets/questionsOrder/General2.jpg";
// import AfterRepair1 from '../../../../assets/questionsOrder/AfterRepair1.jpg'
// import AfterRepair2 from '../../../../assets/questionsOrder/AfterRepair2.jpg'
// import Arrow from '../../../../assets/arrow-landing.svg'
import { Link } from "react-router-dom";
import {
  supportingRoomData,
  generalRoomData,
  afterRepairRoomData,
} from "../../../../data";
import { motion } from "framer-motion";
import { UpAnimation, OpacityAnimation } from "../../../../animations";
import "./QuestionsOrder.scss";

const QuestionsOrderTab = () => {
  const [imageSrc, setImageSrc] = useState(Supporting1);
  const [selectedButton, setSelectedButton] = useState("Поддерживающая");
  const [selectedRoom, setSelectedRoom] = useState("Комната");

  // const handleButtonClick = (button) => {
  //   setSelectedButton(button)
  //   setSelectedRoom('Комната')
  // }

  const handleButtonClick = button => {
    setSelectedButton(button);

    // Установите изображение в зависимости от выбранной вкладки
    switch (button) {
      case "Поддерживающая":
        setImageSrc(Supporting1);
        break;
      case "Генеральная":
        setImageSrc(General1);
        break;
      case "После ремонта":
        setImageSrc(General2);
        break;
      default:
        setImageSrc(Supporting1);
        break;
    }
  };

  const handleRoomClick = room => {
    setSelectedRoom(room);
  };

  const renderCleaningList = () => {
    const selectedData = selectedButton
      ? {
          Поддерживающая: supportingRoomData,
          Генеральная: generalRoomData,
          "После ремонта": afterRepairRoomData,
        }[selectedButton]
      : null;

    return selectedData && selectedRoom ? selectedData[selectedRoom] : null;
  };

  const cleaningList = renderCleaningList();

  return (
    <>
      <div>
        <div className="questions__order-tabs">
          <div
            className={`questions__order-tab ${
              selectedButton === "Поддерживающая"
                ? "questions__order-tab-active"
                : ""
            }`}
            onClick={() => handleButtonClick("Поддерживающая")}>
            Поддерживающая
          </div>
          <div
            className={`questions__order-tab ${
              selectedButton === "Генеральная"
                ? "questions__order-tab-active"
                : ""
            }`}
            onClick={() => handleButtonClick("Генеральная")}>
            Генеральная
          </div>
          <div
            className={`questions__order-tab ${
              selectedButton === "После ремонта"
                ? "questions__order-tab-active"
                : ""
            }`}
            onClick={() => handleButtonClick("После ремонта")}>
            После ремонта
          </div>
        </div>

        <div className="questions__order-tab-content">
          <div className="questions__order-tab-imgs">
            <img className="questions__order-tab-img" src={imageSrc} alt="" />
            <Link to={"/calculator"} className="questions__order-button">
              Заказать уборку
            </Link>
          </div>
          <div className="questions__order-tab-content-text">
            <div className="questions__order-accrodion-container">
              <div className="questions__order-tab-content-accrodion-title">
                {selectedButton &&
                  Object.keys(supportingRoomData).map((room, index) => (
                    <div
                      key={index}
                      className={`questions__order-tab ${
                        selectedRoom === room
                          ? "questions__order-tab-active"
                          : ""
                      }`}
                      onClick={() => handleRoomClick(room)}>
                      {room}
                    </div>
                  ))}
              </div>

              {cleaningList && (
                <div className="questions__order-accrodion-wrapper">
                  {cleaningList.map((item, index) => (
                    <div
                      key={index}
                      className="questions__order-accrodion-wrapper-text">
                      <span>{index + 1}</span> {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsOrderTab;
