import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarNotification from "../../assets/calendarNotification.svg";

const TimetableBlock = memo(() => {
  const [cleaningInfo, setCleaningInfo] = useState(null);
  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    if (!order || !order.items || !Array.isArray(order.items)) {
      console.error(
        "Expected order.items to be an array, but received:",
        typeof order.items
      );
      return;
    }

    const now = new Date();
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    // Фильтрация уборок, чтобы найти ближайшую (сегодня или завтра)
    const upcomingCleaning = order.items.find(item => {
      if (!item.date) return false;
      const cleaningDate = new Date(item.date);
      // Если уборка сегодня
      if (cleaningDate >= todayStart && cleaningDate <= todayEnd) {
        return true;
      }
      // Если уборка завтра
      if (
        cleaningDate.getDate() === tomorrow.getDate() &&
        cleaningDate.getMonth() === tomorrow.getMonth() &&
        cleaningDate.getFullYear() === tomorrow.getFullYear()
      ) {
        return true;
      }
      return false;
    });

    // Если нашли уборку на сегодня или завтра, сохраняем её в состояние
    if (upcomingCleaning) {
      setCleaningInfo(upcomingCleaning);
    } else {
      setCleaningInfo(null);
    }
  }, [order]);

  // Если cleaningInfo ещё не загружен или уборок нет, ничего не отображаем
  if (!cleaningInfo || !cleaningInfo.date) return null;

  const cleaningDate = new Date(cleaningInfo.date);
  if (isNaN(cleaningDate.getTime())) return null;

  const hours = cleaningDate.getHours().toString().padStart(2, "0");
  const minutes = cleaningDate.getMinutes().toString().padStart(2, "0");

  // Проверяем, если дата уборки сегодня
  const isToday = cleaningDate.toDateString() === new Date().toDateString();
  const isTomorrow =
    cleaningDate.getDate() === new Date().getDate() + 1 &&
    cleaningDate.getMonth() === new Date().getMonth() &&
    cleaningDate.getFullYear() === new Date().getFullYear();

  return (
    <div className="timetable">
      <img src={CalendarNotification} alt="calendar" />
      <span className="timetable__title">Расписание</span>
      <span className="timetable__text">
        {isToday ? (
          <>
            Сегодня, в {hours}:{minutes}, запланирована уборка
          </>
        ) : isTomorrow ? (
          <>
            Завтра, в {hours}:{minutes}, запланирована уборка
          </>
        ) : (
          <>
            Уборка запланирована на {cleaningDate.toLocaleDateString()} в{" "}
            {hours}:{minutes}
          </>
        )}
      </span>
    </div>
  );
});

export default TimetableBlock;
