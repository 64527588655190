export const UpAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

export const OpacityAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}
