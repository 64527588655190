import Tabs from "../../../components/client/tabs/Tabs";
import AvatarBig from "../../../assets/avatar-big.svg";
// import CabinetImg from "../../../assets/sale8.jpg";

import { Link, Navigate } from "react-router-dom";
// import SliderClient from '../../../components/client/slider/SliderClient'
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuth } from "../../../redux/slices/auth";
import { fetchUserInfo } from "../../../redux/slices/user";
import { useEffect, memo, useState } from "react";
import { fetchInfoOrder } from "../../../redux/slices/order";
import "./Main.scss";
import TimetableBlock from "../../../components/timetable-block/TimetableBlock";

const Main = memo(() => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInfoOrder());
  }, [dispatch]);

  if (!window.localStorage.getItem("token") && !isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="main__bg">
        <TimetableBlock />
        <section className="main__wrapper">
          <div className="main__header-info">
            <div className="main__header-info-user">
              <img
                src={user.items.avatar ? user.items.avatar : AvatarBig}
                alt="avatar"
              />
              <div className="main__header-info-container">
                {user.items.name ? (
                  <div className="main__header-info-name">
                    Здравствуйте, {user.items.name} !
                  </div>
                ) : (
                  <></>
                )}
                <div className="main__header-info-balance-container">
                  {user.items.balance ? (
                    <span>
                      <span className="main__header-info-balance-text">
                        Баланс:
                      </span>
                      <span className="main__header-info-balance">
                        {user.items.balance} ₽
                      </span>
                    </span>
                  ) : (
                    <span className="main__header-info-balance-text">
                      Баланс : 0 р
                    </span>
                  )}
                  <Link
                    to="/balance"
                    className="main__header-info-balance-button">
                    Пополнить
                  </Link>
                </div>
                <div className="main__header-info-balance-container">
                  {user.items.bonusBalance ? (
                    <span>
                      <span className="main__header-info-balance-text">
                        Бонусы:
                      </span>
                      <span className="main__header-info-balance">
                        {user.items.bonusBalance}
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            {/* <div>
            <SliderClient />
            <img
              src={CabinetImg}
              width={320}
              alt="Промо-код"
              className="main__wrapper-promo"
            />
          </div> */}
          </div>
          <Tabs />
        </section>
      </div>
    </>
  );
});

export default Main;
