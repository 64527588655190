import { Calendar } from "react-multi-date-picker"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSingleDate } from "../../../../redux/slices/calculator";

const SingleCalendar = () => {
  const { dates: stateDates } = useSelector(({ calculator }) => calculator);

  let [value, setValue] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    const date = new Date(stateDates[0].year, stateDates[0].month - 1, stateDates[0].day, stateDates[0].hours, stateDates[0].minutes)
    setValue(date)
  }, [stateDates])

  function handleChange(value) {
    const data = {
      day: value.day,
      month: value.month.number,
      year: value.year
    }
    dispatch(changeSingleDate(data))
    setValue(value)
  }
  return (
    <>
      <Calendar
        weekDays={['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']} // кастомизация недели // начинается с воскресенья
        months={[
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',]}
        weekStartDayIndex={1}
        className='calendar__services'
        value={value}
        minDate={new Date()} // минимальная дата сегодня
        onChange={handleChange}
      />
    </>
  );
};

export default SingleCalendar;