// import { motion } from "framer-motion";
// import { UpAnimation } from "../../../../animations";
import FeedbackSlider from "./FeedbackSlider";
import { Link } from "react-router-dom";
import BeforeAfter from "../../../../components/beforeAfter/BeforeAfter";

const Feedback = () => {
  return (
    <div className="benefits__wrapper feedback__wrapper">
      <div
        className="landing__container feedback__wrapper-content"
        style={{
          display: "flex",
        }}>
        <div className="feedback__container-wrapper">
          <div className="benefits__title">Средняя оценка за уборку - 5</div>
          <div className="feedback__container">
            <div className="feedback__container-slider">
              <FeedbackSlider />
              <Link to={"/feedback"} className="feedback__link">
                Посмотреть все отзывы
              </Link>
            </div>
          </div>
          <div className="feedback__container-yandex">
            <div
              className="title"
              style={{
                justifyContent: "center",
              }}>
              Оставь свой отзыв на Яндексе
            </div>
            <a
              href="https://yandex.ru/maps/org/tvoyauborka/157082417183/reviews/?ll=69.018769%2C47.945453&tab=reviews&z=2"
              className="feedback__link feedback__link-yandex">
              <img
                src="https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg"
                alt="yandex"
                height="30px"
              />{" "}
              Оставить отзыв
            </a>
          </div>
        </div>
        <BeforeAfter />
      </div>
    </div>
  );
};

export default Feedback;
