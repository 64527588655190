import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAuth, selectIsAuth } from "../redux/slices/auth";
import Landing from "../Pages/Client/landing/Landing";
import Layout from "../Pages/Client/Layout";
import Main from "../Pages/Client/main/Main";
import Balance from "../Pages/Client/balance/Balance";
import Timetable from "../Pages/Client/timetable/Timetable";
import Error from "../Pages/error/Error";
import Policy from "../Pages/Client/policy/Policy";
import Agreement from "../Pages/Client/agreement/Agreement";
import CalculatorPage from "../Pages/Client/calculator-page/CalculatorPage";
import Detailed from "../Pages/Client/detailed/Detailed";
import Prices from "../Pages/Client/prices/Prices";
import Payment from "../Pages/Client/Payment";
import CalculatorOrder from "../Pages/Client/calculator-order/CalculatorOrder";
import YandexLoginRedirect from "../Pages/Client/YandexLoginRedirect";
import FeedBackPage from "../Pages/Client/feedBack/FeedBackPage";
import Office from "../Pages/Office/Office";
import ThanksPage from "../Pages/Client/thanks-page/ThanksPage";
import CalculatorPageFurniture from "../Pages/Client/calculator-page-furniture/CalculatorPageFurniture";
import CleaningPage from "../Pages/Client/cleaning-page/CleaningPage";
import WarehousePage from "../Pages/Client/warehouse-page/WarehousePage";
import { Auth } from "../Pages/Client/auth/Auth";
import { Sitemap } from "../Pages/Sitemap";
import { HelmetProvider } from "react-helmet-async";
import { Contacts } from "../Pages/Contacts/Contacts";
import { UborkaPomesheniy } from "../Pages/UborkaPomesheniy/UborkaPomesheniy";
import { Uslugi } from "../Pages/Uslugi/Uslugi";
import { UborkaKvartir } from "../Pages/UborkaKvartir/UborkaKvartir";
import { UborkaDomov } from "../Pages/UborkaDomov/UborkaDomov";
import { AboutUs } from "../Pages/AboutUs/AboutUs";
import { WorkUs } from "../Pages/WorkUs/WorkUs";
import { ClearinghouseRegistration } from "../Pages/ClearinghouseRegistration/ClearinghouseRegistration";

const Routers = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    dispatch(fetchAuth());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="" element={<Layout />}>
          {/* Лендинг */}
          <Route path="/" element={<Landing />} />
          <Route path="/calculator" element={<CalculatorPage />} />
          <Route
            path="/calculator-furniture"
            element={<CalculatorPageFurniture />}
          />
          <Route
            path="/uslugi/uborka-pomesheniy/warehouse/"
            element={<WarehousePage />}
          />
          <Route path="/cleaning" element={<CleaningPage />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/detailed" element={<Detailed />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="*" element={<Error />} />
          <Route path="/feedback" element={<FeedBackPage />} />
          <Route
            path="/uslugi/uborka-pomesheniy/office/"
            element={<Office />}
          />
          <Route
            path="/uslugi/uborka-pomesheniy/"
            element={<UborkaPomesheniy />}
          />
          <Route path="/uslugi/uborka-kvartir" element={<UborkaKvartir />} />
          <Route path="/uslugi/uborka-domov" element={<UborkaDomov />} />
          <Route path="/uslugi" element={<Uslugi />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/work-with-us" element={<WorkUs />} />
          <Route
            path="/clearinghouse-registration"
            element={<ClearinghouseRegistration />}
          />

          <Route path="/thanks" element={<ThanksPage />} />
          <Route path="/karta-sayta" element={<Sitemap />} />
          <Route path="/contacts" element={<Contacts />} />
          {/* Личный кабинет */}
          <Route path="/main" element={<Main />} />
          <Route path="/balance" element={<Balance />} />
          <Route path="/timetable" element={<Timetable />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/yaoauth" element={<YandexLoginRedirect />} />
          <Route path="/payment-order" element={<CalculatorOrder />} />

          {/* Редиректы на главную страницу */}
          <Route path="/index.php" element={<Navigate to="/" />} />
          <Route path="/index.htm" element={<Navigate to="/" />} />
          <Route path="/home.php" element={<Navigate to="/" />} />
          <Route path="/home.htm" element={<Navigate to="/" />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/index/" element={<Navigate to="/" />} />

          {/* Редирект для URL с лишними слешами */}
          <Route path="/:extraSlashes/*" element={<Navigate to="/" />} />
          <Route path="/:extraSlashes" element={<Navigate to="/" />} />

          {/* Редирект для URL с query параметрами */}
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/?" element={<Navigate to="/" />} />
        </Route>
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </HelmetProvider>
  );
};

export default Routers;
