import { motion } from "framer-motion";
// import { UpAnimation, OpacityAnimation } from '../../../../animations'
import { BenefistItems } from "../../../../data";
// import Questions from '../questions/Questions'
// import { Link } from 'react-router-dom'
import { memo } from "react";

const Benefist = memo(() => {
  return (
    <div className="benefits__wrapper">
      <div className="landing__container">
        {/* <motion.div
          custom={3}
          variants={UpAnimation}
          className="benefits__title"
        >
          Преимущества работы с нами
        </motion.div> */}
        <div className="benefits">
          {/* <motion.div
            custom={3}
            variants={OpacityAnimation}
            transition={{
              delay: 0.3,
            }}
          >
            <Link to="/calculator" className="calculate__the-cost">
              Рассчитать стоимость
            </Link>
            <div className="benefits__more">
              <div className="benefits__more-title">
                Особые условия для корпоративных клиентов
              </div>
              <Link className="benefits__more-button" to="/detailed">
                Подробнее
              </Link>
            </div>
          </motion.div> */}
          <ul className="benefits__items">
            {BenefistItems.map((item, index) => (
              <li custom={index + 2} key={index}>
                <div className="benefits__item-img">
                  <img src={item.img} alt="icon" />
                </div>
                <div className="benefits__item-text-container">
                  <div>{item.title}</div>
                  <div className="benefits__item-text">{item.text}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <Questions /> */}
      </div>
    </div>
  );
});

export default Benefist;
