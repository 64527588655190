import { Calendar } from "react-multi-date-picker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSingleDate,
  changeTimes,
} from "../../../../redux/slices/calculator";
import TimeInputs from "./TimeInputs"; // Импортируем ваш компонент для выбора времени
import "./Calendar.scss";

const SingleCalendar = () => {
  const { dates: stateDates } = useSelector(({ calculator }) => calculator);
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    if (stateDates && stateDates.length > 0) {
      const date = new Date(
        stateDates[0].year,
        stateDates[0].month - 1,
        stateDates[0].day,
        stateDates[0].hours || 0,
        stateDates[0].minutes || 0
      );
      setValue(date);
    }
  }, [stateDates]);

  // Обработчик изменения даты
  function handleChange(value) {
    const data = {
      day: value.day,
      month: value.month.number,
      year: value.year,
    };
    dispatch(changeSingleDate(data)); // Обновляем дату в Redux
    setValue(value);
  }

  // Обработчик изменения времени
  function handleTimeChange(hours, minutes) {
    if (stateDates && stateDates.length > 0) {
      const date = stateDates[0];
      dispatch(
        changeTimes({
          year: date.year,
          month: date.month,
          day: date.day,
          hours,
          minutes,
        })
      );
    }
  }

  return (
    <div className="calendar__wrapper">
      <Calendar
        weekDays={["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]}
        months={[
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ]}
        weekStartDayIndex={1}
        className="calendar__services"
        value={value}
        minDate={new Date()}
        onChange={handleChange}
      />

      {/* Время теперь выбираем через TimeInputs */}
      <TimeInputs date={stateDates[0]} callBack={handleTimeChange} />
    </div>
  );
};

export default SingleCalendar;
