import "./Furniclean.scss";

const Furniclean = () => {
  return (
    <div className="landing__container">
      <div className="furni-clean">
        <div className="benefits__title">Химчистка мебели</div>
        <ul className="furni-clean__items">
          <li>Убиваем бактерии</li>
          <li>Выводим запах</li>
          <li>Убираем пятна и загрязнения</li>
        </ul>
      </div>
      <div>
        <ul className="furni-clean__items">
          <li>
            <div>Оборудование</div>
            <div className="description">
              Мастер приедет с профессиональным оборудованием и химией
            </div>
          </li>
          <li>
            <div>Безопасно</div>
            <div className="description">
              Моющие средства полностью безопасны для людей и живытных
            </div>
          </li>
          <li>
            <div>Опытные мастера</div>
            <div className="description">
              Перед чисткой, мастер проведет необходимые тесты и ответит на все
              вопросы
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Furniclean;
