import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BenefistSale from "../Client/landing/benefistSale/BenefistSale";
import Benefist from "../Client/landing/benefist/Benefist";
import QuestionsOrder from "../Client/landing/questionsOrder/questionsOrder";
import Feedback from "../Client/landing/feedback/Feedback";
import Sales from "../Client/landing/sales/Sales";
import OurEquipment from "../../components/our-equipment/OurEquipment";
import Questions from "../Client/landing/questions/Questions";
import { Helmet } from "react-helmet-async";

export const UborkaKvartir = () => {
  const tabs = [
    {
      title: "Поддерживающая",
      content: "Поддерживающая уборка от 2520 ₽",
    },
    {
      title: "Генеральная",
      content: "Генеральная уборка от 5253 ₽",
    },
    {
      title: "После ремонта",
      content: "После ремонта уборка от 7000 ₽",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };

  return (
    <>
      <Helmet>
        <title>Заказать уборку квартиры в Москве – цена на клининг</title>
        <meta
          name="description"
          content="Мы предлагаем широкий спектр услуг по уборке квартир в Москве. Мы используем только качественные средства и оборудование, чтобы гарантировать вам чистоту."
        />
      </Helmet>
      <section style={{ overflow: "hidden" }}>
        <div className="landing__main">
          <div className="landing__container service-list-container">
            <ul className="service-list">
              <li>
                <Link to="/uslugi/uborka-kvartir">
                  <div>Уборка Квартиры</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/office/">
                  <div>Уборка офиса</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                  <div>Склад</div>
                </Link>
              </li>
              <li>
                <Link to="/cleaning">
                  <div>Химчистка</div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="landing__container ">
            <ul className="service-list">
              {tabs.map((tab, index) => (
                <li key={index} onClick={() => handleTabClick(index)}>
                  <div
                    className={`${
                      activeTab === index ? "service-list__active" : ""
                    }`}>
                    {tab.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="landing__wrapper-form">
          <div className="landing__container landing__container-tab">
            <div></div>

            {activeTab !== -1 ? (
              <div className="landing__main-form landing__main-form-tab">
                <div className="landing__form">
                  <div className="landing__form-title">
                    {tabs[activeTab].content}
                  </div>
                  <Link
                    to={"/calculator"}
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}>
                    Заказать уборку квартиры
                  </Link>
                  <div className="landing__form-border">
                    <a
                      href="https://yandex.ru/business/widget/request/company/157082417183"
                      target="_blank"
                      className="landing__form-button"
                      style={{
                        textDecoration: "none",
                      }}
                      rel="noreferrer">
                      Перезвонить мне
                    </a>
                  </div>
                  <div className="landing__promo">
                    <div
                      className="promo"
                      ref={promoRef}
                      onClick={copyToClipboard}>
                      First
                    </div>
                    <div className="title">
                      <strong>Скидка 8%</strong> на первый заказ{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="landing__main">
          <BenefistSale />
          <Benefist />
          <QuestionsOrder />
          <Feedback />

          <OurEquipment />
          <Sales />
        </div>
        <div className="benefits__wrapper">
          <div className="landing__container">
            <Questions />
          </div>
        </div>
        <div className="landing__container" style={{ padding: "22px 0" }}>
          <h1>
            Уборка квартиры в Москве с сервисом «Твоя уборка»: новый стандарт
            чистоты и комфорта
          </h1>
          <p>
            Жизнь в большом городе диктует свои условия: динамичный ритм,
            плотный график, бесконечные задачи и стремление к идеальному порядку
            в доме. Однако, как часто мы сталкиваемся с тем, что времени на
            уборку квартиры просто не хватает? Пыль, грязь, разбросанные вещи —
            все это накапливается, создавая дискомфорт и ощущение беспорядка. Но
            что, если уборка квартиры перестанет быть рутинной обязанностью и
            станет простым и удобным решением? Здесь на помощь приходит
            профессиональный клининг квартиры от компании «Твоя уборка».
          </p>
          <h2>Преимущества клининговой уборки квартиры с «Твоя уборка»</h2>
          <p>
            Сервис «Твоя уборка» — это не просто услуга уборки, это новый
            стандарт чистоты и заботы о вашем доме. Мы предлагаем широкий спектр
            услуг, которые подходят как для небольших квартир, так и для
            просторных апартаментов. Для нас важно, чтобы каждый клиент
            оставался доволен результатом, поэтому мы используем только
            безопасные и экологичные средства, которые не нанесут вреда вашему
            здоровью и окружающей среде.
          </p>
          <p>
            Сделать уборку в квартире с нашей службой — это значит получить
            гарантированный результат. Мы знаем, как важно доверять тем, кто
            приходит в ваш дом, поэтому уделяем особое внимание безопасности и
            порядочности наших сотрудников.
          </p>
          <h2>Виды уборки квартиры с «Твоя уборка»</h2>
          <p>
            Мы понимаем, что каждая квартира и каждая ситуация уникальны,
            поэтому предлагаем различные виды уборки, чтобы максимально
            удовлетворить потребности наших клиентов. Уборка квартиры может
            быть:
          </p>
          <ul>
            <li>
              Поддерживающей: это регулярная клининговая уборка квартиры,
              направленная на поддержание идеального порядка в доме. Наши
              специалисты уберут пыль, пропылесосят ковры, помоют полы и
              выполнят все необходимые работы для того, чтобы ваш дом всегда
              оставался чистым и уютным.
            </li>
            <li>
              Генеральной: иногда требуется более глубокая и тщательная уборка
              квартиры, которая включает в себя очистку всех поверхностей,
              включая труднодоступные места, мытье окон, чистку мягкой мебели и
              ковров.
            </li>
            <li>
              После ремонта: уборка новой квартире после ремонта требует особого
              подхода и профессионализма. Строительная пыль, следы от краски и
              других материалов — все это требует специализированных средств и
              оборудования.
            </li>
          </ul>
          <h3>Как заказать уборку квартиры в Москве?</h3>
          <p>
            Чтобы заказать клининговую уборку в Москве вы можете оставить заявку
            на нашем сайте или связаться с нашими операторами по телефону. Мы
            предлагаем гибкий график работы, что позволяет выбрать удобное время
            для уборки. Кроме того, мы учитываем все ваши пожелания и
            особенности квартиры, чтобы сделать уборку максимально эффективной.
          </p>
          <p>
            Узнать стоимость уборки квартиры можно на нашем сайте или при
            обращении к нашим менеджерам.
          </p>
        </div>
      </section>
    </>
  );
};
