import { useDispatch, useSelector } from "react-redux";
import { addProduct, minusItem } from "../../../../redux/slices/calculator";
import {
  servicesDataMoscow,
  servicesDataSaintPeterburg,
} from "../../../../data";
import CalculatorCalendar from "../calculator-calendar/CalculatorCalendar";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { fetchOrderCalculator } from "../../../../redux/slices/calculator";
import { HiOutlineCursorClick } from "react-icons/hi";
import { createPortal } from "react-dom";

const CalculatorAddServices = () => {
  const [openInputs, setOpenInputs] = useState({});
  const inputPickup = useRef(null);
  const { items } = useSelector(({ calculator }) => calculator);
  const { city } = useSelector(state => state.city);
  const dispatch = useDispatch();

  const City = () => {
    if (
      city &&
      city.selected &&
      city.cities[city.selected] === "Санкт-Петербург"
    ) {
      return servicesDataSaintPeterburg;
    } else {
      return servicesDataMoscow;
    }
  };

  const onClickAdd = index => {
    // Получите выбранный элемент
    const selectedCity = City();
    // Получите выбранный элемент по индексу
    const selectedItem = selectedCity[index];

    // Проверьте, существует ли элемент в корзине и количество меньше 8
    const existingItem = items.find(item => item.id === selectedItem.id);
    if (existingItem && existingItem.count >= 8) {
      // Если элемент уже существует и его количество равно или больше 8, не допускайте добавления
      return;
    }

    if (selectedItem.count < 8) {
      const item = {
        id: selectedItem.id,
        name: selectedItem.name,
        price: selectedItem.price,
        count: selectedItem.count + 1,
      };
      dispatch(addProduct(item));
    }
  };

  const onClickremove = id => {
    dispatch(minusItem(id));
  };

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleOpenInputs = (event, itemId) => {
    event.stopPropagation();
    if (itemId === 6)
      setOpenInputs(prevState => ({
        ...prevState,
        [itemId]: !prevState[itemId],
      }));
  };

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      pickup: "",
      clarify: false,
    },
  });

  const onSubmit = async values => {
    const data = await dispatch(fetchOrderCalculator(values));
    reset();
    return data;
  };

  const onSubmitWhereKey = (values, itemId) => {
    const selectedCity = City();
    const selectedItem = selectedCity[itemId];
    const item = {
      id: selectedItem.id,
      name: selectedItem.name,
      price: selectedItem.price,
      count: 1,
    };

    if (values.clarify) {
      item.clarify = values.clarify;
      item.name = selectedItem.name + " (уточнить у Вас)";
    } else {
      item.pickup = values.pickup ? values.pickup : "";
      item.name = selectedItem.name + " (" + values.pickup + ")";
    }
    dispatch(addProduct(item));
  };

  const [openServices, setOpenServices] = useState(false);

  const handleOpenServices = () => {
    setOpenServices(!openServices);
  };

  const handleOpenModalInfo = itemId => {
    setSelectedItemId(itemId);
    setOpenModalInfo(true);
  };

  return (
    <div className="additional-services">
      <div className="additional-services__title additional-services__title-mob-off">
        Дополнительные услуги
        <span className="additional-services__title__mobile-icon">
          <HiOutlineCursorClick />
        </span>
      </div>
      <div
        className={
          openServices
            ? `additional-services__title additional-services__title__mobile`
            : `additional-services__title__mobile-open`
        }
        onClick={handleOpenServices}>
        Дополнительные услуги
        <span className="additional-services__title__mobile-icon">
          <HiOutlineCursorClick />
        </span>
      </div>
      <span className={openServices ? `` : `additional-services__mobile`}>
        <ul className="additional-services__items">
          {City().map((item, index) => (
            <li
              className="additional-services__item"
              key={item.id}
              onClick={e => handleOpenInputs(e, item.id)}>
              <div>
                {item.inputs ? (
                  <>
                    {openInputs[item.id] && (
                      <div className="additional-services__modal">
                        <div>
                          <input
                            className="additional-services__input-modal"
                            type="text"
                            {...register("pickup", {
                              required: "Откуда забрать",
                            })}
                            ref={inputPickup}
                            onClick={e => e.stopPropagation()}
                            placeholder="Введите адрес"
                          />
                          <button
                            className="additional-services-add-button"
                            onClick={e => {
                              onSubmitWhereKey(
                                { pickup: inputPickup.current.value },
                                item.id
                              );
                            }}>
                            +
                          </button>
                          <br />
                          <span style={{ color: "#fff" }}>или</span>
                          <br />
                          <button
                            className="additional-services__button-modal"
                            onClick={e => {
                              onSubmitWhereKey({ clarify: true }, item.id);
                            }}>
                            Уточнить у Вас
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
                <div className="additional-services__item-text-main">
                  <div className="additional-services__item-text">
                    {item.name}
                    {item.imgInfo ? (
                      <span>
                        <span onClick={() => handleOpenModalInfo(item.id)}>
                          {item.imgInfo}
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="additional-services__item-text">
                    {item.price === 0 ? "" : `${item.price}₽`}
                    {item.additionText}
                  </div>
                </div>
                <img src={item.img} alt={item.name} />
                {!item.inputs ? (
                  <div className="additional-services-add-buttons">
                    <button
                      onClick={() => onClickremove(index)}
                      className="additional-services-add-button">
                      -
                    </button>
                    <div className="additional-services-add-button-count">
                      {items.map(item => {
                        if (item.id === City()[index].id) {
                          return item.count;
                        }
                        return null;
                      })}
                    </div>
                    <button
                      onClick={() => onClickAdd(index)}
                      className="additional-services-add-button">
                      +
                    </button>
                  </div>
                ) : null}
              </div>
              {createPortal(
                <>
                  {openModalInfo && selectedItemId === item.id ? (
                    <div onClick={() => handleOpenModalInfo(false)}>
                      <div
                        className="nav__sign-modal-wrapper"
                        style={{
                          zIndex: 9999,
                        }}></div>
                      <div className="nav__sign-modal">
                        <div
                          className="nav__sign-modal-close"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginBottom: "32px",
                          }}>
                          x
                        </div>
                        <div>{item.textInfo}</div>
                      </div>
                    </div>
                  ) : null}
                </>,
                document.body
              )}
            </li>
          ))}
        </ul>
      </span>

      <div>
        <CalculatorCalendar />
      </div>
    </div>
  );
};

export default CalculatorAddServices;
