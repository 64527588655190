import { useEffect } from 'react';
const Detailed = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="landing__container">
      <div className="landing__additional-title">Наше предложение для корпоративных клиентов.</div>
      <div>
        <div>Кому мы можем быть полезны.</div>
        <div>Владельцам - Ресторанов, Шоу-румов, Офисов, Сдающие квартиры по суточно, и любого
          другого бизнеса.</div>
      </div>
      <br />
      <div>
        <div>Требования.</div>
        <div>Наличия Юридического Лица на выбор - (ИП или ООО) с расчетным счетом любого банка РФ.</div>
      </div>
      <br />
      <div>
        <div>Преимущества работы с нами.</div>
        <ul className="landing__additional-list">
          <li>1. Доступ в личный кабинет.</li>
          <li>2. Заключаем типовой договор на оказание услуг.</li>
          <li>3. Выставляем счет в личном кабинете или почтой.</li>
          <li>4. Поддержка личного менеджера 24 / 7.</li>
          <li>5. Индивидуальная цена на услуги из расчета Тех / задания.</li>
          <li>6. Взаимодействие с организациями работающие в рамках 223 - ФЗ.</li>
        </ul>
      </div>
      <br />
      <div>
        <div>Как это работает.</div>
        <div>Согласно Тех/заданию - составляется смета - ( Согласования Договора. Подписания) - выставляем счет на оплату - оказание услуг.</div>
      </div>
      <br />
      <div>
        <div><strong>Внимание!!!</strong></div>
        <div>Организациям заключающие договор от 3 месяцев предусмотрена скидка в 30%. ( НА СМЕТУ)</div>
        <div>НЕ заключаем договор больше чем на 1 год. ( по оканчанию срока действия договора производим пролонгацию договора )</div>
      </div>
      <br />
      <div>
        <div> По любым другим вопросам.</div>
        <div>Вы можете связаться с нами по (НОМЕРУ)</div>
        <br />
        <div>С Уважением «ТвояУборка»</div>
        <br />
      </div>
    </section>
  )
}

export default Detailed;
