import { Link, Navigate } from "react-router-dom";
import "../Client/main/Main.scss";
import { useEffect, useRef, useState } from "react";
import Advantages from "./advantages/Advantages";
import CleaningOffice from "./cleaningOffice/CleaningOffice";
import Questions from "../Client/landing/questions/Questions";
import BenefistSale from "../Client/landing/benefistSale/BenefistSale";
import OurEquipment from "../../components/our-equipment/OurEquipment";
import { Helmet } from "react-helmet-async";

const Office = () => {
  const tabs = [
    {
      title: "Поддерживающая",
      content: "Уборка офиса от 2250 ₽",
    },
    {
      title: "Генеральная",
      content: "Генеральная уборка от 4900 ₽",
    },
    {
      title: "После ремонта",
      content: "После ремонта уборка от 7000 ₽",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = index => {
    setActiveTab(index);
  };

  const promoRef = useRef(null);

  const copyToClipboard = () => {
    const promoText = "offici";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (localStorage.getItem("token")) {
    return <Navigate to="/main" />;
  }
  return (
    <>
      <Helmet>
        <title>Заказать уборку офисов в Москве – ваш надежный клининг</title>
        <meta
          name="description"
          content="Закажите уборку офиса в Москве. Качественно, быстро и надежно от Твоя Уборка! Мы предлагаем услуги профессиональной уборки офисов в столице."
        />
      </Helmet>
      <section
        style={{
          overflow: "hidden",
        }}>
        <div className="landing__main">
          <div className="landing__container service-list-container">
            <ul className="service-list">
              <li>
                <Link to="/uslugi/uborka-kvartir">
                  <div>Уборка Квартиры</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/office/">
                  <div>Уборка офиса</div>
                </Link>
              </li>
              <li>
                <Link to="/uslugi/uborka-pomesheniy/warehouse/">
                  <div>Склад</div>
                </Link>
              </li>
              <li>
                <Link to="/cleaning">
                  <div>Химчистка</div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="landing__container">
            <ul className="service-list">
              {tabs.map((tab, index) => (
                <li key={index} onClick={() => handleTabClick(index)}>
                  <div
                    className={`${
                      activeTab === index ? "service-list__active" : ""
                    }`}>
                    {tab.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="landing__wrapper-form">
          <div className="landing__container landing__container-tab">
            <div></div>

            {activeTab !== -1 ? (
              <div className="landing__main-form landing__main-form-tab">
                <div className="landing__form">
                  <div className="landing__form-title">
                    {tabs[activeTab].content}
                  </div>
                  <Link
                    to={"/calculator"}
                    className="landing__form-button"
                    style={{
                      textDecoration: "none",
                    }}>
                    Заказать уборку офиса
                  </Link>
                  <div className="landing__form-border">
                    <a
                      href="https://yandex.ru/business/widget/request/company/157082417183"
                      target="_blank"
                      className="landing__form-button"
                      style={{
                        textDecoration: "none",
                      }}
                      rel="noreferrer">
                      Перезвонить мне
                    </a>
                  </div>
                  <div className="landing__promo">
                    <div
                      className="promo"
                      ref={promoRef}
                      onClick={copyToClipboard}>
                      offici
                    </div>
                    <div className="title">
                      <strong>Скидка 5%</strong> на первый заказ{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="landing__main">
          <BenefistSale />
          <CleaningOffice />
          <Advantages />
          <OurEquipment />
          <div className="benefits__wrapper">
            <div className="landing__container">
              <Questions />
            </div>
          </div>
        </div>
        <div className="landing__container" style={{ padding: "22px 0" }}>
          <h1>
            Уборка офисов в Москве: профессиональный подход от компании "Твоя
            уборка"
          </h1>
          <p>
            Чистота в офисе — это не просто вопрос эстетики, но и показатель
            профессионализма компании. Если офис сияет чистотой, сотрудники
            чувствуют себя комфортно, а клиенты — уверенно. Но как обеспечить
            идеальный порядок, не отвлекаясь от основного бизнеса? Ответ прост:
            доверьте это профессионалам. Компания "Твоя уборка" предлагает
            качественные услуги клининга офисов в Москве, гарантируя чистоту и
            порядок в вашем рабочем пространстве.
          </p>
          <h2>Почему уборка офисов в Москве важна?</h2>
          <p>
            Уборка офисов в Москве — это не только гигиена и порядок, но и
            забота о здоровье сотрудников. Пыль, грязь и микробы накапливаются в
            самых неожиданных местах: на столах, в вентиляции, в углах
            помещений. Эти невидимые враги могут вызывать аллергии, заболевания
            дыхательных путей и даже снижать общую работоспособность коллектива.
            В условиях большого города, где офисы находятся в плотной городской
            среде, профессиональный клининг становится не просто необходимостью,
            а обязанностью каждого ответственного работодателя.
          </p>
          <h2>
            Какие услуги клининга офисов в Москве предлагает "Твоя уборка"?
          </h2>
          <h3>Поддерживающая уборка офисов</h3>
          <p>
            Поддерживающая уборка офисов — это регулярное обслуживание, которое
            проводится ежедневно или по установленному графику. Ее главная цель
            — поддержание чистоты и порядка в офисе, чтобы сотрудники могли
            работать в комфортных условиях, а клиенты чувствовали себя
            приветливо.
          </p>
          <h3>Генеральная уборка офисов</h3>
          <p>
            Генеральная уборка офисов проводится реже, но она необходима для
            поддержания общего уровня чистоты и устранения загрязнений, которые
            не всегда видны на первый взгляд. Генеральная уборка предполагает
            более глубокую и тщательную очистку всех помещений офиса, включая
            труднодоступные места.
          </p>
          <h3>Уборка офисов после ремонта</h3>
          <p>
            После проведения ремонтных работ офис нуждается в особом виде
            уборки. Уборка офисов после ремонта отличается высокой сложностью и
            требует специальных знаний и оборудования. Это связано с тем, что в
            помещении после ремонта остаются остатки строительных материалов,
            пыль, пятна от краски и другие загрязнения, которые требуют
            тщательной очистки.{" "}
          </p>
          <h2>Сколько стоит клининг офиса и что влияет на цену?</h2>
          <p>
            Цены на клининг офисов в Москве варьируются в зависимости от
            множества факторов: площадь помещения, необходимость дополнительных
            услуг (например, мойка окон). Для уточнения всех деталей и расчета
            точной стоимости, вы можете обратиться к нашим специалистам по
            телефону или через форму на сайте. Мы всегда готовы ответить на ваши
            вопросы и подобрать оптимальный вариант обслуживания.
          </p>
          <h2>Как заказать клининг офиса в Москве?</h2>
          <p>
            Заказать услуги клининга офисов в Москве от компании "Твоя уборка"
            очень просто. Вы можете позвонить нам по телефону или оставить
            заявку на сайте. Мы оперативно свяжемся с вами для уточнения всех
            деталей и составим индивидуальное предложение, учитывающее все ваши
            требования и пожелания.
          </p>
        </div>
      </section>
    </>
  );
};

export default Office;
