import AddNumber from "./add-number/AddNumber";
import AddMail from "./add-mail/AddMail";
import AddPassword from "./add-password/AddPassword";
// import AddPayment from "./addPayment/AddPayment";
import AddAddress from "./add-adress/AddAddress";
// import Notifications from "./notifications/Notifications";
// import AddName from "./add-name/AddName";
import "./Settings.scss";

const Settings = () => {
  return (
    <section className="setting">
      <div>Данные для входа:</div>
      <div className="setting__data">
        {/* <AddName /> */}
        <AddNumber />
        <AddMail />
        <AddPassword />
      </div>
      {/* <AddPayment /> */}
      <AddAddress />
      {/* <div>
        <Notifications />
      </div> */}
    </section >
  )
}

export default Settings;
