import { useState } from 'react'
import './ModalOrder.scss'

const ModalOrder = () => {
  const [closePopup, setClosePopup] = useState(true)
  const handleClose = () => {
    setClosePopup(false)
  }
  return (
    closePopup && (
      <span>
        <div className="modal-bg"></div>
        <div className="modal-order">
          {/* <div className="close" onClick={handleClose}>&times;</div> */}
          <div className="title">
            Спасибо, что доверяете нам <br />
            заботу о Вашем доме!
          </div>
          <div className="intro">
            Услуга отобразится в Вашем личном кабинете.
          </div>
        </div>
      </span>
    )
  )
}

export default ModalOrder
